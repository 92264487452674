import { AfterViewInit, Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { L10N_CONFIG, L10N_LOCALE, L10nConfig, L10nLocale } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { ThemeColorService } from 'src/app/core/services/theme-color.service';

@Component({
  selector: 'app-anonymous-language-menu-item',
  templateUrl: './anonymous-language-menu-item.component.html',
  styleUrls: ['./anonymous-language-menu-item.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AnonymousLanguageMenuItemComponent implements AfterViewInit {

  schema = this.l10nConfig.schema;
  @ViewChild(MatIcon) titleItem: MatIcon;

  constructor(
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private baseService: BaseService,
    private themeColorService: ThemeColorService
  ) { }

  ngAfterViewInit(): void {
    const color = this.themeColorService.theme?.properties['--primary'];
    this.titleItem._elementRef.nativeElement.style.color = color || 'linear-gradient(90deg, #00816D 0%, #00816D 100%)';
}

  setLocale(locale: L10nLocale): void {
    this.baseService.setLocale(locale);
  }

}
