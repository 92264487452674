import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DoctorService } from 'src/app/core/services/doctors.service';
import { LicenciaProfesional } from 'src/app/core/services/prints.service';
import { LicenseDoctor } from 'src/app/shared/models/clinic-history/license-doctor.model';

@Component({
  selector: 'app-summary-order-sign',
  templateUrl: './summary-order-sign.component.html',
  styleUrls: ['./summary-order-sign.component.css']
})
export class SummaryOrderSignComponent implements OnChanges {

  @Input() idResponsableServicio: number;
  sign: string;
  nombreResponsableServicio: string;
  nombreEspecialidad: string;
  registro: string;
  esProfesionalVerificado = false;
  json: LicenciaProfesional;
  private _signPdf = '';

  get signPdf() { return this._signPdf; }
  
  constructor(private doctorService: DoctorService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.idResponsableServicio && changes.idResponsableServicio.currentValue > 0) {
      this.doctorService.getLicense(this.idResponsableServicio).subscribe(this.procesarIdoneidad.bind(this));
    }
  }

  private procesarIdoneidad(args: LicenseDoctor): void {
    const informacionResponsableServicio = args.informacionResponsableServicio;
    const licencia = args.licenciasProfesional
      .filter(x => x.idIdentificacionPersona == informacionResponsableServicio.idIdentificacionIdoneidadProfesionalDefault)[0];

    if (licencia) {
      this.json = JSON.parse(licencia.jsonIdentificacion);

      this.nombreResponsableServicio = this.json.Nombre;
      this.nombreEspecialidad = this.json.Especialidad;
      this.registro = `${this.json.Registro ? `Reg. ${this.json.Registro}` : ''} ${this.json.Codigo ? `Cod. ${this.json.Codigo}` : ''} ${this.json.Folio ? `Folio. ${this.json.Folio}` : ''}`
    }

    this.esProfesionalVerificado = informacionResponsableServicio.profesionalVerificado == 'S';
    this.sign = args.documentosFirma
      .filter(x => x.idDocumento == informacionResponsableServicio.idDocumentoImagenFirma)
      .map(x => x.direccionUbicacionWeb)[0];
    this._signPdf = args.documentosFirma
      .filter(x => x.idDocumento == informacionResponsableServicio.idDocumentoImagenFirma)
      .map(x => x.nombreBucketAmazonS3 ? x.direccionUbicacionWeb : x.direccionUbicacionFisica)[0];
  }

  imgError(event: Event) {
    const element = event.target as HTMLImageElement;
    element.style.visibility = "hidden";
    event.preventDefault();
  }

  imgLoad(event: Event) {
    const element = event.target as HTMLImageElement;
    element.style.visibility = "visible";
    event.preventDefault();
  }

}
