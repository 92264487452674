import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ChatConversacionMessageEvent } from 'src/app/shared/models/signalr/chat-conversation-message-event.model';
import { UserActiveEvent } from 'src/app/shared/models/signalr/user-active-event.model';
import { VirtualAttentionEvent } from 'src/app/shared/models/signalr/virtual-attention-event.model';
import { AppStorageService } from '../app-storage.service';
import { BaseService } from '../base.service';
import { SignalrService } from './signalr.service';

/*
It is important for this Service to NOT be a Singleton (avoid adding "providedIn: 'root'")
in order to work properly. If not all the event related to connection and re-connection get mixed between all the clients that use the service
In order to use the service on an component add it to the providers of the component like: "providers: [ChatSignalRService]""
*/
@Injectable()
export class ChatSignalRService extends SignalrService {

  protected newChatMessageSubject = new Subject<ChatConversacionMessageEvent>();
  protected virtualAttentionChangedSubject = new Subject<VirtualAttentionEvent>();
  protected userActiveSubject = new Subject<UserActiveEvent>();

  constructor(protected baseService: BaseService, protected appStorageService: AppStorageService, @Inject(PLATFORM_ID) protected platformId: Object) { 
    super(baseService, appStorageService, platformId);
        
    this.hubName = "CliniwebHub";
  }  

  registerOnServerEvents(): void {  

    // Virtual consultation state changed
    this.proxy.on('virtualRequestStateChanged', (data: VirtualAttentionEvent) => {        
      
        console.log("ChatSignalRService -> VirtualAttention changed ->");
        console.log(data);        
        
        this.virtualAttentionChangedSubject.next(data);
    });

    // New Message received
    this.proxy.on('ChatMessageReceived', (data: ChatConversacionMessageEvent) => {        
      
      console.log("ChatSignalRService -> New Message ->");
      console.log(data);      
      
      this.newChatMessageSubject.next(data);
    });
    
    // User Active
    this.proxy.on('UserActive', (data: UserActiveEvent) => {        
      
      //console.log("ChatSignalRService -> User Active ->");
      //console.log(data);

      //let obj = this.baseService.parseObjectDeep<UserActiveEvent>(JSON.parse(data));
      
      this.userActiveSubject.next(data);
    });
  }
  
  onNewMessageReceived() : Observable<ChatConversacionMessageEvent> {
    return this.newChatMessageSubject.asObservable();
  }

  onVirtualAttentionChanged() : Observable<VirtualAttentionEvent> {
    return this.virtualAttentionChangedSubject.asObservable();
  }

  onUserActive() : Observable<UserActiveEvent> {
    return this.userActiveSubject.asObservable();
  }

  public userActive(groupId: number, userId: number, etapaSolicitudId: number) {
    var model = {
      userId: userId,
      etapaSolicitudId: etapaSolicitudId,
      action: "chat_user_active",
      eventUnixDate: new Date().getTime()
    };

    this.proxy.invoke('UserActive', groupId, JSON.stringify(model)).catch((error: any) => {
        console.log('UserActive error -> ' + error); 
    });
  }
}

