<div class="growth-charts-tab-table-type-container">
    <ng-container *ngIf="!loading else loadingSkeleton">
        <table mat-table [dataSource]="measurementsForRange" class="desktop-solution">                    
            <ng-container *ngFor="let header of headers" [matColumnDef]="header">
                <th mat-header-cell *matHeaderCellDef class="column-header"> <span>{{ header | translate:locale.language }}</span> </th>
                <td mat-cell *matCellDef="let result" class="row-cell"> {{ result[header] }}</td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="headers"></tr>
            <tr mat-row *matRowDef="let row; columns: headers;" class="past-app-row"></tr>
        </table>
    </ng-container>
</div>

<ng-template #loadingSkeleton>
    <div class="skeleton">
        <div class="skeleton-row header">
            <div class="skeleton-cell" *ngFor="let header of headers">
                <span>{{ header | translate:locale.language }}</span>
            </div>
        </div>
        <div class="skeleton-row">
            <div class="skeleton-cell">
                <ngx-skeleton-loader
                    [theme]="{ 'display': 'block', 'width': '88px', 'height': '12px', 'background-color': 'rgb(231, 231, 232)', 'border-radius': '2px'}"
                ></ngx-skeleton-loader>
            </div>
            <div class="skeleton-cell">
                <ngx-skeleton-loader
                    [theme]="{ 'display': 'block', 'width': '44px', 'height': '12px', 'background-color': 'rgb(231, 231, 232)', 'border-radius': '2px'}"
                ></ngx-skeleton-loader>
            </div>
            <div class="skeleton-cell" style="width: 50%;">

            </div>
        </div>
        <div class="skeleton-row">
            <div class="skeleton-cell">
                <ngx-skeleton-loader
                    [theme]="{ 'display': 'block', 'width': '88px', 'height': '12px', 'background-color': 'rgb(231, 231, 232)', 'border-radius': '2px'}"
                ></ngx-skeleton-loader>
            </div>
            <div class="skeleton-cell">
                <ngx-skeleton-loader
                    [theme]="{ 'display': 'block', 'width': '44px', 'height': '12px', 'background-color': 'rgb(231, 231, 232)', 'border-radius': '2px'}"
                ></ngx-skeleton-loader>
            </div>
            <div class="skeleton-cell" style="width: 50%;">
                
            </div>
        </div>
    </div>
</ng-template>