import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { of, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, debounceTime, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { DoctorService } from 'src/app/core/services/doctors.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ClassConcept } from 'src/app/shared/enums/class-concept.enum';
import { DoctorSearchResult } from 'src/app/shared/models/cdr/doctor-search-result.model';
import { PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { MessageBusService, ProviderSearchMessage } from 'src/app/core/services/message-bus.service';
import { TerritorialDivisionsService } from 'src/app/core/services/territorial-divisions.service';
import { TerritorialDivision } from 'src/app/shared/models/systems/territorial-division.model';
import { LayoutService } from 'src/app/core/services/layout.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { ConceptDefaultModel } from 'src/app/shared/models/search-results/concept-default.model';
import { SearchQsParamMapModel, SearchQsParametersModel } from 'src/app/shared/models/search-results/search-parameters.model';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { SearchProvidersConfiguration, SearchProvidersType } from 'src/app/shared/models/search-results/search-providers-configuration.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { PublicProfileAvatarComponentModel } from '../../public-profile-avatar/public-profile-avatar.component';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { AppointmentDoctor } from 'src/app/shared/models/people/appointment-doctor';
import { Platform } from '@angular/cdk/platform';
import { LocationV2DefaultModel } from 'src/app/shared/models/search-results/location-default.model';
import { ClinicalConceptService } from 'src/app/core/services/clinical-concept.service';
import { DirectoryFiltersItemsModel, DirectoryFiltersModel } from '../provider-filters/provider-filters-content/provider-filters-content.component';
import { ProfileFilterSearchResult, ProfileFilterSearchResultConcept, ProfileFilterSearchResultGroup } from 'src/app/shared/models/people/profile-filter-search-result.model';

@Component({
  selector: 'app-search-providers-v2',
  templateUrl: './search-providers-v2.component.html',
  styleUrls: ['./search-providers-v2.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('SearchProvidersV2Component')
export class SearchProvidersV2Component implements OnInit, OnDestroy, AfterContentChecked , IControlComponent {
  data: any;

  @Input() model: SearchProvidersConfiguration = new SearchProvidersConfiguration(); 

  //subscribes
  private ngUnsubscribe = new Subject();

  companyName: string = "";
  language: string;
  searchCountry: TerritorialDivision;

  searchOpened: boolean = false;

  autocompleteOpen:boolean = false;
  loadingSearchProvider: boolean = false;
  searchProviderCtrl = new FormControl();
  filteredResults$: Observable<DoctorSearchResultGroup[]>;
  filteredResultsCount:number;
  providerSelected: DoctorSearchResult | null = null;

  highlightValue: string;

  locationAutocompleteOpen:boolean = false;
  loadingSearchLocation: boolean = false;
  searchLocationCtrl = new FormControl();
  filteredLocationResults$: Observable<LocationSearchResultGroup[]>;
  filteredLocationResultsCount:number;
  locationObjectSelected: LocationSearchResultItem | null = null;
  locationLevels: string = "1,2,3";
  locationDefault: LocationV2DefaultModel | undefined = undefined;

  insuranceAutocompleteOpen: boolean = false;
  loadingSearchInsurance: boolean = false;
  searchInsuranceCtrl = new FormControl();
  filteredInsuranceResults$: Observable<ProfileFilterSearchResultGroup[]>;
  filteredInsuranceResultsCount:number;
  insuranceObjectSelected: ProfileFilterSearchResultConcept | null = null;

  insuranceFilter: DirectoryFiltersItemsModel;  
  providerSearchMessage: ProviderSearchMessage;
  insuranceUnfilteredResults: ProfileFilterSearchResultConcept[];  

  cityText:string;
  stateText:string;
  countryText:string;
  cityDefaultText:string;
  stateDefaultText:string;
  countryDefaultText: string;
  dontApplyInsuranceText:string;

  removingSelection:boolean = false;

  isMobile:boolean = false;
  fullsize: boolean = false;

  isAnonymous:boolean = true;

  mobileDelimiter: number = 800;

  maxLastestDoctorsToShow: number = 4;
  lastestDoctors: AppointmentDoctor[] = [];
  loadingLastestDoctors:boolean = false;
  lastestDoctorsIndex: number = 0;

  otherInsuranceComp: ConceptDefaultModel[] = [];
  qsParams: SearchQsParametersModel;

  @ViewChild('inputWrapperProvider') inputWrapperProvider: ElementRef<HTMLDivElement>;
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('searchInput', { read:MatAutocompleteTrigger }) autocompleteProviderTrigger: MatAutocompleteTrigger;

  @ViewChild('inputWrapperLocation') inputWrapperLocation: ElementRef<HTMLDivElement>;
  @ViewChild('searchLocationInput') searchLocationInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoLocation') matAutocompleteLocation: MatAutocomplete;
  @ViewChild('searchLocationInput', { read:MatAutocompleteTrigger }) autocompleteLocationTrigger: MatAutocompleteTrigger;

  @ViewChild('inputWrapperInsurance') inputWrapperInsurance: ElementRef<HTMLDivElement>;
  @ViewChild('searchInsuranceInput') searchInsuranceInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoInsurance') matAutocompleteInsurance: MatAutocomplete;
  @ViewChild('searchInsuranceInput', { read:MatAutocompleteTrigger }) autocompleteInsuranceTrigger: MatAutocompleteTrigger;

  isBrowser: boolean;

  constructor(    
    private baseService: BaseService,
    private appointmentService: AppointmentService,
    private layoutService: LayoutService,
    private utilsService: UtilsService,
    private doctorService: DoctorService,
    private router: Router,
    private messageBusService: MessageBusService,
    private territorialDivisionsService: TerritorialDivisionsService,
    private publicProfileService: PublicProfileService,
    private translation: L10nTranslationService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private cdref: ChangeDetectorRef,
    public platform: Platform,
    private clinicalConceptsService: ClinicalConceptService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }
  
  ngOnInit(): void {
    this.document.body.classList.add('search-providers-v2');
    this.isMobile = this.isBrowser && window.innerWidth <= this.mobileDelimiter;

    this.companyName = this.baseService.getCliniwebDirectoryCompanyName();
    this.language = this.baseService.getLanguage();

    this.parseControlData();

    this.configSearchByCountryApp();
  
    this.configSearchProviders();

    this.configSearchLocations();

    this.configSearchInsurers();

    this.initBySearchType();

    this.setDefaultsText();

    this.getDirectoryFilters();

    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
        this.setDefaultsText();
      }
    });

    this.messageBusService.onLoginCompleted()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      if (msg.user) {
        this.isAnonymous = false;
      }      
    });

    this.messageBusService.onSendTerritorialDivisionCountry()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      this.configSearchByCountry(msg.country);
      
      this.setLocationDefaultByCountry();
    });

    this.authService.isLoggedInFull()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result:boolean)=>{
      this.isAnonymous = !result;

      if(result){
        this.getLatestDoctors();
      }      
    });

    this.messageBusService.onAppointmentChangeReceived()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      appointments => {

        this.lastestDoctors =  appointments.doctores;
      },
      error => { this.baseService.handleServiceError(error, "Error getting latest doctors") }      
    );

    // Subscribe to new search event
    this.messageBusService.onProviderSearch()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(message => {
      this.providerSearchMessage = message;

      this.toggleAutocompleteInsurance(message);
    });
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.document.body.classList.remove('search-providers-v2');
  }

  getParamMaps(): Params {
    const url = this.router.routerState.snapshot.url;

    const { params, queryParams } = this.processUrl(url);

    return {...params, ...queryParams};
  }

  private processUrl(url: string): { params: Record<string, string>, queryParams: Record<string, string> } {
    const [path, queryString] = url.split('?');
  
    const segments = path.split('/').filter(segment => segment);
  
    const params: Record<string, string> = {
      param1: segments[1] || '',
      param2: segments[2] || '',
      param3: segments[3] || '',
      param4: segments[4] || '',
      param5: segments[5] || ''
    };
  
    const queryParams: Record<string, string> = {};
    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      urlParams.forEach((value, key) => {
        queryParams[key] = value;
      });
    }
  
    return { params, queryParams };
  }

  initBySearchType(){

    switch(this.model.searchType){
      case SearchProvidersType.DEFAULT:
      default:
        this.initFromParams();
        break;
      case SearchProvidersType.POPUP:
        this.initLikePopup();

        this.initEmptyProviderSearchMessage();
        break;
      case SearchProvidersType.WIDGET:
        this.initLikeWidget();

        this.initEmptyProviderSearchMessage();
        break;
    }
  }

  initLikePopup(){
    this.searchOpened = true;

    this.layoutService.overflowBodyDisabled();

    if(!this.isMobile){
      setTimeout(()=>{
        this.autocompleteProviderTrigger.openPanel();
        this.searchInput.nativeElement.focus();
      }, 100);
    }
  }

  initLikeWidget(){
    // this.searchOpened = true;

    this.mobileDelimiter = 600;

    this.isMobile = this.isBrowser && window.innerWidth <= this.mobileDelimiter;

    this.document.body.classList.add('widget-mode');
  }

  initFromParams(){
    // let params = this.getParamMaps();
    const currentSnapshot: ActivatedRouteSnapshot = this.router.routerState.snapshot.root;
    let params = this.collectParams(currentSnapshot);
    let _paramMap = new SearchQsParamMapModel();

    _paramMap.param1 = params['param1'] || '';
    _paramMap.param2 = params['param2'] || '';
    _paramMap.param3 = params['param3'] || '';
    _paramMap.param4 = params['param4'] || '';
    _paramMap.param5 = params['param5'] || '';

    this.qsParams = new SearchQsParametersModel();
    
    this.qsParams.t = params['t'] || '';
    this.qsParams.filtros = params['filtros'] || '';
    this.qsParams.idC = params['idC'] || '';
    this.qsParams.idTc = params['idTc'] || '';

    this.populateDropdownByParams(_paramMap, this.qsParams);
  }

  configSearchProviders(){
    this.filteredResults$ = this.searchProviderCtrl.valueChanges
    .pipe(
      tap(()=>{ 
        this.loadingSearchProvider = true;
      }),
      debounceTime(300),
      startWith(''),
      switchMap(value => 
        this.filterProviders(value)
        .pipe(
          map(results => {
            this.loadingSearchProvider = false;

            this.highlightValue = value;

            this.filteredResultsCount = results.length;
            
            return this.groupProviderResults(results);
          })
        )
      )
    );
  }

  configSearchLocations(){
    if(!this.model.showLocationsAutocomplete)
      return;

    this.setLocationDefaultByCountry();

    if(this.model.locationsLikeCombo){
      this.filteredLocationResults$ = this.searchLocationCtrl.valueChanges
      .pipe(
        tap(()=>{ 
          this.loadingSearchLocation = true;
        }),
        debounceTime(0),
        startWith(''),
        switchMap(value => {

          let options = this.filterDefaultLocations(value);

          this.loadingSearchLocation = false;
          this.highlightValue = value;
          this.filteredLocationResultsCount = options.length;

          return of(options);
        })
      );
    }
    else{

      this.filteredLocationResults$ = this.searchLocationCtrl.valueChanges
      .pipe(
        tap(()=>{ 
          this.loadingSearchLocation = true;
        }),
        debounceTime(0),
        startWith(''),
        switchMap(value =>
          this.filterLocations(value)
          .pipe(
            map(results => {
              this.loadingSearchLocation = false;

              this.highlightValue = value;

              this.filteredLocationResultsCount = results.length;

              return results;
            })
          )
        )
      );
    }
  }  

  filterDefaultLocations(value:any):LocationSearchResultGroup[]{

    let optionsGrouped = this.getLocationSearchGroupByDefault();

    if(value){

      const textValue = (typeof value === 'string')
        ? value.toLowerCase()
        : value.nombre.toLowerCase();

      optionsGrouped = optionsGrouped.filter(og => og.results.filter(r => r.nombre.toLowerCase().includes(textValue)).length > 0);

      optionsGrouped.forEach(og => {
        og.results = og.results.filter(r => r.nombre.toLowerCase().includes(textValue));
      });

      return optionsGrouped;
    }
    else{
      return optionsGrouped;
    }
  }  

  filterProviders(value:any):Observable<DoctorSearchResult[]> {

    if(value instanceof DoctorSearchResult){
      return of([value]);
    }
    else if(value){
      value = value.trim(); 
    }
    
    if(!this.autocompleteOpen)
      return of([]);

    if((!value || value == '') && this.model.profilesDefault.length > 0){
      return of(this.getProfilesDefault());
    }
    else{

      let _profileSearchTypes = this.model.profileTypes.split(",").map(pt => +pt);

      return this.doctorService.searchDoctors(this.locale.language, value, this.companyName, _profileSearchTypes);
    }
  }

  filterLocations(value:any):Observable<LocationSearchResultGroup[]>{
    if(typeof value === 'string'){
      value = value.trim(); 
    }

    if(!this.locationAutocompleteOpen)
      return of([]);

    if((!value || value == '') && this.model.locationsDefault != null){
      return of(this.getLocationSearchGroupByDefault());
    }
    else{
      return this.getLocationsByLevels(value);
    }
  }

  removeSearchProviderSelected(){
    this.removingSelection = true;

    this.searchProviderCtrl.reset('');

    if(this.searchInput){
      this.searchInput.nativeElement.value = '';
    }

    this.providerSelected = null;

    if(this.searchOpened && this.model.profilesDefault.length > 0) {
      setTimeout(()=>{
        this.autocompleteOpen = true;
        this.autocompleteProviderTrigger.openPanel();
        this.autocompleteProviderTrigger._onChange('');
        this.autocompleteProviderTrigger.updatePosition();
      }, 100);
    }

    setTimeout(() => {
      this.removingSelection = false;
    }, 100);

    if(this.isSearchDefault()){
      this.fireSearchEvent();
    }
  }

  removeSearchLocationSelected(){
    this.removingSelection = true;

    this.searchLocationCtrl.reset('');

    if(this.searchLocationInput){
      this.searchLocationInput.nativeElement.value = '';
    }

    this.locationObjectSelected = null;

    if(this.searchOpened && this.model.locationsDefault != null) {
      setTimeout(()=>{
        this.locationAutocompleteOpen = true;
        this.autocompleteLocationTrigger.openPanel();
        this.autocompleteLocationTrigger._onChange('');
        this.autocompleteLocationTrigger.updatePosition();
      }, 100);
    }

    setTimeout(() => {
      this.removingSelection = false;
    }, 100);

    if(this.isSearchDefault()){
      this.fireSearchEvent();
    }
  }

  removeSearchInsuranceSelected(){
    
    this.removingSelection = true;

    this.searchInsuranceCtrl.reset('');

    if(this.searchInsuranceInput){
      this.searchInsuranceInput.nativeElement.value = '';
    }

    this.insuranceObjectSelected = null;

    //if(this.searchOpened && this.model.insurersDefault != null) {
    if (this.searchOpened && this.insuranceUnfilteredResults != null) {
      setTimeout(()=>{
        this.insuranceAutocompleteOpen = true;
        this.autocompleteInsuranceTrigger.openPanel();
        this.autocompleteInsuranceTrigger._onChange('');
        this.autocompleteInsuranceTrigger.updatePosition();
      }, 100);
    }

    setTimeout(() => {
      this.removingSelection = false;
    }, 100);

    if(this.isSearchDefault()){
      this.fireSearchEvent();
    }
  }  

  groupProviderResults(results: DoctorSearchResult[]): DoctorSearchResultGroup[]{
    let _resultGroup:DoctorSearchResultGroup[] = [];

    let _resultsGrouped = this.utilsService.groupBy<DoctorSearchResult, number>(results, sd => sd.claseId);

    _resultsGrouped.forEach((value: DoctorSearchResult[], key: number)=>{
      let resultGroupItem = new DoctorSearchResultGroup();

      resultGroupItem.classId = key;
      resultGroupItem.className = this.getOptgroupLabelProvider(value[0].clase);
      resultGroupItem.results = value;

      _resultGroup.push(resultGroupItem);
    });

    return _resultGroup;
  }

  getOptgroupLabelProvider(text:string){
    let optgroupFocusAreaText:string = this.translation.translate('shared.searchResults.searchProvidersV2.optgroupFocusArea');
    let optgroupDoctorText: string = this.translation.translate('shared.searchResults.searchProvidersV2.optgroupDoctor');

    switch(text.toLocaleLowerCase()){
      case optgroupFocusAreaText:
        return this.translation.translate('shared.searchResults.searchProvidersV2.optgroupExpertIn');
      case optgroupDoctorText:
        return this.translation.translate('shared.searchResults.searchProvidersV2.optgroupDoctors');
      default:
        return text;
    }
  }

  profileSelected(event: MatAutocompleteSelectedEvent): void {
    this.loadingSearchProvider = false;

    let _optionSelected:DoctorSearchResult = event.option.value;
    let _clase = _optionSelected.clase.toLowerCase();

    if(_clase == 'doctor' || _clase == 'proveedor'){
      let url = this.publicProfileService.getPublicProfileUrl(_optionSelected.url);
      
      this.triggerOpenPublicProfileByType(url);
    }
    else {
      this.providerSelected = _optionSelected;

      if(this.isMobile || this.fullsize){
        this.searchInput.nativeElement.blur();
      }
      else if (this.model.showLocationsAutocomplete){
        setTimeout(()=>{
          this.autocompleteLocationTrigger.openPanel();
          this.searchLocationInput.nativeElement.focus();
        }, 100);
      }
      else if(this.model.showInsurersAutocomplete){
        setTimeout(()=>{
          this.autocompleteInsuranceTrigger.openPanel();
          this.searchInsuranceInput.nativeElement.focus();
        }, 100);
      }
      else {
        this.fireSearchEvent();
        this.closeSearch();
      }
    }
  }

  gotoProfile(doctor: AppointmentDoctor){
    this.autocompleteOpen = false;
    this.autocompleteProviderTrigger.closePanel();
    
    let url = this.publicProfileService.getPublicProfileUrl(doctor.identificadorPerfilPublico);
      
    this.triggerOpenPublicProfileByType(url);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = this.isBrowser && event.target.innerWidth <= this.mobileDelimiter;
  }

  @HostListener('scroll', ['$event'])
  onScroll(e: Event) {
    this.searchInputBlur(this.searchInput.nativeElement);
    this.searchInputBlur(this.searchLocationInput.nativeElement);
    this.searchInputBlur(this.searchInsuranceInput.nativeElement);
  }

  locationSelected(event: MatAutocompleteSelectedEvent): void {
    this.loadingSearchLocation = false;

    let _optionSelected:LocationSearchResultItem = event.option.value;

    this.locationObjectSelected = _optionSelected;

    if(this.isMobile || this.fullsize){
      this.searchLocationInput.nativeElement.blur();
    }
    else if(this.model.showInsurersAutocomplete){
      setTimeout(()=>{
        this.autocompleteInsuranceTrigger.openPanel();
        this.searchInsuranceInput.nativeElement.focus();
      }, 100);
    }
    else {
      this.fireSearchEvent();
      this.closeSearch();
    }
  }

  insuranceSelected(event: MatAutocompleteSelectedEvent): void {
    this.loadingSearchInsurance = false;

    let _optionSelected:ProfileFilterSearchResultConcept = event.option.value;

    this.insuranceObjectSelected = _optionSelected;

    if(this.isMobile || this.fullsize){
      this.searchInsuranceInput.nativeElement.blur();
    }
    else{
      this.fireSearchEvent();
      this.closeSearch();
    }
  }

  autoCompleteProviderDisplayWith(item: DoctorSearchResult){
    return item 
      ? item.nombre 
      : "";
  }

  autoCompleteLocationDisplayWith(item: TerritorialDivision){
    return item 
      ? item.nombre 
      : "";
  }

  autoCompleteInsuranceDisplayWith(item: ProfileFilterSearchResultConcept){
    return item 
      ? item.n_cc 
      : "";
  }

  onKeydownInputSearch($event: KeyboardEvent){
    if($event.key !== 'Enter'){
      this.providerSelected = null;
    }
  }

  onKeydownInputLocationSearch($event: KeyboardEvent){
    if($event.key !== 'Enter'){
      this.locationObjectSelected = null;
    }
  }

  onKeydownInputInsuranceSearch($event: KeyboardEvent){
    if($event.key !== 'Enter'){
      this.insuranceObjectSelected = null;
    }
  }

  onFocusInputSearch(){

    this.autocompleteOpen = true;

    // ejecutar la busqueda del autocomplete si el input tiene un texto
    if(this.searchInput.nativeElement.value){
      this.autocompleteProviderTrigger._onChange(this.searchInput.nativeElement.value);
    }
    // si no tiene texto y valores por defecto, se agrega un timeout para que reposicione el dropdown
    else if(this.model.profilesDefault.length > 0) {
      setTimeout(()=>{
        this.autocompleteProviderTrigger._onChange('');
        this.autocompleteProviderTrigger.updatePosition();
      }, 100);
    }
  }

  onFocusInputLocationSearch(){
    this.locationAutocompleteOpen = true;

    // ejecutar la busqueda del autocomplete si el input tiene un texto
    if(this.searchLocationInput.nativeElement.value){
      this.autocompleteLocationTrigger._onChange(this.searchLocationInput.nativeElement.value);
    }
    // si no tiene texto y valores por defecto, se agrega un timeout para que reposicione el dropdown
    else if(this.model.locationsDefault != null) {
      setTimeout(()=>{
        this.autocompleteLocationTrigger._onChange('');
        this.autocompleteLocationTrigger.updatePosition();
      }, 100);
    }

    // ajuste para IOS
    if(this.isBrowser && this.isMobile){
      setTimeout(() => {
        window.scroll(0,0);
      }, 200);
    }
  }  

  onBlurInputSearch(){
    if(this.isMobile || this.lastestDoctors.length > 0)
      return;

    this.autocompleteOpen = false;

    if(!this.searchInput.nativeElement.value){
      this.searchProviderCtrl.setValue(null);
      this.providerSelected = null;
    }
  }

  onBlurInputLocationSearch(){

    if(this.isMobile)
      return;

    setTimeout(() => {
      
      this.locationAutocompleteOpen = false;

      if(!this.searchLocationInput.nativeElement.value){
        this.searchLocationCtrl.setValue(null);
        this.locationObjectSelected = null;
      }
    }, 100);

  }
  
  onBlurInputInsuranceSearch(){
    if(this.isMobile)
      return;
    
    setTimeout(() => {
      this.insuranceAutocompleteOpen = false;

      if(!this.searchInsuranceInput.nativeElement.value){
        this.searchInsuranceCtrl.setValue(null);
        this.insuranceObjectSelected = null;
      }
    }, 100);
  }

  onCancelMobile(){
    event?.stopPropagation();

    this.autocompleteOpen = false;
    this.locationAutocompleteOpen = false;
    this.insuranceAutocompleteOpen = false;
    this.autocompleteProviderTrigger.closePanel();
    this.autocompleteLocationTrigger.closePanel();
    this.autocompleteInsuranceTrigger.closePanel();
    this.searchInputBlur(this.searchInput.nativeElement);
    this.searchInputBlur(this.searchLocationInput.nativeElement);
    this.searchInputBlur(this.searchInsuranceInput.nativeElement);
  }

  onClickSearchTextMobile(){
    this.autocompleteOpen = false;
    this.autocompleteProviderTrigger.closePanel();
  }

  autocompleteOpened():void{
    if(this.isMobile)
      setTimeout(() => {
        this.matAutocomplete.panel.nativeElement.classList.add('opened');
        this.inputWrapperProvider.nativeElement.classList.add('opened');
      }, 300);

    this.autocompleteOpen = true;

    if(!this.searchOpened)
      this.sendMessageIframeResizeOpen();
  }

  autocompleteClosed() {
    if(this.isMobile && this.removingSelection)
      return;

    if(this.isMobile){
      this.matAutocomplete.panel.nativeElement.classList.remove('opened');
      this.inputWrapperProvider.nativeElement.classList.remove('opened');
    }

    this.autocompleteOpen = false;
    this.searchInputBlur(this.searchInput.nativeElement);

    if(!this.isMobile && !this.searchOpened && !(this.locationAutocompleteOpen && this.insuranceAutocompleteOpen))
      this.sendMessageIframeResizeClose();
  }

  autocompleteLocationOpened():void{
    if(this.isMobile){      
      setTimeout(() => {
        this.matAutocompleteLocation.panel.nativeElement.classList.add('opened');
        this.inputWrapperLocation.nativeElement.classList.add('opened');
      }, 300);
    }

    this.locationAutocompleteOpen = true;

    if(!this.searchOpened)
      this.sendMessageIframeResizeOpen();
  }

  autocompleteLocationClosed() {
    if(this.isMobile && this.removingSelection)
      return;

    if(this.isMobile){
      this.matAutocompleteLocation.panel.nativeElement.classList.remove('opened');
      this.inputWrapperLocation.nativeElement.classList.remove('opened');
    }
          
    this.locationAutocompleteOpen = false;
    this.searchInputBlur(this.searchLocationInput.nativeElement);

    if(!this.isMobile && !this.searchOpened && !(this.autocompleteOpen && this.insuranceAutocompleteOpen))
      this.sendMessageIframeResizeClose();
  }

  autocompleteInsuranceOpened():void{

    if(this.isMobile)
      setTimeout(() => {
        this.matAutocompleteInsurance.panel.nativeElement.classList.add('opened');
        this.inputWrapperInsurance.nativeElement.classList.add('opened');
      }, 300);

    this.insuranceAutocompleteOpen = true;

    if(!this.searchOpened)
      this.sendMessageIframeResizeOpen();

    this.searchInputBlur(this.searchInsuranceInput.nativeElement);
  }

  autocompleteInsuranceClosed() {
    if(this.isMobile && this.removingSelection)
      return;

    if(this.isMobile){
      this.matAutocompleteInsurance.panel.nativeElement.classList.remove('opened');
      this.inputWrapperInsurance.nativeElement.classList.remove('opened');
    }
    
    this.insuranceAutocompleteOpen = false;

    if(!this.isMobile && !this.searchOpened && !(this.locationAutocompleteOpen && this.autocompleteOpen))
      this.sendMessageIframeResizeClose();
  }

  showIconDropdownOption(item:DoctorSearchResult){
    return item.claseId == ClassConcept.ESPECIALIDAD
      || item.claseId == ClassConcept.DIAGNOSTICO
      || item.claseId == ClassConcept.AREAS_DE_ENFOQUE
      || item.claseId == ClassConcept.PROCEDIMIENTO;
  }

  getIconClassNameDropdownOption(item:DoctorSearchResult){
    let iconClassName = 'phr-iconset-';

    switch(item.claseId){
      case ClassConcept.ESPECIALIDAD:
        iconClassName += 'estetoscopio';
        break;
      case ClassConcept.DIAGNOSTICO:
        iconClassName += 'info-general2';
        break;
      case ClassConcept.AREAS_DE_ENFOQUE:
        iconClassName += 'medal';
        break;
      case ClassConcept.PROCEDIMIENTO:
        iconClassName += 'procedimientos';
        break;
      default:
        break;
    }

    return iconClassName;
  }

  showAvatarDropdownOption(item:DoctorSearchResult){
    return item.claseId == 0;
  }

  showDescriptionDropdownOption(item:DoctorSearchResult){
    return item.claseId == 0;
  }

  isDoctor(item:DoctorSearchResult) {
    return item.tipoPerfilPublico == PublicProfileType.Doctor;
  }

  getProfileTypeIconClassName(item:DoctorSearchResult){

    let profileType = item.tipoPerfilPublico;
    let className: string = 'phr-iconset-';

    switch(profileType){
      case PublicProfileType.Doctor:
      default:
        className += 'estetoscopio';
        break;
      case PublicProfileType.Farmacia:
        className += 'farmacia';
        break;
      case PublicProfileType.Clinica:
        className += 'hospital';
        break;
      case PublicProfileType.Fisioterapia:
          className += 'fisioterapia';
          break;
      case PublicProfileType.Laboratorio:
        className += 'laboratorios';
        break;
      case PublicProfileType.CentroDeImagenes:
        className += 'imagenologia';
        break;  
      case PublicProfileType.ServiciosOdontologicos:
        className += 'odontologia';
        break;
      case PublicProfileType.Opticas:
        className += 'open-eye';
        break;
    }

    return className;
  }

  onClickSearchButton(){
    event?.stopPropagation();

    this.closeSearch();
    this.fireSearchEvent();
  }
  
  getPlaceholderProvider() {
    let result = '';

    if (this.model) {
      if (this.isMobile && this.model.profilesLabel) {
        if (this.autocompleteOpen == true)        
          result = this.layoutService.getTextByLanguage(this.model.profilesPlaceholderOpened, this.locale.language);
        else 
          result = this.layoutService.getTextByLanguage(this.model.profilesLabel, this.locale.language);
      }          
      else if (!this.isMobile && this.model.profilesPlaceholderOpened)
        result = this.layoutService.getTextByLanguage(this.model.profilesPlaceholderOpened, this.locale.language);      
    }

    /*return this.model && this.model.profilesPlaceholderOpened 
      ? this.layoutService.getTextByLanguage(this.model.profilesPlaceholderOpened, this.locale.language)
      : '';*/
    
    return result;
  }

  getPlaceholderLocation(){
    let result = '';
    let optionalText = " " + this.translation.translate('shared.searchResults.searchProvidersV2.optionalText');

    if (this.model) {
      if (this.isMobile && this.model.locationsLabel) {
        if (this.locationAutocompleteOpen == true)
          result = this.layoutService.getTextByLanguage(this.model.locationsPlaceholderOpened, this.locale.language);
        else
          result = this.layoutService.getTextByLanguage(this.model.locationsLabel, this.locale.language) + optionalText;
      }
      else if (!this.isMobile && this.model.locationsPlaceholderOpened)
        result = this.layoutService.getTextByLanguage(this.model.locationsPlaceholderOpened, this.locale.language);      
    }

    return result;

    /*return this.model && this.model.locationsPlaceholderOpened 
      ? this.layoutService.getTextByLanguage(this.model.locationsPlaceholderOpened, this.locale.language)
      : '';*/
  }

  showPreviewProviderPlaceholder(){
    return this.providerSelected == null 
      && !(this.searchInput 
        && this.searchInput.nativeElement 
        && this.searchInput.nativeElement.value);
  }

  showPreviewProviderValue(){
    return this.providerSelected != null 
      || (this.searchInput 
        && this.searchInput.nativeElement 
        && this.searchInput.nativeElement.value);
  }

  getPreviewProviderValue(){
    return this.providerSelected 
      ? this.providerSelected.nombre 
      : this.searchInput.nativeElement.value;
  }

  showPreviewLocationPlaceholder(){
    return this.locationObjectSelected == null 
      && !(this.searchLocationInput 
        && this.searchLocationInput.nativeElement 
        && this.searchLocationInput.nativeElement.value);
  }

  showPreviewLocationValue(){
    return this.locationObjectSelected != null 
      || (this.searchLocationInput 
        && this.searchLocationInput.nativeElement 
        && this.searchLocationInput.nativeElement.value);
  }

  showButtonRemoveProvider(){
    return this.providerSelected
      || (this.searchInput 
        && this.searchInput.nativeElement 
        && this.searchInput.nativeElement.value);
  }

  showButtonRemoveLocation(){
    return this.locationObjectSelected
      || (this.searchLocationInput 
        && this.searchLocationInput.nativeElement 
        && this.searchLocationInput.nativeElement.value);
  }

  showHeaderMobile(){
    return !this.autocompleteOpen && !this.locationAutocompleteOpen && !this.insuranceAutocompleteOpen;
  }

  getPreviewLocationValue(){
    return this.locationObjectSelected 
      ? this.locationObjectSelected.nombre 
      : this.searchLocationInput.nativeElement.value;
  }

  onFocusProvider(){
    this.openSearch();

    setTimeout(()=>{
      this.autocompleteProviderTrigger.openPanel();
      this.searchInput.nativeElement.focus();
    }, 100);
  }

  clearProviderSelected(){
    this.removeSearchProviderSelected();

    this.onFocusProvider();
  }

  sendMessageIframeResizeOpen(){

    this.fullsize = this.isMobile;

    var message = this.isMobile
      ? { height: "100vh", fullsize: true }
      : { height: "600px" };

    if(this.isBrowser){
      window.parent.postMessage(message, '*');
    }    
  }

  sendMessageIframeResizeClose(){
    var message = this.isMobile
    ? { height: "100px", fullsize: false }
    : { height: "100px" };

    if(this.isBrowser){
      window.parent.postMessage(message, '*');
    }
  }

  onFocusLocation(){
    this.openSearch();

    setTimeout(()=>{
      this.autocompleteLocationTrigger.openPanel();
      this.searchLocationInput.nativeElement.focus();
    }, 100);

    this.sendMessageIframeResizeOpen();
  }

  clearLocationSelected(){
    this.removeSearchLocationSelected();

    this.onFocusLocation();
  }

  onFocusInsurance(){
    this.openSearch();

    setTimeout(()=>{
      this.autocompleteInsuranceTrigger.openPanel();
      this.searchInsuranceInput.nativeElement.focus();
    }, 100);    
  }

  clearInsuranceSelected(){
    this.removeSearchInsuranceSelected();

    this.onFocusInsurance();
  }

  clearSearchSelected(){
    // this.openSearch();

    this.searchProviderCtrl.reset('');
    this.searchLocationCtrl.reset('');
    this.searchInsuranceCtrl.reset('');

    if(this.searchInput){
      this.searchInput.nativeElement.value = '';
    }

    if(this.searchLocationInput){
      this.searchLocationInput.nativeElement.value = '';
    }

    if(this.searchInsuranceInput){
      this.searchInsuranceInput.nativeElement.value = '';
    }

    this.providerSelected = null;
    this.locationObjectSelected = null;
    this.insuranceObjectSelected = null;

    if(this.isSearchDefault())
      this.fireSearchEvent();
  }

  isPopup(){
    return this.model.searchType == SearchProvidersType.POPUP;
  }

  isWidget(){
    return this.model.searchType == SearchProvidersType.WIDGET;
  }

  isSearchDefault(){
    return this.model.searchType == SearchProvidersType.DEFAULT;
  }

  openSearch(){
    this.searchOpened = true;

    this.document.body.classList.add('search-opened');

    this.layoutService.overflowBodyDisabled();

    this.sendMessageIframeResizeOpen();
  }

  onClickMobilePreview(){
    this.openSearch();

    if(!this.isMobile){

      setTimeout(()=>{
        this.autocompleteProviderTrigger.openPanel();
        this.searchInput.nativeElement.focus();
      }, 100);
    }
  }

  closeDropdownProvider(){
    this.autocompleteOpen = false;
    this.autocompleteProviderTrigger.closePanel();
  }

  closeDropdownLocation(){
    this.locationAutocompleteOpen = false;
    this.autocompleteLocationTrigger.closePanel();
  }

  closeDropdownInsurance(){
    this.insuranceAutocompleteOpen = false;
    this.autocompleteInsuranceTrigger.closePanel();
  }

  closeSearch(){
    this.searchOpened = false;

    this.document.body.classList.remove('search-opened');

    this.layoutService.overflowBodyEnabled();

    if(this.model.searchType == SearchProvidersType.POPUP){
      this.document.body.classList.remove('search-providers-v2');
    }      

    this.autocompleteOpen = false;
    this.autocompleteProviderTrigger.closePanel();

    if(this.model.showLocationsAutocomplete){
      this.locationAutocompleteOpen = false;
      this.autocompleteLocationTrigger.closePanel();
    }

    if(this.model.showInsurersAutocomplete){
      this.insuranceAutocompleteOpen = false;
      this.autocompleteInsuranceTrigger.closePanel();
    }

    this.sendMessageIframeResizeClose();
  }

  showSearchPlaceholderMobile(){
    return this.providerSelected == null 
      && !(this.searchInput && this.searchInput.nativeElement && this.searchInput.nativeElement.value)
      && (!this.model.showLocationsAutocomplete || this.locationObjectSelected == null)
      && (!this.model.showInsurersAutocomplete || this.insuranceObjectSelected == null);
  }

  getSelectionTextMobile(){
    let result = '';
    
    if(this.providerSelected){
      result += this.providerSelected.nombre;
    }
    else if(this.searchInput && this.searchInput.nativeElement && this.searchInput.nativeElement.value){
      result += this.searchInput.nativeElement.value;
    }
    
    if(this.locationObjectSelected){
      result = result 
        ? result + ', ' + this.locationObjectSelected.nombre
        : this.locationObjectSelected.nombre;
    }

    if(this.insuranceObjectSelected){
      result = result 
        ? result + ', ' + this.insuranceObjectSelected.n_cc
        : this.insuranceObjectSelected.n_cc;      
    }
    return result;
  }

  getPlaceholderInsurance() {
    let result = '';
    let optionalText = " " + this.translation.translate('shared.searchResults.searchProvidersV2.optionalText');

    if (this.model) {
      if (this.isMobile && this.model.insurersLabel) {
        /*if (this.insuranceAutocompleteOpen == true)
          result = this.layoutService.getTextByLanguage(this.model.insurersPlaceholderOpened, this.locale.language);
        else 
          result = this.layoutService.getTextByLanguage(this.model.insurersLabel, this.locale.language) + optionalText;*/
          
        result = this.layoutService.getTextByLanguage(this.model.insurersLabel, this.locale.language) + optionalText;
      }
      else if (!this.isMobile && this.model.insurersPlaceholderOpened)
        result = this.layoutService.getTextByLanguage(this.model.insurersPlaceholderOpened, this.locale.language);      
    }

    return result;

    /*return this.model && this.model.insurersPlaceholderOpened 
    ? this.layoutService.getTextByLanguage(this.model.insurersPlaceholderOpened, this.locale.language)
    : '';*/
  }

  getAvatarModel(item:DoctorSearchResult) : PublicProfileAvatarComponentModel {
    var avatarModel = new PublicProfileAvatarComponentModel();

    avatarModel.personId = item.idPersona;
    avatarModel.avatarUrl = item.urlImagen;
    avatarModel.fullName = item.nombre;
    avatarModel.sex = item.sexo;
    avatarModel.profileType = item.tipoPerfilPublico;    
    avatarModel.useAvatarPadding = false;

    return avatarModel;
  }

  getAvatarModelByLastestDoctor(item:AppointmentDoctor) : PublicProfileAvatarComponentModel {
    var avatarModel = new PublicProfileAvatarComponentModel();

    avatarModel.personId = item.idResponsableServicio;
    avatarModel.avatarUrl = item.urlAvatarResponsableServicio;
    avatarModel.fullName = item.nombreResponsableServicio;
    avatarModel.sex = item.sexoResponsableServicio;
    avatarModel.profileType = PublicProfileType.Doctor;

    return avatarModel;
  }

  prevLastestDoctors(){
    if (this.lastestDoctorsIndex > 0) {
      this.lastestDoctorsIndex -= this.maxLastestDoctorsToShow;
    }
  }

  nextLastestDoctors(){
    if (this.lastestDoctorsIndex + this.maxLastestDoctorsToShow < this.lastestDoctors.length) {
      this.lastestDoctorsIndex += this.maxLastestDoctorsToShow;
    }
  }

  showLastestDoctors(){
    return (this.loadingLastestDoctors || this.lastestDoctors.length > 0) && this.searchInput && !this.searchInput.nativeElement.value;
  }

  showSearchTextOption(){
    return this.providerSelected == null 
      && this.searchInput 
      && this.searchInput.nativeElement 
      && this.searchInput.nativeElement.value
      && this.filteredResultsCount > 0;
  }

  getSearchTextOption(){
    return this.searchInput 
    && this.searchInput.nativeElement 
    && this.searchInput.nativeElement.value
      ? this.searchInput.nativeElement.value
      : "";
  }

  private parseControlData(){
    if(this.data && this.data.valor){
      try {
        var asd = JSON.parse(this.data.valor);

        this.model = new SearchProvidersConfiguration(JSON.parse(this.data.valor));
      } catch (ex) {}   
    }    
  }

  private populateDropdownByParams(paramMap:SearchQsParamMapModel, qsParams: SearchQsParametersModel){
    if(paramMap.param1){
      let _locationObjectSelected =  new LocationSearchResultItem();

      if(paramMap.param1 == "texto"){
        this.searchProviderCtrl.setValue({ nombre: qsParams.t });

        // carga de dropdown localidad
        if(paramMap.param4){
          _locationObjectSelected.url = `${paramMap.param2}/${paramMap.param3}/${paramMap.param4}`;
          _locationObjectSelected.nombre = paramMap.param4.replace(/-/g, ' ');
        }
        else if(paramMap.param3){
          _locationObjectSelected.url = `${paramMap.param2}/${paramMap.param3}`;
          _locationObjectSelected.nombre = paramMap.param3.replace(/-/g, ' ');
        }
        else if(paramMap.param2){
          _locationObjectSelected.url = `${paramMap.param2}`;
          _locationObjectSelected.nombre = paramMap.param2.replace(/-/g, ' ');
        }
      }
      // caso de que se selecciono localidad y el dropdown de proveedores no.
      else if(paramMap.param1 == "todos"){
        if(paramMap.param4){
          _locationObjectSelected.url = `${paramMap.param2}/${paramMap.param3}/${paramMap.param4}`;
          _locationObjectSelected.nombre = paramMap.param4.replace(/-/g, ' ');
        }
        else if(paramMap.param3){
          _locationObjectSelected.url = `${paramMap.param2}/${paramMap.param3}`;
          _locationObjectSelected.nombre = paramMap.param3.replace(/-/g, ' ');
        }
        else{
          _locationObjectSelected.url = `${paramMap.param2}`;
          _locationObjectSelected.nombre = paramMap.param2.replace(/-/g, ' ');
        }          
      }
      else {
        this.providerSelected = new DoctorSearchResult();
        this.providerSelected.nombre = paramMap.param2.replace(/-/g, ' ');
        this.providerSelected.url = `/${paramMap.param1}/${paramMap.param2}`;

        if(qsParams.idC)
          this.providerSelected.id = +qsParams.idC;

        if(qsParams.idTc)
          this.providerSelected.claseId = +qsParams.idTc;

        this.searchProviderCtrl.setValue(this.providerSelected);

        // carga de dropdown localidad
        if(paramMap.param5){
          _locationObjectSelected.url = `${paramMap.param3}/${paramMap.param4}/${paramMap.param5}`;
          _locationObjectSelected.nombre = paramMap.param5.replace(/-/g, ' ');
        }
        else if(paramMap.param4) {
          _locationObjectSelected.url = `${paramMap.param3}/${paramMap.param4}`;
          _locationObjectSelected.nombre = paramMap.param4.replace(/-/g, ' ');
        }
        else if(paramMap.param3){
          _locationObjectSelected.url = `${paramMap.param3}`;
          _locationObjectSelected.nombre = paramMap.param3.replace(/-/g, ' ');
        }
      }

      if(this.locationDefault && qsParams && qsParams.filtros){

        let conceptIds = qsParams.filtros.split(',').map(Number);
        let conceptSelected = this.locationDefault.seccion3.find(c=> conceptIds.includes(c.id));

        if(!conceptSelected) {
          let concept = this.locationDefault.seccion1.find(c=> conceptIds.includes(c.id));
          
          if (concept) {
            conceptSelected = new TerritorialDivision();
            conceptSelected.id = concept.id;

            let name = concept.nombre.find(l => l.lenguaje == this.locale.language);
            conceptSelected.nombre = name? name.texto : "";
          }          
        }

        if(conceptSelected){
          _locationObjectSelected = new LocationSearchResultItem();
          _locationObjectSelected.id = conceptSelected.id;
          _locationObjectSelected.nombre = this.layoutService.getTextByLanguage(conceptSelected.nombre, this.locale.language);
        }
      }

      if(_locationObjectSelected.nombre){
        this.locationObjectSelected = _locationObjectSelected;
        this.searchLocationCtrl.setValue(this.locationObjectSelected);
      }

      if (!this.providerSearchMessage) {
        let territorialDivisionIdentification = this.locationObjectSelected? this.locationObjectSelected.url : "";

        this.providerSearchMessage = new ProviderSearchMessage();
        this.providerSearchMessage.filtros = this.qsParams.filtros;
        this.providerSearchMessage.territorialDivisionIdentification = territorialDivisionIdentification;
        this.providerSearchMessage.countryIdentification = this.searchCountry?.identificador;
        this.providerSearchMessage.searchText = this.qsParams.t;
        this.providerSearchMessage.conceptId = this.qsParams.idC? parseInt(this.qsParams.idC) : 0;
        this.providerSearchMessage.conceptClaseId = this.qsParams.idTc? parseInt(this.qsParams.idTc) : 0;
      }

      this.setInsuranceBaseOnQueryParams();
    }
  }

  private initEmptyProviderSearchMessage() {
    this.providerSearchMessage = new ProviderSearchMessage();
    this.providerSearchMessage.countryIdentification = this.searchCountry?.identificador;
  }

  private setInsuranceBaseOnQueryParams() {
    if(this.qsParams && this.qsParams.filtros) {
      this.loadFilters().subscribe(filters => {
        //if(this.model.insurersDefault && this.qsParams && this.qsParams.filtros){
        
        if(this.insuranceUnfilteredResults){
          let conceptIds = this.qsParams.filtros.split(',').map(Number);
          /*let conceptSelected = this.model.insurersDefault.find(c=> conceptIds.includes(c.id));

          if(conceptSelected) {
            this.insuranceObjectSelected = new ProfileFilterSearchResultConcept();
            this.insuranceObjectSelected.cc = conceptSelected.id;
            this.insuranceObjectSelected.n_cc = this.layoutService.getTextByLanguage(conceptSelected.nombre, this.locale.language);

            this.searchInsuranceCtrl.setValue(this.insuranceObjectSelected);
          }*/

          let conceptSelected = this.insuranceUnfilteredResults.find(c=> conceptIds.includes(c.cc));

          if(conceptSelected) {
            this.insuranceObjectSelected = conceptSelected;

            this.searchInsuranceCtrl.setValue(this.insuranceObjectSelected);
          }
        }
      });
    }
  }

  private getProfilesDefault(){
    return (this.model.profilesDefault && this.model.profilesDefault.length > 0)
      ? this.model.profilesDefault.map(cd => {
          let doctorSearchResult = new DoctorSearchResult();
          doctorSearchResult.id = cd.id;
          doctorSearchResult.claseId = cd.claseId;
          doctorSearchResult.clase = this.translation.translate('shared.searchResults.searchProvidersV2.suggestionsText');
          doctorSearchResult.nombre = this.layoutService.getTextByLanguage(cd.nombre, this.locale.language);
          doctorSearchResult.descripcion = this.layoutService.getTextByLanguage(cd.descripcion, this.locale.language);
          doctorSearchResult.url = cd.url;
          doctorSearchResult.urlImagen = cd.urlImagen;

          return doctorSearchResult;
        })
      : [];
  }  

  private fireSearchEvent(){
    let _searchText = this.searchInput 
      ? this.searchInput.nativeElement.value.trim()
      : '';

    let _providerSearchMessage = new ProviderSearchMessage();

    _providerSearchMessage.countryIdentification = this.searchCountry.identificador;

    if(this.providerSelected){
      _providerSearchMessage.conceptNav = this.providerSelected.url;
      _providerSearchMessage.conceptId = this.providerSelected.id;
      _providerSearchMessage.conceptClaseId = this.providerSelected.claseId;
    }
    else {
      _providerSearchMessage.searchText = _searchText;
    }

    if(this.locationObjectSelected){
      if(this.locationObjectSelected.url){
        _providerSearchMessage.territorialDivisionIdentification = this.locationObjectSelected.url;
      }
      else {
        _providerSearchMessage.filtros = this.locationObjectSelected.id.toString();
      }
    }
    else{
      this.searchLocationCtrl.reset();
    }

    if(this.insuranceObjectSelected){
      if(this.insuranceObjectSelected.cc > 0){
        _providerSearchMessage.filtros = _providerSearchMessage.filtros
          ? _providerSearchMessage.filtros + ',' + this.insuranceObjectSelected.cc.toString()
          : this.insuranceObjectSelected.cc.toString();
      }
    }
    else{
      this.searchInsuranceCtrl.reset();
    }

    if (!this.providerSearchMessage)
      this.providerSearchMessage = _providerSearchMessage;

    this.triggerSearchByType(_providerSearchMessage);    
  }

  private triggerSearchByType(providerSearchMessage: ProviderSearchMessage){

    switch(this.model.searchType){
      case SearchProvidersType.DEFAULT:
      default:
        this.messageBusService.providerSearch(providerSearchMessage);
        break;
      case SearchProvidersType.POPUP:
        this.redirectToSearchPage(providerSearchMessage);
        break;
      case SearchProvidersType.WIDGET:
        this.goToSearhPage(providerSearchMessage);
        break;
    }
  }

  private triggerOpenPublicProfileByType(publicProfileUrl:string){
    switch(this.model.searchType){
      case SearchProvidersType.DEFAULT:
      case SearchProvidersType.POPUP:
      default:
        this.publicProfileService.openPublicProfile(publicProfileUrl);
        break;
      case SearchProvidersType.WIDGET:
        this.goToPublicPage(publicProfileUrl)
        break;
    }
  }

  private redirectToSearchPage(providerSearchMessage: ProviderSearchMessage){

    let searchProviderUrl = this.getRelativeSearchPath(providerSearchMessage);

    this.router.navigate([searchProviderUrl.url], {queryParams:searchProviderUrl.qsParams});
  }

  private getRelativeSearchPath(providerSearchMessage: ProviderSearchMessage):SearchProvidersUrl{
    let paramMap:SearchQsParamMapModel = new SearchQsParamMapModel();
    let qsParams:SearchQsParametersModel = new SearchQsParametersModel();

    // busqueda por texto
    if(providerSearchMessage.searchText){
      paramMap.param1 = 'texto';

      qsParams.t = providerSearchMessage.searchText;
    }
    // busqueda por concepto
    else if(providerSearchMessage.conceptNav){

      let conceptNavArray = providerSearchMessage.conceptNav.split('/');

      paramMap.param1 = conceptNavArray[1];
      paramMap.param2 = conceptNavArray[2];
    }
    else{
      paramMap.param1 = 'todos';
    }

    if(providerSearchMessage.territorialDivisionIdentification){

      let tdArray = providerSearchMessage.territorialDivisionIdentification.split('/');

      if(paramMap.param2){
        paramMap.param3 = tdArray[0];
        paramMap.param4 = tdArray[1] ?? '';
        paramMap.param5 = tdArray[2] ?? '';
      }
      else{
        paramMap.param2 = tdArray[0];
        paramMap.param3 = tdArray[1] ?? '';
        paramMap.param4 = tdArray[2] ?? '';
      }
    }

    qsParams.filtros = providerSearchMessage.filtros;
    
    let url = `${this.baseService.getLanguage()}/${this.model.searchParamMap}/${paramMap.param1}`;
    url += paramMap.param2 ? `/${paramMap.param2}` : '';
    url += paramMap.param3 ? `/${paramMap.param3}` : '';
    url += paramMap.param4 ? `/${paramMap.param4}` : '';
    url += paramMap.param5 ? `/${paramMap.param5}` : '';

    if (providerSearchMessage.conceptId > 0){
      qsParams.idC = providerSearchMessage.conceptId.toString();
      qsParams.idTc = providerSearchMessage.conceptClaseId.toString();
    }

    let searchProviderUrl = new SearchProvidersUrl();

    searchProviderUrl.url = url;
    searchProviderUrl.qsParams = qsParams;

    return searchProviderUrl;
  }

  private goToPublicPage(publicProfileUrl:string){
    if(this.isBrowser){
      const url = this.router.serializeUrl(
        this.router.createUrlTree([publicProfileUrl])
      );
  
      window.parent.location.href = url;
    }
  }

  private goToSearhPage(providerSearchMessage: ProviderSearchMessage) {
    if(this.isBrowser){
      let searchProviderUrl = this.getRelativeSearchPath(providerSearchMessage);

      const url = this.router.serializeUrl(
        this.router.createUrlTree([searchProviderUrl.url], {queryParams:searchProviderUrl.qsParams})
      );

      window.parent.location.href = url;
    }
  }

  private getLocationSearchGroupByDefault():LocationSearchResultGroup[]{
    let _resultGroup:LocationSearchResultGroup[] = [];

    if(!this.locationDefault)
      return _resultGroup; 

    // SECCION 1
    let _section2Grouped = this.locationDefault.seccion1 && this.locationDefault.seccion1.length > 0 
      ? this.utilsService.groupBy<ConceptDefaultModel, number>(this.locationDefault.seccion1, sd => sd.claseId)
      : new Map<TerritorialDivision, number>();

    _section2Grouped.forEach((value: ConceptDefaultModel[], key: number)=>{
      let resultGroupItem = new LocationSearchResultGroup();
      resultGroupItem.id = key;
      resultGroupItem.displayName = this.layoutService.getTextByLanguage(value[0].clase, this.locale.language);

      resultGroupItem.results = value.map(td => { 
        return {
          id: td.id,
          nombre: this.layoutService.getTextByLanguage(td.nombre, this.locale.language),
          url: "",
          icono: 'phr-iconset-hospital'
        };
      });

      _resultGroup.push(resultGroupItem);
    });

    // SECCION 2
    let _section1Grouped = this.locationDefault.seccion2 && this.locationDefault.seccion2.length > 0 
      ? this.utilsService.groupBy<TerritorialDivision, number>(this.locationDefault.seccion2, sd => sd.nivel)
      : new Map<TerritorialDivision, number>();

    _section1Grouped.forEach((value: TerritorialDivision[], key: number)=>{
      let resultGroupItem = new LocationSearchResultGroup();
      resultGroupItem.id = key;
      resultGroupItem.displayName = this.getLocationDisplayNameFromLevelDefault(key);

      resultGroupItem.results = value.map(td => { 
        return {
          id: td.id, 
          nombre: this.layoutService.getTextByLanguage(td.nombreCanonico, this.locale.language),
          url: td.identificador, 
          icono: 'phr-iconset-suraplace'
        };
      });

      _resultGroup.push(resultGroupItem);
    });

    // SECCION 3
    let _section3Grouped = this.locationDefault.seccion3 && this.locationDefault.seccion3.length > 0 
      ? this.utilsService.groupBy<TerritorialDivision, number>(this.locationDefault.seccion3, sd => sd.nivel)
      : new Map<TerritorialDivision, number>();

    _section3Grouped.forEach((value: TerritorialDivision[], key: number)=>{
      let resultGroupItem = new LocationSearchResultGroup();
      resultGroupItem.id = key;
      resultGroupItem.displayName = this.getLocationDisplayNameFromLevelDefault(key);

      resultGroupItem.results = value.map(td => { 
        return {
          id: td.id,
          nombre: this.layoutService.getTextByLanguage(td.nombreCanonico, this.locale.language),
          url: td.identificador,
          icono: 'phr-iconset-suraplace' 
        };
      });

      _resultGroup.push(resultGroupItem);
    });

    return _resultGroup;
  }
  
  private getLocationsByLevels(value:string):Observable<LocationSearchResultGroup[]>{
    return this.territorialDivisionsService.getByLevels(
      this.language,
      value, 
      this.locationLevels,
      this.searchCountry.idPais)
      .pipe(map((results:TerritorialDivision[])=>{
      let _resultGroup:LocationSearchResultGroup[] = [];
      let _territorialDivisionGrouped = this.utilsService.groupBy<TerritorialDivision, number>(results, sd => sd.nivel);

      _territorialDivisionGrouped.forEach((value: TerritorialDivision[], key: number)=>{
        let resultGroupItem = new LocationSearchResultGroup();
        resultGroupItem.id = key;
        resultGroupItem.displayName = this.getLocationDisplayNameFromLevel(key);
  
        resultGroupItem.results = value.map(td => { 
          return {id: td.id, nombre: td.nombreCanonico, url: td.identificador, icono: 'phr-iconset-suraplace' };
        });
  
        _resultGroup.push(resultGroupItem);
      });

      return _resultGroup;
    }));
  }

  private getLocationDisplayNameFromLevel(level: number) {
    return level == 1 ? this.countryText : (level == 2 ? this.stateText : this.cityText);
  }

  private getLocationDisplayNameFromLevelDefault(level: number) {
    switch(level){
      case 1:
        return this.countryDefaultText;
      case 2:
        return this.stateDefaultText;
      case 3:
        return this.cityDefaultText;
      default:
          return "";
    }
  }

  /*private getOtherInsuranceCompanies() {
    this.clinicalConceptsService.getInsuraceConcepts()
      .subscribe(concepts => {
        // Filter out all insurance companies that are already on defaults
        let filteredConcepts = concepts.filter(c => !this.model.insurersDefault.find(d => d.id == c.id));

        this.otherInsuranceComp = filteredConcepts.map(c => {
          let model = new ConceptDefaultModel();
          
          let classNameEs = new TextLanguage();
          classNameEs.lenguaje = "es";
          classNameEs.texto = "otras aseguradoras";
          let classNameEn = new TextLanguage();
          classNameEn.lenguaje = "en";
          classNameEn.texto = "Other insurances";

          let insuranceName = new TextLanguage();
          insuranceName.lenguaje = this.locale.language;
          insuranceName.texto = c.nombre;

          model.id = c.id;
          model.clase = [classNameEs, classNameEn];
          model.claseId = 0;
          model.descripcion = [insuranceName];
          model.nombre = [insuranceName];

          return model;
        });

        // Add the other insurance companies to the defaults
        this.model.insurersDefault = this.model.insurersDefault.concat(this.otherInsuranceComp);

        if (this.otherInsuranceComp.length > 0 && this.qsParams && this.qsParams.filtros) {
          this.setInsuranceBaseOnQueryParams();
        }
      }
    );
  }*/  

  private searchInputBlur(input: HTMLInputElement): void {
    if(this.isMobile || this.fullsize) {
      input?.blur();
    }
  }

  private setDefaultsText(){
    this.cityText = this.translation.translate('city');
    this.stateText = this.translation.translate('province');
    this.countryText = this.translation.translate('country');
    this.cityDefaultText = this.translation.translate('shared.searchResults.searchProvidersV2.mostWantedCities');
    this.stateDefaultText = this.translation.translate('shared.searchResults.searchProvidersV2.mostWantedProvinces');
    this.countryDefaultText = this.translation.translate('shared.searchResults.searchProvidersV2.mostWantedCountries');
    this.dontApplyInsuranceText = this.translation.translate('shared.searchResults.searchProvidersV2.dontApplyInsuranceText');
  }
  
  private collectParams(snapshot: ActivatedRouteSnapshot): Params {
    let params: Params = { ...snapshot.params, ...snapshot.queryParams };

    const childSnapshots = snapshot.children;
  
    while (childSnapshots.length) {
      snapshot = childSnapshots[0];
      params = { ...params, ...snapshot.params, ...snapshot.queryParams };
      childSnapshots.shift();
      childSnapshots.push(...snapshot.children);
    }
  
    return params;
  }

  private getLatestDoctors() {
    this.loadingLastestDoctors = true;
    this.appointmentService.getLatestDoctors(this.locale.language, 20)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      doctors => {
        this.loadingLastestDoctors = false;

        this.lastestDoctors = doctors;

        this.lastestDoctors.forEach(d => {
          let avatarInitialsObject = this.baseService.getAvatarInitialObject(d.idResponsableServicio, d.nombreResponsableServicio);
          d.iniciales = avatarInitialsObject.initials;
        });
      },
      error => { this.baseService.handleServiceError(error, "Error getting latest doctors"); }
    );   
  }

  
  private configSearchByCountry(country:TerritorialDivision){
    let availableCountries = this.territorialDivisionsService.getAvailableCountries();

    if(country.idPais != 9 && country.idPais != 1 && availableCountries.find(c=> c.idPais == country?.idPais)){
      this.searchCountry = country;
    }
    else{
      this.searchCountry = new TerritorialDivision ();
      this.searchCountry.idPais = -1;
      this.searchCountry.identificador = '';
    }
  }

  private configSearchByCountryApp(){
    this.territorialDivisionsService.getCountryApp().subscribe(countryApp=>{
      if(countryApp && countryApp.territorialDivision){
        this.configSearchByCountry(countryApp.territorialDivision)
      }
    });
  }

  private setLocationDefaultByCountry(){

    this.locationDefault = this.model.locationsDefault.find(s=> s.idPais == this.searchCountry.idPais);

    // si no encuentra una sección, se establece por defecto la de panamá
    if(!this.locationDefault)
      this.locationDefault = this.model.locationsDefault.filter(s=> s.idPais == 28)[0];

    this.locationLevels = this.locationDefault.niveles;
  }

  private toggleAutocompleteInsurance(providerSearchMessage: ProviderSearchMessage){

    if(!this.model.showInsurersAutocomplete || !this.insuranceUnfilteredResults)
      return;

    if(providerSearchMessage.filtros) {
      let filters = providerSearchMessage.filtros.split(',').map(f=> +f);
      
      let selectedInsurers = this.insuranceUnfilteredResults.find(concept => filters.includes(concept.cc));

      if(selectedInsurers) {
        this.insuranceObjectSelected = selectedInsurers;
        this.searchInsuranceCtrl.setValue(this.insuranceObjectSelected);
      }
      
      //let selectedInsurers = this.model.insurersDefault.filter(insuranceDefault => filters.includes(insuranceDefault.id));

      /*if(selectedInsurers.length > 0) {
        this.insuranceObjectSelected = new ProfileFilterSearchResultConcept();
        this.insuranceObjectSelected.cc = selectedInsurers[0].id;
        this.insuranceObjectSelected.n_cc = this.layoutService.getTextByLanguage(selectedInsurers[0].nombre, this.locale.language);

        this.searchInsuranceCtrl.setValue(this.insuranceObjectSelected);
      }*/
    }
    else{
      this.searchInsuranceCtrl.reset('');

      if(this.searchInsuranceInput){
        this.searchInsuranceInput.nativeElement.value = '';
      }
  
      this.insuranceObjectSelected = null;
    }
  }

  /********************** Insurance Search *************************/

  configSearchInsurers(){
    if(!this.model.showInsurersAutocomplete)
      return;

    //this.getOtherInsuranceCompanies();

    this.filteredInsuranceResults$ = this.searchInsuranceCtrl.valueChanges
      .pipe(
        tap(()=>{ 
          this.loadingSearchInsurance = true;
        }),
        debounceTime(0),
        startWith(''),
        switchMap(value => {

          if(!this.insuranceAutocompleteOpen){
            this.loadingSearchInsurance = false;
            return of([]);
          }

          if(this.model.insurersLikeCombo){
            let options = this.filterDefaultInsurers(value);

            this.loadingSearchInsurance = false;
            this.highlightValue = value;
            this.filteredInsuranceResultsCount = options.length;
  
            return of(options);
          }
          else{
            return of([]);
          }
        })
      );
  }

  filterDefaultInsurers(value:any) : ProfileFilterSearchResultGroup[]{
    let optionsGrouped = this.getInsuranceSearchGroupByDefault();    

    if(value){

      const textValue = (typeof value === 'string')
        ? value.toLowerCase()
        : value.nombre.toLowerCase();

      optionsGrouped = optionsGrouped.filter(og => og.results.filter(r => r.n_cc.toLowerCase().includes(textValue)).length > 0);

      optionsGrouped.forEach(og => {
        og.results = og.results.filter(r => r.n_cc.toLowerCase().includes(textValue));
      });

      return optionsGrouped;
    }
    else{
      return optionsGrouped;
    }
  }

  private getInsuranceSearchGroupByDefault() : ProfileFilterSearchResultGroup[] {
    return this.publicProfileService.groupProfileFilters(this.insuranceUnfilteredResults, this.insuranceFilter.grupos, this.locale.language);
  }

  onFocusInputInsuranceSearch(){
    event?.preventDefault();

    this.insuranceAutocompleteOpen = true;

    // Re-load the filters when opening the insurance
    this.loadFilters()
      .subscribe(filters => {

        // ejecutar la busqueda del autocomplete si el input tiene un texto
        if(this.searchInsuranceInput.nativeElement.value){
          this.autocompleteInsuranceTrigger._onChange(this.searchInsuranceInput.nativeElement.value);
        }
        // si no tiene texto y valores por defecto, se agrega un timeout para que reposicione el dropdown
        else if(filters.length > 0) {    
          setTimeout(()=>{
            this.autocompleteInsuranceTrigger._onChange('');
            this.autocompleteInsuranceTrigger.updatePosition();
          }, 100);
        }
      },
      error => {

      }
    );
  }

  private getDirectoryFilters() {    
    let directory = this.baseService.getDirectory();

    if (directory && directory.filtros) {
      let filtersObj = JSON.parse(directory.filtros);
      let directoryFilters = this.baseService.parseObject<DirectoryFiltersModel>(filtersObj);            

      // Find the Insurance provider   
      for(let p of directoryFilters.providers) {
        let filter = p.filters.find(f => f.idClaseConceptoClinico == 8);

        if (filter) {
          this.insuranceFilter = filter;
          break;
        }
      }
    }
  }
  
  private loadFilters() : Observable<ProfileFilterSearchResult[]> {
    if (!this.providerSearchMessage)
      this.initEmptyProviderSearchMessage();

    let searchText = this.providerSearchMessage.searchText;
    let conceptType = "";
    let concept = "";

    let filtersString = this.providerSearchMessage.filtros;

    let accountName = this.baseService.getCliniwebDirectoryCompanyName();
    let accountId = this.baseService.getCliniwebDirectoryCompanyId();
    let locationCompanyIds = this.baseService.getCliniwebDirectoryLocationCompanyIds();        

    let location = this.baseService.getLocationFromIdentifier(this.providerSearchMessage.territorialDivisionIdentification);

    if(!location.country && this.providerSearchMessage.countryIdentification){
      location.country = this.providerSearchMessage.countryIdentification;
    }

    if (!accountName)
        accountName = this.baseService.getCompanyName();

    if (this.providerSearchMessage.conceptNav) {
        // Some time the navigation contains '/' like: '/especialidad/cardiologia' in those cases filter the empty strings after split
        let parts = this.providerSearchMessage.conceptNav.split("/").filter(e => e);
        conceptType = parts[0];
        concept = parts[1];
    }    

    let profileSearchTypes = this.model.profileTypes.split(",").map(pt => +pt);

    return this.publicProfileService.getPublicProfileFilters(
      this.baseService.getLanguage(),
      conceptType,
      searchText, 
      concept,
      filtersString,
      accountName,
      location.country,
      location.state,
      location.city,
      8, // Insurance
      profileSearchTypes,
      accountId,
      locationCompanyIds,
      this.providerSearchMessage.conceptId,
      this.providerSearchMessage.conceptClaseId
    ).pipe(
      map((result:ProfileFilterSearchResult[]) =>{
        if (!result || result.length == 0)
          return [];

        this.insuranceUnfilteredResults = result[0].conceptos;

        return result;
      }),
      catchError((err) => {
        this.baseService.handleServiceError(err, "Error getting profile filters");          
        return of([]);
      })
    );      
  }  

}

export class DoctorSearchResultGroup {
  classId: number;
  className: string;
  results: DoctorSearchResult[];
}

export class LocationSearchResultGroup {
  id: number;
  displayName: string;
  results: LocationSearchResultItem[];
}

export class LocationSearchResultItem {
  id: number;
  nombre: string;
  url: string;
  icono?: string;
}

export class SearchProvidersUrl{
  url: string;
  qsParams:SearchQsParametersModel;
}