import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'customDate'
  })
  export class CustomDatePipe implements PipeTransform {
    transform(value: any, format: string = 'mediumDate', timezone: string = 'UTC', locale: string = 'es-AR'): string | null {
      const datePipe = new DatePipe(locale);
      let formattedDate = datePipe.transform(value, 'dd MMM y', timezone, locale);
      
      // Agrega el punto a los meses abreviados si falta
      if (formattedDate) {
        formattedDate = formattedDate.replace(/\b(ene|feb|mar|abr|may|jun|jul|ago|sep|oct|nov|dic)\b/g, '$&.');
      }
      
      return formattedDate;
    }
}