import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
};


function ensureUrlIsString(url: any): string {
  if (typeof url === 'string') {
    return url;
  } else {
    console.error('URL is not a string:', url);
    return '';
  }
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    // Intercepta y corrige la URL antes de pasarla a cualquier función que la manipule
    const url = ensureUrlIsString(window.location.href);
    const urlsSpinner = ['ordenes-medicas/landing'];

    if (urlsSpinner.some(x => url.includes(x))) {
      const mainSpinner = document.getElementsByClassName('main-spinner');
      mainSpinner.item(0)?.classList.add('loading');
    }

    bootstrap();
  });
}
