import { ChangeDetectorRef, Component, EventEmitter, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-growth-chart-type-select',
  templateUrl: './growth-chart-type-select.component.html',
  styleUrls: ['./growth-chart-type-select.component.css']
})
export class GrowthChartTypeSelectComponent {

    @Output() valueChanges = new EventEmitter<GropwthViewTypeOption>();
    private lastOptionSelected = GropwthViewTypeOption.GRAFICO;

    selectionControl = new FormControl();
    typeOptions: GropwthViewType[] = [
        {
            id: GropwthViewTypeOption.GRAFICO,
            text: 'Gráfico'
        },
        {
            id: GropwthViewTypeOption.TABLA,
            text: 'Tabla'
        },
    ];
    isVisible = false;

    constructor(private cdr: ChangeDetectorRef) {
        this.selectionControl.valueChanges.subscribe((x: GropwthViewTypeOption) => {
            this.lastOptionSelected = x;
            this.valueChanges.emit(x);
        });
        this.selectionControl.setValue(GropwthViewTypeOption.GRAFICO);
    }

    getValue(): GropwthViewTypeOption {
        return this.selectionControl.value;
    }

    setVisibility(value: boolean) {
        this.isVisible = value;
    }

    resume() {
        this.isVisible = true;
        if (this.selectionControl.value != this.lastOptionSelected) {
            this.selectionControl.setValue(this.lastOptionSelected, {emitEvent: false});
            this.valueChanges.emit(this.lastOptionSelected);
            this.isVisible = true;
            this.cdr.detectChanges();
        }
    }

    goToEmptyState() {
        this.isVisible = false;
        if (this.selectionControl.value != GropwthViewTypeOption.GRAFICO) {
            this.lastOptionSelected = this.selectionControl.value;
            this.selectionControl.setValue(GropwthViewTypeOption.GRAFICO, {emitEvent: false});
            this.valueChanges.emit(GropwthViewTypeOption.GRAFICO);
            this.cdr.detectChanges();
        }
    }

}

export enum GropwthViewTypeOption {
    GRAFICO = 1,
    TABLA = 2
}

export interface GropwthViewType {
    id: GropwthViewTypeOption;
    text: string;
}