import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { RouteDialogHeaderModel } from '../../../dialogs/route-dialog/route-dialog.component';
import { MessageBusService, NavigationBackMessage } from 'src/app/core/services/message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-default-back-with-link-header',
  templateUrl: './default-back-with-link-header.component.html',
  styleUrls: ['./default-back-with-link-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('DefaultBackWithLinkHeaderComponent')
export class DefaultBackWithLinkHeaderComponent implements OnInit, IControlComponent, OnDestroy {

  private ngUnsubscribe = new Subject();

  constructor(
    private messageBusService: MessageBusService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
  data: RouteDialogHeaderModel;

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onBackClick(){
    let dialogId = this.data.dialogModel.id;

    this.messageBusService.navigateBack(new NavigationBackMessage(dialogId));
  }

  onOpenInNewWindow() {
    if(isPlatformBrowser(this.platformId)){
      let url = window.location.href;

      window.open(url, "_blank");
    }
  }
}