import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { BaseService } from 'src/app/core/services/base.service';
import { UserService } from 'src/app/core/services/user.service';
import { MessageBusService, ServiceErrorMessage } from 'src/app/core/services/message-bus.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { WorkflowLookup } from 'src/app/shared/decorators/workflow-lookup.decorator';
import { OnlineAppointmentRequest } from 'src/app/shared/models/process/online-appointment-request.model';
import { AppointmentCheckout } from 'src/app/shared/models/workflow/models/appointment-checkout.model';
import { OnlineAppointmentCliniwebState } from 'src/app/shared/models/workflow/states/online-appointment-cliniweb-state.model';
import { BaseWorkflowComponent } from '../base-workflow/base-workflow.component';
import { DOCUMENT, Location } from '@angular/common';
import { OnlineAppointmentStatus } from 'src/app/shared/enums/online-appointment-status.enum';
import { WorkflowStepService } from 'src/app/core/services/workflow-step.service';
import { StatesRequestVerifyDataModel } from '../../states-request-verify/states-request-verify.component';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { RouteDialogType } from '../../dialogs/route-dialog/route-dialog.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyModel } from 'src/app/shared/models/systems/company.model';

@Component({
  selector: 'app-online-appointment-cliniweb',
  templateUrl: './online-appointment-cliniweb.component.html',
  styleUrls: [
    '../base-workflow/base-workflow.component.css',
    './online-appointment-cliniweb.component.css'
  ],
  encapsulation: ViewEncapsulation.None
})
@WorkflowLookup('OnlineAppointmentCliniwebComponent')
export class OnlineAppointmentCliniwebComponent extends BaseWorkflowComponent implements OnInit {  
  state: OnlineAppointmentCliniwebState = new OnlineAppointmentCliniwebState();

  showState:boolean = false;
  onlineAppointmentStatus: OnlineAppointmentStatus;
  statesRequestVerifyDataModel: StatesRequestVerifyDataModel;
  activatingAccount: boolean;

  logoutFn: any;

  constructor(
    protected route: ActivatedRoute,
    private router: Router,
    protected location: Location,
    private appointmentService: AppointmentService,
    private userService: UserService,
    private translation: L10nTranslationService,
    private dialog: MatDialog,
    protected publicProfileService: PublicProfileService,
    private routeDialogService: RouteDialogService,
    private authService: AuthService,
    protected baseService: BaseService,
    protected messageBusService: MessageBusService,
    protected workflowService: WorkflowService,
    protected workflowStepService: WorkflowStepService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private appStorageService: AppStorageService,
    @Inject(DOCUMENT) protected document: Document
  ){
    super(
      location,
      route,
      baseService, 
      messageBusService, 
      publicProfileService, 
      workflowService, 
      workflowStepService, 
      document);

    this.logoutFn = this.logout.bind(this);
  }
  
  ngOnInit() {
    if(this.data && this.data.idVerificacion){
      this.checkOnlineAppointmentStatus();
    } else{
      this.startWorkflow();
    }
  }

  logout() {
    this.authService.logout();

    this.stepIndex = 0;
    this.loadStep();    
  }

  checkOnlineAppointmentStatus(){
    this.appointmentService.getStatus(this.data.idVerificacion).subscribe({
      next: this.getStatusNext.bind(this), 
      error: _ => this.messageBusService.serviceError(new ServiceErrorMessage("Error getting request status"))
    });
  }

  private getStatusNext(status: OnlineAppointmentStatus): void {
    this.onlineAppointmentStatus = status;

    if (this.onlineAppointmentStatus == OnlineAppointmentStatus.POR_COMPLETAR) {
      this.showState = false;
      this.startWorkflow();
    }
    else if (this.onlineAppointmentStatus == OnlineAppointmentStatus.USUARIO_POR_REGISTRAR) {
      this.showState = false;

      this.activatingAccount = true;

      this.userConfirmation().subscribe(this.userConfirmationNext.bind(this));
    }
    else if(this.onlineAppointmentStatus == OnlineAppointmentStatus.COMPLETADA) {
      this.authService.isLoggedInFull().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.isLoggedInFullNext.bind(this));
    }
    else{
      this.configStatusView();
      this.showState = true;
    }
  }

  private userConfirmationNext(result: boolean): void {
    if (result) {
      this.configStatusView();
      this.showState = true;
    } else{
      this.messageBusService.serviceError(new ServiceErrorMessage("Error confirming user"));
    }
    setTimeout(() => this.activatingAccount = false, 500);
  }

  private isLoggedInFullNext(isLoggedIn: boolean): void {
    if (isLoggedIn) {
      this.stepIndex = this.model.items.length - 1;
      this.startWorkflow();
    } else{
      this.router.navigate(['welcome', 'login'], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
    } 
  }

  configStatusView(): void {
    this.statesRequestVerifyDataModel = new StatesRequestVerifyDataModel();

    this.statesRequestVerifyDataModel.companyLogoUrl = 'assets/images/cliniweb/logo-cliniweb-phr.svg';
    this.statesRequestVerifyDataModel.mainImageUrl = 'assets/images/cliniweb/Campana.gif';
    this.statesRequestVerifyDataModel.showActionButton = true;
    this.statesRequestVerifyDataModel.showHomeButton = false;

    switch(this.onlineAppointmentStatus){
      case OnlineAppointmentStatus.USUARIO_POR_REGISTRAR:
        this.statesRequestVerifyDataModel.buttonActionFn = this.continueWithAppointment.bind(this);
        this.statesRequestVerifyDataModel.buttonTextRefI18n = 'shared.statesRequestVerify.continueWithAppointment';
        this.statesRequestVerifyDataModel.textRefI18n = 'shared.statesRequestVerify.accountActivatedInCliniweb';
        break;
      case OnlineAppointmentStatus.COMPLETADA:
        this.statesRequestVerifyDataModel.buttonActionFn = this.gotoAppointments.bind(this);
        this.statesRequestVerifyDataModel.buttonTextRefI18n = 'shared.statesRequestVerify.gotoAppointments';
        this.statesRequestVerifyDataModel.textRefI18n = 'shared.statesRequestVerify.scheduledAppointment';
        break;
      case OnlineAppointmentStatus.VENCIDA:
          this.statesRequestVerifyDataModel.buttonActionFn = this.gotoSignup.bind(this);
          this.statesRequestVerifyDataModel.buttonTextRefI18n = 'shared.statesRequestVerify.gotoSignup';
          this.statesRequestVerifyDataModel.textRefI18n = 'shared.statesRequestVerify.expiredAppointment';
          this.statesRequestVerifyDataModel.textDescriptionRefI18n = 'shared.statesRequestVerify.expiredAppointmentDescription';
          break;
    }
  }

  private userConfirmation():Observable<boolean>{
    return this.userService.postConfirmation(this.data.idVerificacion)
    .pipe(
      map((response)=>{
        return response.success;
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }

  continueWithAppointment(){
    this.showState = false;

    this.startWorkflow();
  }

  gotoAppointments(){
    this.router.navigate(['mis-citas']);
  }

  gotoSignup(){
    this.router.navigate(['signup']);
  }


  startWorkflow(){
    this.workflowService.setWorkflowTypeActive(STORAGE.ONLINE_APPOINTMENT_CLINIWEB_STATE);

    this.getState().subscribe((state:OnlineAppointmentCliniwebState)=>{

      if(!state){
        //TODO analizar agregar una vista que informe al usuario que no hay datos de la cita, con el link al home
        this.onlineAppointmentStatus = OnlineAppointmentStatus.VENCIDA;

        this.configStatusView();
        this.showState = true;
      }

      this.state = state;
      this.workflowService.setWorkflowDataState(this.state);

      this.init();
    });

    this.progressBarModel.closeFn = this.closeWorkflow.bind(this);
  }

  getState():Observable<OnlineAppointmentCliniwebState>{
    
    if(this.data){
      return this.getStateByAppointmentCheckout(this.data);      
    }
    else {
      let bootstrapState = this.appStorageService.getItemSession(STORAGE.ONLINE_APPOINTMENT_CLINIWEB_BOOTSTRAP_STATE) as AppointmentCheckout;

      if(bootstrapState){
        this.data = bootstrapState;

        this.appStorageService.removeSessionItem(STORAGE.ONLINE_APPOINTMENT_CLINIWEB_BOOTSTRAP_STATE);
  
        return this.getStateByAppointmentCheckout(this.data);
      }
      else {
        return of(this.workflowService.getWorkflowDataState() as OnlineAppointmentCliniwebState);
      }
    }    
  }

  getStateByAppointmentCheckout(appointmentCheckout: AppointmentCheckout):Observable<OnlineAppointmentCliniwebState>{
    let _state:OnlineAppointmentCliniwebState = new OnlineAppointmentCliniwebState();

    // If we do not get the state from localstorage first we will loose the request data from the state
    _state = this.workflowService.getWorkflowDataState(STORAGE.ONLINE_APPOINTMENT_CLINIWEB_STATE);

    if (!_state)
      _state = new OnlineAppointmentCliniwebState();    

    if(appointmentCheckout.idVerificacion){
      return this.appointmentService.getOnlineAppointmentRequest(appointmentCheckout.idVerificacion)
      .pipe(
        map((request:OnlineAppointmentRequest)=>{          
          _state.onlineAppointmentRequest = request;
          _state.setPublicProfile(request.profile);
          
          if (_state.request && _state.request.etapasSolicitud && _state.request.etapasSolicitud.length > 0) {
            _state.request.etapasSolicitud[0].idEstado = _state.onlineAppointmentRequest.idEstado;
            _state.request.etapasSolicitud[0].nombreEstado = _state.onlineAppointmentRequest.nombreEstado;
          }

          _state.idVerificacion = appointmentCheckout.idVerificacion;

          return _state;
        }),
        catchError((err) => {
          this.baseService.handleServiceError(err, "Error getting online appointment request");

          this.router.navigate(['/not-found']);

          return of(_state);
        })
      );
    }
    else{
      let _onlineAppointmentRequest = new OnlineAppointmentRequest();

      _onlineAppointmentRequest.fechaInicio = appointmentCheckout.fechaInicio;
      _onlineAppointmentRequest.fechaFin = appointmentCheckout.fechaFin;
      _onlineAppointmentRequest.idResponsableServicioDestino = appointmentCheckout.idResponsableServicio;
      _onlineAppointmentRequest.nombreResponsableServicioDestino = appointmentCheckout.nombreResponsableServicio;
      _onlineAppointmentRequest.urlAvatarResponsableServicioDestino = appointmentCheckout.urlAvatarResponsableServicio;
      _onlineAppointmentRequest.nicknameResponsableServicioDestino = appointmentCheckout.nickname;
      _onlineAppointmentRequest.especialidadResponsableServicioDestino = appointmentCheckout.especialidadResponsableServicio;

      _onlineAppointmentRequest.perfilPublico = appointmentCheckout.perfilPublico;     

      _onlineAppointmentRequest.idLocalidad = appointmentCheckout.idLocalidad;
      _onlineAppointmentRequest.nombreLocalidad = appointmentCheckout.nombreLocalidad;
      _onlineAppointmentRequest.direccionLocalidad = appointmentCheckout.direccionLocalidad;
      _onlineAppointmentRequest.coordenadasLocalidad = appointmentCheckout.coordenadasLocalidad;
      _onlineAppointmentRequest.telefonoLocalidad = appointmentCheckout.telefonoLocalidad;
      _onlineAppointmentRequest.teleconsulta = appointmentCheckout.teleconsulta;
      _onlineAppointmentRequest.idEmpresaDestino = appointmentCheckout.idEmpresa;
      _onlineAppointmentRequest.checkoutUrl = this.document.location.origin + '/citas/checkout/';      
      _onlineAppointmentRequest.localizacion = this.locale.language;

      return this.baseService.getCompanyByLicense()
      .pipe(switchMap((company:CompanyModel)=>{
        _onlineAppointmentRequest.idEmpresaOrigen = company.id;
        _onlineAppointmentRequest.nombreEmpresaOrigen = company.name;

        _state.onlineAppointmentRequest = _onlineAppointmentRequest;
        _state.idVerificacion = '';

        return of(_state);
      }),      
      catchError((err) => {
        this.baseService.handleServiceError(err, "Error getting online appointment request");
        return of(_state);
      }));
    }
  }
  
  closeWorkflow(){

    if(this.state && this.state.idVerificacion){
      let dialogData = new DialogData();
      dialogData.title = this.translation.translate("shared.appointmentCancelDialog.title");
      dialogData.message = this.translation.translate("shared.appointmentCancelDialog.message");
      dialogData.confirmText = this.translation.translate("shared.appointmentCancelDialog.confirmText");   
      dialogData.cancelText = this.translation.translate("shared.appointmentCancelDialog.cancelText");
      dialogData.showHeaderIcon = false;
      dialogData.showCancelButton = true;
      dialogData.loadLicense = true;
  
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: dialogData,
        panelClass: 'cancel-appointment-dialog'
      });
  
      dialogRef.afterClosed().subscribe(accepted => {
  
        if(accepted){
          this.onCloseWorkflow();
        }
      });
    }
    else{
      this.onCloseWorkflow();
    }
  }

  onCloseWorkflow(){
    this.messageBusService.showHeader();

    this.workflowService.removeWorkflowData(STORAGE.ONLINE_APPOINTMENT_CLINIWEB);
    this.appStorageService.removeSessionItem(STORAGE.ONLINE_APPOINTMENT_CLINIWEB_BOOTSTRAP_STATE);

    if(this.routeDialogService.isOpen(RouteDialogType.APPOINTMENT)){
      this.routeDialogService.closeDialog(RouteDialogType.APPOINTMENT);
    }
    else{
      const url = this.getPublicProfileUrl(this.state.onlineAppointmentRequest);
  
      this.router.navigate([url]);
    }
  }

  updateWorkflowStateData():Observable<void>{    
    if(this.state.idVerificacion){
      return this.appointmentService.updateOnlineAppointmentRequest(
        this.state.idVerificacion,
        this.state.onlineAppointmentRequest)
        .pipe(map((id:number)=>{
          this.state.onlineAppointmentRequest.id = id;
          this.workflowService.setWorkflowDataState(this.state);
        }),
        catchError(err=>{
          this.baseService.handleServiceError(err, "Error updating online appointment request");
          return of<void>();
        }));
    }
    else{
      return this.appointmentService.createOnlineAppointmentRequest(this.state.onlineAppointmentRequest)
        .pipe(map((idVerificacion:string)=>{
          this.state.idVerificacion = idVerificacion;
          this.workflowService.setWorkflowDataState(this.state);

          //this.location.go(`${this.baseService.getLanguage() + this.router.url}/${idVerificacion}`);
          this.router.navigate([`${this.baseService.getLanguage() + this.router.url}/${idVerificacion}`]);
        }),
        catchError(err=>{
          this.baseService.handleServiceError(err, "Error creating online appointment request");
          return of<void>();
        }));
    }
  }

  completeWorkflowStateData():Observable<void>{
    return this.appointmentService.confirmOnlineAppointmentRequest(
      this.state.idVerificacion,
      this.state.onlineAppointmentRequest)
      .pipe(map((request:any)=>{
        this.state.request = request;
        this.workflowService.setWorkflowDataState(this.state);
        
        this.userService.getUserPersonLoggedInFromService().subscribe(
          usr => {},
          error => {
            this.baseService.handleServiceError(error, "Error getting user person logged in");
          });
      }));
  }

  toCompleteStatus(){
    return this.onlineAppointmentStatus == OnlineAppointmentStatus.POR_COMPLETAR;
  }

  completedStatus(){
    return this.onlineAppointmentStatus == OnlineAppointmentStatus.COMPLETADA;
  }

  expiredStatus(){
    return this.onlineAppointmentStatus == OnlineAppointmentStatus.VENCIDA;
  }
}