import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { AppointmentDoctor } from '../../models/people/appointment-doctor';
import { AppointmentService } from 'src/app/core/services/appointment.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SearchProvidersService } from 'src/app/core/services/search-providers.service';
import { PublicProfileAvatarComponentModel } from '../public-profile-avatar/public-profile-avatar.component';
import { PublicProfileType } from '../../models/people/profile-search-results.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-latest-doctors',
  templateUrl: './latest-doctors.component.html',
  styleUrls: ['./latest-doctors.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('LatestDoctorsComponent')
export class LatestDoctorsComponent implements OnInit, IControlComponent, OnDestroy {
  data: FormComponentData;
  qs: any = null;
  private _latestDoctors: AppointmentDoctor[];
  finishedLoading: boolean = false;
  private currentMaxDoctorsToShow = 3;
  private maxDoctorsToShow = 5;
  private ngUnsubscribe = new Subject();
  get latestDoctors(): AppointmentDoctor[] { return this._latestDoctors.filter((_, i) => i < this.currentMaxDoctorsToShow);}

  constructor(
    private router: Router,
    private route : ActivatedRoute,
    private baseService: BaseService,
    private publicProfileService: PublicProfileService,
    private appointmentService: AppointmentService, 
    private messageBusService: MessageBusService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private searchProvidersService: SearchProvidersService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    // Get QS parameters
    this.route.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(params => {
      this.qs = params;
    });

    this.getLatestDoctors();

    this.messageBusService.onAppointmentChangeReceived()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      appointments => {
        if (appointments.doctores && appointments.doctores.length > this.currentMaxDoctorsToShow) {
          this._latestDoctors = appointments.doctores.slice(0, this.currentMaxDoctorsToShow);
        } else {
          this._latestDoctors = appointments.doctores;
        }
      },
      error => { this.baseService.handleServiceError(error, "Error getting latest doctors") }      
    );
    this.onResize();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  
  getLatestDoctors() {
    this.appointmentService.getLatestDoctors(this.locale.language, this.maxDoctorsToShow)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      doctors => {
        this._latestDoctors = doctors;

        this._latestDoctors.forEach(d => {
          let avatarInitialsObject = this.baseService.getAvatarInitialObject(d.idResponsableServicio, d.nombreResponsableServicio);
          d.iniciales = avatarInitialsObject.initials;
        });
        this.finishedLoading = true;
      },
      error => { this.baseService.handleServiceError(error, "Error getting latest doctors"); }
    );   
  }

  getMissingDoctorCount(): number[] {
    return Array.from({length: this.currentMaxDoctorsToShow - this.latestDoctors.length}, (_, index) => index);
  }

  onDoctorClick(doctor : AppointmentDoctor) {        
    let url = this.publicProfileService.getPublicProfileUrl(doctor.identificadorPerfilPublico);
    this.publicProfileService.openPublicProfile(url);
  }

  onOtherClick() {    
    this.searchProvidersService.openSearch();
  }

  onNavigateToAppointmentClick(){
    //this.router.navigate(['/mis-citas']);
    this.searchProvidersService.openSearch();
  }

  @HostListener('window:resize', [])
  onResize(): void {
    if(isPlatformBrowser(this.platformId)){
      this.currentMaxDoctorsToShow = window.innerWidth < 800 ? 5 : 3;
    }
  }

  getAvatarModel(item:AppointmentDoctor) : PublicProfileAvatarComponentModel {
    var avatarModel = new PublicProfileAvatarComponentModel();

    avatarModel.personId = item.idResponsableServicio;
    avatarModel.avatarUrl = item.urlAvatarResponsableServicio;
    avatarModel.fullName = item.nombreResponsableServicio;
    avatarModel.sex = item.sexoResponsableServicio;
    avatarModel.profileType = PublicProfileType.Doctor;

    return avatarModel;
  }

}