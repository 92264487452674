import { Component, Inject, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageBusService, WorkflowDataMessage } from 'src/app/core/services/message-bus.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { IWorkflowComponent } from 'src/app/shared/interfaces/workflow-component';
import { IStepFlow } from 'src/app/shared/interfaces/step-flow';
import { Workflow, WorkflowItemData } from 'src/app/shared/models/systems/workflow.model';
import { WorkflowProgressBarComponentData } from '../workflow-progress-bar/workflow-progress-bar.component';
import { IWorkflowDataState } from 'src/app/shared/interfaces/workflow-data-state';
import { BaseService } from 'src/app/core/services/base.service';
import { WorkflowStepService } from 'src/app/core/services/workflow-step.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { BaseRequestModel } from 'src/app/shared/models/process/base-request.model';
import { DOCUMENT, Location } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-base-workflow',
  template: '',
  styleUrls: ['./base-workflow.component.css']
})
export class BaseWorkflowComponent implements IWorkflowComponent, OnDestroy {
  protected ngUnsubscribe = new Subject();

  model: Workflow;
  data: any;
  
  stepIndex: number = 0;
  progressBarModel: WorkflowProgressBarComponentData = new WorkflowProgressBarComponentData();

  workflowMessage: WorkflowDataMessage;

  currentStep: IStepFlow;
  steps: Array<IStepFlow> = [];
  state: IWorkflowDataState;
  backToFlowStep: boolean = false;
  validationStepCompletedDisabled: boolean = false;

  checkingStep:boolean;

  formConfigurationData: {[componentName: string] : any; };

  constructor(
    protected location: Location,
    protected route: ActivatedRoute,
    protected baseService: BaseService,
    protected messageBusService: MessageBusService,
    protected publicProfileService: PublicProfileService,
    protected workflowService: WorkflowService,
    protected workflowStepService: WorkflowStepService,
    @Inject(DOCUMENT) protected document: Document
  ){

    this.messageBusService.onSendWorkflowStateUpdateMessage()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(message=>{
        this.state = message.state;

        this.workflowService.setWorkflowDataState(this.state);
    });

    this.messageBusService.onSendFlowStepCompleted()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(message=>{
        if(message.state){
          this.state = message.state;

          this.workflowService.setWorkflowDataState(this.state);
        }
          
        this.flowStepCompleted();
      });

    this.messageBusService.onCloseWorkFlowRequest()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(args => this.closeWorkflow(args));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  init(){
    this.steps = this.workflowStepService.createSteps(this.model.items, this.state);

    this.loadStep();

    this.route.queryParams
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(this.closeWorkflowIfQueryParamExists.bind(this));
  }

  private closeWorkflowIfQueryParamExists(args: Params): void {
    const cerrar = args['close'] === 'true';
    if (cerrar) {
      const currentUrl: string = this.location.path();
      const urlWithoutQueryParams = currentUrl.split('?')[0];

      this.location.replaceState(urlWithoutQueryParams);


      setTimeout(() => {
        this.closeWorkflow();  
      }, 100);
      
    }
  }

  protected getPublicProfileUrl(state: BaseRequestModel): string {
    return this.publicProfileService.getPublicProfileUrl(state.nicknameResponsableServicioDestino ?? '');
  }

  loadStep() {
    let currentStep = this.steps[this.stepIndex];
    currentStep.setState(this.state);

    // Once current step is set the form will be loaded, so before that we call step method to do any prior form setup
    currentStep.onBeforeFormLoad().subscribe(() => {
      this.formConfigurationData = currentStep.formConfigurationData;

      this._loadStep();
    });
  }

  private _loadStep() {
    this.currentStep = this.steps[this.stepIndex];
    this.currentStep.setState(this.state);

    if(this.backToFlowStep){
      if(this.currentStep.showStepInActionBack()){
        this.backToFlowStep = false;

        this.validationStepCompletedDisabled = true;

        this.buildProgressBarModel();
        this.configFooter();
      }
      else{
        this.onBackFlowStep();
      }
    }
    else if(this.validationStepCompletedDisabled){

      if(!this.currentStep.showStep()){
        this.doFlowStepCompleted();
      }

      this.buildProgressBarModel();
      this.configFooter();
    }
    else{
      this.checkingStep = true;
      this.currentStep.isCompleted()
      .subscribe((result:boolean)=>{  
        if(result){
          this.doFlowStepCompleted();
        }

        this.checkingStep = false;

        this.buildProgressBarModel();
        this.configFooter();
      });
    }
  }

  loadNextStep(){
    this.stepIndex++;

    if(this.model.items.length > this.stepIndex){
      this.loadStep();
    }      
  }

  flowStepCompleted(){
    this.currentStep.setState(this.state);    

    this.currentStep.isCompleted()
    .subscribe((result:boolean)=>{
      if(result){
        // When the user completes a step, always set this flag to false
        this.validationStepCompletedDisabled = false;
        this.doFlowStepCompleted(true);
      }
      else{
        console.log("flow step completed error")
        this.loadStep();
      }
    });
  }

  doFlowStepCompleted(updateState: boolean = false){
    this.workflowService.setWorkflowDataState(this.state);

    let stepItemData = new WorkflowItemData(JSON.parse(this.currentStep.item.valor));

    if(stepItemData && stepItemData.endWorkflow){
      this.completeWorkflowStateData()
      .subscribe(
        (a)=>{
          if(this.model.items.length > this.stepIndex)
            this.loadNextStep();
        },
        error => {
          this.baseService.handleServiceError(error, "Error completing flow");
        });
    }
    else if(updateState) {
      this.updateWorkflowStateData()
      .subscribe(
        (a)=>{
          this.loadNextStep();
        },
        error =>{
          this.baseService.handleServiceError(error, "Error completing flow step");
        });
    }
    else{
      this.loadNextStep();
    }
  }

  showWorkflowContent():boolean{
    return this.currentStep && !this.checkingStep;
  }

  getIdAreaSistema():number{
    return this.currentStep ? this.currentStep.item.idAreaSistema : -1;
  }

  buildProgressBarModel(){

    this.progressBarModel.show = this.currentStep.showHeader;
    this.progressBarModel.showButtonBack = this.currentStep.showButtonBack;
    this.progressBarModel.showButtonClose = this.currentStep.showButtonClose;
    this.progressBarModel.showProgressBar = this.currentStep.showProgressBar;

    // TODO replace with a defined value at each step
    this.progressBarModel.percentComplete = this.calculateProgressBarProgress(this.model.items.length, this.stepIndex);

    this.progressBarModel.backFn = this.onBackFlowStep.bind(this);
  }

  calculateProgressBarProgress(workflowStepsCount: number, currentStepIndex: number) {
    return (100 / workflowStepsCount) * (currentStepIndex + 1);
  }

  configFooter(){
    if(this.currentStep.withoutFooter){
      this.document.body.classList.add('step-without-footer');
    }
    else{
      this.document.body.classList.remove('step-without-footer');
    }
  }

  onBackFlowStep(){
    this.stepIndex--;
    this.backToFlowStep = true;
    this.loadStep();
  }

  setPercentComplete(percentage: number){
    this.progressBarModel.percentComplete = percentage;
  }

  completeWorkflowStateData():Observable<void>{
    // override in the particular workflow component
    return of();
  }

  updateWorkflowStateData():Observable<void>{
    // override in the particular workflow component
    return of();    
  }

  closeWorkflow(args?: any): void {
    // override in the particular workflow component
  }
}