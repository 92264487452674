import { AfterViewInit, Component, ElementRef, Inject, Input, ViewChild } from "@angular/core";
import { L10N_LOCALE, L10nLocale } from "angular-l10n";
import { ThemeColorService } from "src/app/core/services/theme-color.service";

@Component({
    selector: 'app-anonymous-language-menu-title',
    templateUrl: './anonymous-language-menu-title.component.html',
    styleUrls: ['./anonymous-language-menu-title.component.css']
})
export class AnonymousLanguageMenuTitle implements AfterViewInit {
    @Input() themeColor = false;
    @Input() color = '';
    @Input() icon = '';
    @Input() text = '';
    @ViewChild('titleItem') titleItem: ElementRef<HTMLSpanElement>;
    constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private themeColorService: ThemeColorService) {}
    ngAfterViewInit(): void {
        if (this.themeColor) {
            const color = this.themeColorService.theme?.properties['--primary'];
            this.titleItem.nativeElement.style.background = color || 'linear-gradient(90deg, #00816D 0%, #00816D 100%)';
        } else {
            this.titleItem.nativeElement.style.background = this.color;
        }
    }
}