import { Injectable } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { L10nResolver } from 'angular-l10n';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HomeComponent } from 'src/app/modules/home/pages/home/home.component';
import { FormComponent } from 'src/app/shared/components/form/form.component';
import { Link } from 'src/app/shared/models/people/link.model';
import { MenuGuard } from '../guards/menu.guard';
import { BaseService } from './base.service';
import { AuthPrivateRoutesGuard } from '../guards/auth-private-routes.guard';
import { environment } from 'src/environments/environment';
import { AppInitializationGuard } from '../guards/app-initialization.guard';

@Injectable({
  providedIn: 'root'
})
export class RouteLoaderService {

  constructor(private baseService: BaseService, public router: Router, public activedRoute: ActivatedRoute) { }

  public load(language:string, companyId: number):Observable<void>{
    return this.baseService.getMenuLinks(language, companyId).pipe(map((links: Link[])=> this.buildMenuRoutes(links)));
  }

  buildMenuRoutes(links: Link[]): void {
    let alreadyAdded = this.router.config.find(r=> r.path == links[0].identificadorUrl);

    if(alreadyAdded) return;

    links.forEach(link=>{
      if (link.identificadorUrl) {
        this.createRoute(link);
      }
      
      if (link.subMenu?.length) {
        link.subMenu.forEach(subLink => this.createRoute(subLink, true));
      }
    });
    
    this.router.resetConfig(this.router.config);
  }

  private createRoute(link: Link, isSublink = false) {
    let path = this.baseService.getBasePath(link.identificadorUrl);

    if(isSublink){
      path = this.baseService.getBasePath(link.identificadorUrl);
    }

    const existingRoute = this.router.config.find(r => r.path == path);

    if (existingRoute) {
      this.addChildrenRoute(existingRoute, link);
    } else {
      this.addNewRoute(link);
    }
  }

  private addNewRoute(link: Link) {
    const childrenRoutes = this.buildChildrenRoutebyLink(link);
    
    this.router.config.unshift(
        {
          path: this.baseService.getBasePath(link.identificadorUrl), 
          canActivate: [MenuGuard, AuthPrivateRoutesGuard, AppInitializationGuard],
          component: HomeComponent,
          children: childrenRoutes,
          resolve: { l10n: L10nResolver },
          data: {
              l10nProviders: [
                { name: 'home', asset: './assets/i18n/home', options: { version: environment.version } },
                { name: 'shared', asset: './assets/i18n/shared', options: { version: environment.version } }
              ],
              animation: link.nombreAnimacion ?? '',
              isRestrictedRoute: link.accesoPublico === 'N'
          }
        });
  }


  private addChildrenRoute (existingRoute: Route, link: Link) {
    let parentPath = existingRoute.path ?? '';
    parentPath += '/';

    const path = link.identificadorUrl.replace(parentPath, '');

    const childrenRoute = this.childrenRoute(link, path);

    if(existingRoute.children){
      existingRoute.children.unshift(childrenRoute);
    }    
  }

  private buildChildrenRoutebyLink(link:Link): Route[] {
    let childrenRouteArray: Route[] = [];
    const paramsArray = link.parametrosUrl?.split('/');
    
    if (!paramsArray?.length) {
      childrenRouteArray.push(this.childrenRoute(link));
    } else {
      childrenRouteArray = childrenRouteArray.concat(this.ifHaveParamsArray(paramsArray, link));
    }

    return childrenRouteArray;
  }

  private ifHaveParamsArray(paramsArray: string[], link: Link): Route[] {
    const routes = [];

    routes.push(this.childrenRoute(link, ''));

    const paramRoutes = paramsArray.map((_, i) => {
        const path = this.getPathByNumber(link.parametrosUrl, i + 1);
        return this.childrenRoute(link, path);
    });

    routes.push(...paramRoutes);

    return routes.reverse();
  }

  private childrenRoute(link: Link, path = ''): Route {
    return {
      path, component: FormComponent,
      data: {
        idAreaSistema: link.idAreaSistema,
        nombreComponenteAnonimo: link.nombreComponenteAnonimo,
        idsTipoContenidos: link.idsTipoContenidos?.split(',')
      }
    };
  }

  private getPathByNumber(originPath: string, index: number): string {
    return originPath.split("/").slice(0, index).join("/");
  }

}
