import { Component, ChangeDetectorRef, Inject, OnDestroy, OnInit, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { MenuMobileComponent } from '../../dialogs/menu-mobile/menu-mobile.component';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { MessageBusService, PublicProfileActionMessage } from 'src/app/core/services/message-bus.service';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ContainerType } from 'src/app/shared/enums/container-type.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/core/services/base.service';
import { HeaderComponentPublicProfileMenuModel } from '../header/header.component';

@Component({
  selector: 'app-public-profile-header',
  templateUrl: './public-profile-header.component.html',
  styleUrls: ['./public-profile-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('PublicProfilePageHeaderComponent')
export class PublicProfilePageHeaderComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;

  routeDialogOpened: boolean = false;
  isPublicProfilePage: boolean = false;
  isSearchProfilePage: boolean = false;
  loggedInUser: UserPerson = null as any;
  floatingComponents: FormComponentData[] = [];
  language: string;
  publicProfileMenuModel: HeaderComponentPublicProfileMenuModel;
  headerLogo: string = "";
  isLicensedCompany: boolean = true;
  loading: boolean = true;
  directoryUrl: string = environment.cliniwebUrl;

  private ngUnsubscribe = new Subject();

  constructor(
    private routeDialogService: RouteDialogService,
    private messageBusService: MessageBusService,
    private router: Router,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private baseService: BaseService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit(): void {

    this.setHeaderLogo();

    this.isPublicProfilePage = this.router.url.includes('perfil');
    this.isSearchProfilePage = this.router.url.includes('doctores');

    if (this.baseService.isCliniwebCompany()) {
      this.isLicensedCompany = true;
      this.loading = false;
    }
    else {
      this.baseService.isLicensedCompany().subscribe(hasLicenses => {
        this.isLicensedCompany = hasLicenses;
  
        this.loading = false;
      });

      /*let companyId = this.baseService.getCliniwebDirectoryCompanyId();
      this.baseService.getCliniwebDirectory(companyId).subscribe(dir => {
        this.directoryUrl = dir.url;
      });*/
    }    

    this.router.events
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(event=>{
      if(event instanceof NavigationEnd){
        if(this.routeDialogService.anyOpen())
          return;        

        this.isPublicProfilePage = this.router.url.includes('perfil');
        this.isSearchProfilePage = this.router.url.includes('doctores');


        this.language = this.locale.language;
      }
    });

    this.messageBusService.onSendFloatingComponents()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=>{

      if(this.routeDialogService.anyOpen())
        return;

      this.floatingComponents = message.componentsData.filter(c=>c.contenedor == ContainerType.HEADER);
    });

    this.messageBusService.onOpenRouteDialog()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=>{

      this.routeDialogOpened = true;
    });

    this.messageBusService.onCloseRouteDialog()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=>{
      this.routeDialogOpened = false;
    });

    this.messageBusService.onAllRouteDialogClosed()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=>{
      this.routeDialogOpened = false;
    });

    this.messageBusService.onPublicProfileHeaderVisibilityChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=> {
      if (message.fromRouteDialog)
        return;

      this.publicProfileMenuModel = new HeaderComponentPublicProfileMenuModel();
      this.publicProfileMenuModel.headerVisible = message.headerVisible;
      this.publicProfileMenuModel.profile = message.profile;
      this.publicProfileMenuModel.avatarModel = message.publicProfileAvatarComponentModel;
      this.publicProfileMenuModel.avatarModel.useAvatarPadding = message.headerVisible;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setHeaderLogo() {
    var directoryName = this.baseService.getCliniwebDirectoryCompanyName();
    var logo = "/assets/images/cliniweb/logo-cliniweb-phr.svg";

    switch(directoryName) {
      case "consultorios-towncenter":
        logo = "/assets/images/town-center/logo.svg";
      break;
      case "hospital-paitilla-directorio":
        logo = "/assets/images/hospital-paitilla/logo-HPaitilla.png";
      break;    
      case "pacificasalud": 
        logo = "/assets/images/pacifica-salud-phr/logo-pacifica-salud-phr-public-profile.svg";
      break;
      case "hsantafe": 
        logo = "/assets/images/hsantafe-phr/logo-hsantafe-phr-public-profile.svg";
      break;
      case "chpanamericano": 
        logo = "/assets/images/chpanamericano-phr/logo-chpanamericano-phr-public-profile.svg";
      break;
      case "chsf": 
        logo = "/assets/images/chsf-phr/logo-chsf-phr-public-profile.svg";
      break;
      case "thepanamaclinic": 
        logo = "/assets/images/thepanamaclinic-phr/logo-thepanamaclinic-phr-public-profile.svg";
      break;
      case "cmcaribe": 
        logo = "/assets/images/cmcaribe-phr/logo-cmcaribe-phr-public-profile.svg";
      break;
      case "hchiriqui": 
        logo = "/assets/images/hchiriqui-phr/logo-hchiriqui-phr-public-profile.png";
      break;
      case "cmpaitilla": 
        logo = "/assets/images/cmpaitilla-phr/logo-cmpaitilla-phr.svg";
      break;}

    this.headerLogo = logo;
  }

  openMenuMobile(){
    const dialogRef = this.dialog.open(MenuMobileComponent, {
      panelClass: "menu-mobile"
    });
  }

  onLogoClick() {
    let isCliniweb = this.baseService.isCliniwebCompany();

    if(isPlatformBrowser(this.platformId)){
      if (isCliniweb) {
        if (this.loggedInUser)
          this.router.navigate(['/']);
        else
          window.location.href = environment.cliniwebUrl;
      }
      else {
        var urlCompany = this.baseService.getUrlCompany();
        window.location.href = urlCompany;
      }
    }    
  }

  setLoggedInUser(arg: UserPerson | null): void {
    this.loggedInUser = arg as any;
    this.cd.detectChanges();
  }

  gotoPayment() {
    this.messageBusService.sendPublicProfileAction(new PublicProfileActionMessage(true, false));
  }

  onAppointmentBtnClick() {
    this.messageBusService.sendPublicProfileAction(new PublicProfileActionMessage(false, true));
  }
}

