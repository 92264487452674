import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10N_CONFIG, L10N_LOCALE, L10nConfig, L10nLocale, L10nSchema } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { CloseRouteDialogMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-location-language',
  templateUrl: './location-language.component.html',
  styleUrls: ['./location-language.component.css']
})
export class LocationLanguageComponent implements OnInit, OnDestroy {  
  
  private ngUnsubscribe = new Subject();
  schema: L10nSchema[];
  saving: boolean = false;
  selectionControl: FormControl;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    private baseService: BaseService,
    private messageBusService: MessageBusService
  ) {
    this.selectionControl = new FormControl('auto');
  }

  ngOnInit(): void {
    
    this.schema = this.l10nConfig.schema;
    this.selectionControl.setValue(this.locale.language);

    // On Header Back click
    this.messageBusService.onNavigateBack()
      .pipe(takeUntil(this.ngUnsubscribe))  
      .subscribe(m => {
        this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
      }
    );    
  }  

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSaveClick() {
    // Get locale
    let schema = this.schema.find(s => s.locale.language == this.selectionControl.value);

    if (!schema)
        return;

    this.saving = true;

    this.baseService.setLocale(schema.locale);

    this.saving = false;

    this.messageBusService.closeRouteDialog(new CloseRouteDialogMessage());
  }
}