import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from '../../interfaces/control-component';
import { FormComponentData } from '../../models/people/form-control.model';
import { SenderPatientRelationship } from '../../models/process/base-request.model';
import { PatientContactFormDataModel } from '../../models/workflow/models/patient-contact-form-data.model';
import { ComponentName } from '../../enums/component-name.enum';
import { UserService } from 'src/app/core/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentInfoDialogComponent } from '../dialogs/appointment-info-dialog/appointment-info-dialog.component';
import { CustomEmailValidator } from '../../validators/custom-email.validator';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { KeyboardService } from 'src/app/core/services/keyboard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-patient-contact-form-appointment-checkout',
  templateUrl: './patient-contact-form-appointment-checkout.component.html',
  styleUrls: ['./patient-contact-form-appointment-checkout.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('PatientContactFormAppointmentCheckoutComponent')
export class PatientContactFormAppointmentCheckoutComponent implements IControlComponent, OnInit, AfterViewInit, OnDestroy  {
  data: FormComponentData;
  model: PatientContactFormDataModel = new PatientContactFormDataModel();

  //subscribes
  private ngUnsubscribe = new Subject();

  language: string;
  patientContactForm: FormGroup;
  showAppointmentInfo: boolean;
  userPatientMode: boolean;
  formSubmitted: boolean = false;
  loading: boolean = false;

  @ViewChild('userPhone', { read: ElementRef }) userPhone: ElementRef;
  @ViewChild('patientPhone', { read: ElementRef }) patientPhone: ElementRef;

  isBrowser: boolean;
  
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private messageBusService: MessageBusService,
    private userService: UserService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(DOCUMENT) public document: Document,
    private renderer: Renderer2,
    private keyboardService: KeyboardService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.language = locale.dateLanguage ?? 'es-AR';
    this.isBrowser = isPlatformBrowser(this.platformId);
  }
  
  ngOnInit(): void {
    this.loadDataModel();
    
    this.loadForm();

    this.sendComponentFinishLoadingMessage();
    
    this.showAppointmentInfo = this.isBrowser ? window.innerWidth > 799 : true;

    this.userPatientMode = this.model.patientRelationship == SenderPatientRelationship.USUARIO;

    this.keyboardService.getKeyboardOpenObservable()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(isOpen=>{

      if(this.userPatientMode){
        this.onChangeHeightContainer();
      }      
    });
  }

  ngOnDestroy():void{
    if(this.isBrowser && window.innerWidth < 800){
      console.log('onChangeHeightContainer executed !');
      let container = this.document.getElementsByClassName('online-appointment-container');

      if(container.length > 0 && window.visualViewport){

        this.renderer.setStyle(container[0], 'height', `100dvh`);
      }
    }


    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(){
    // if(this.userPatientMode){
    //   const userTelInputElement: HTMLInputElement = this.userPhone.nativeElement;
    //   const userInputElement = userTelInputElement.getElementsByTagName('input')[0];
  
    //   userInputElement.addEventListener('focus', this.onChangeHeightContainer.bind(this));
    //   userInputElement.addEventListener('blur', this.onChangeHeightContainer.bind(this));
    // }
  }

  loadForm(){

    this.model.contactType = this.model.contactType == 0 ? 1 : this.model.contactType;

    this.patientContactForm = this.formBuilder.group(
      {
        contactRadio:[ this.model.contactType.toString() ],
        userPhone: [this.model.userPhone, [this.requiredIfValidator(()=> this.patientContactForm.get('contactRadio')?.value == '1')]],        
        patientPhone: [this.model.patientPhone, [this.requiredIfValidator(()=> this.patientContactForm.get('contactRadio')?.value == '2')]],
        patientEmail: [this.model.patientEmail, [
          this.requiredIfValidator(()=> this.patientContactForm.get('contactRadio')?.value == '2'),
          CustomEmailValidator
        ]],
      }
    );

    this.patientContactForm.get('contactRadio')?.valueChanges
    .subscribe(value => {
        this.patientContactForm.get('userPhone')?.updateValueAndValidity();
        this.patientContactForm.get('patientPhone')?.updateValueAndValidity();
        this.patientContactForm.get('patientEmail')?.updateValueAndValidity();
    });    
  }

  sendComponentFinishLoadingMessage(){
    let event = new ComponentFinishLoadingMessage();
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);   
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.showAppointmentInfo = event.target.innerWidth > 799;
  }
  
  onChangeHeightContainer(){
    setTimeout(() => {
      if(this.isBrowser && window.innerWidth < 800){
        console.log('onChangeHeightContainer executed !');
        let container = this.document.getElementsByClassName('online-appointment-container');

        if(container.length > 0 && window.visualViewport){

          this.renderer.setStyle(container[0], 'height', `${window.visualViewport.height}px`);
        }
      }
    }, 100);
  }

  onClickFinish(){
    // stop here if form is invalid
    if (this.patientContactForm.invalid) {
      return;
    }

    this.loading = true;

    this.updateModel();

    let message = new ComponentDataSetMessage();
    message.componentName = ComponentName.PATIENT_CONTACT_FORM;
    message.data = this.model;

    this.messageBusService.onComponentDataSetMessage(message); 
  }

  buttonDisabled():boolean{
    return this.patientContactForm.invalid;
  }

  getControl(controlName: string) {
    return this.patientContactForm.controls[controlName];
  }

  isControlInvalid(controlName: string) {
    let control = this.getControl(controlName);

    if(this.formSubmitted || control.touched)
      return control.invalid;

    return false;
  }

  controlHasError(controlName: string, errorName: string) {
    return this.getControl(controlName).hasError(errorName);
  }

  getControlValue(controlName: string) {
    return this.getControl(controlName).value;
  }

  updateModel(){    
    this.model.contactType = +(this.patientContactForm.controls.contactRadio.value);

    if(this.model.contactType == 1) {
      this.model.userPhone = this.patientContactForm.controls.userPhone.value;
    }
    else {
      this.model.patientPhone = this.patientContactForm.controls.patientPhone.value;
      this.model.patientEmail = this.patientContactForm.controls.patientEmail.value;
    }

    // refresh user in cache
    this.userService.getUserPersonLoggedInFromService();
  }

  getRelationship(): string {
    const condicion = this.model.patientRelationship === SenderPatientRelationship.USUARIO;
    const condicionSexoPaciente = this.model.appointmentInfo.patientGender == `${SenderPatientRelationship.DESCONOCIDO}`;
    const me = 'shared.selectPatientAppointmentCheckout.userRelationship';
    const relantionship = 'shared.appointmentInfoCheckout.relationship_';
    const patientGender = condicionSexoPaciente ? 'M' : this.model.appointmentInfo.patientGender;
    return condicion ? me : `${relantionship}${this.model.patientRelationship}_${patientGender}`;
  }

  openAppointmentInfoDialog(){
    this.dialog.open(AppointmentInfoDialogComponent, {          
      panelClass: ['appointment-info-dialog', 'appointment-info-animation-in'],
      data: this.model.appointmentInfo
    });
  }

  private requiredIfValidator(predicate: any) {
    return ((formControl:AbstractControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl); 
      }
      return null;
    })
  }

  private loadDataModel() {
    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as PatientContactFormDataModel;        
    }     
  }
}
