import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import { AuthService } from 'src/app/core/services/auth.service';
import { FloatingComponentsMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { Dependent } from 'src/app/shared/models/people/dependent.model';
import { takeUntil } from 'rxjs/operators';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { ChangePasswordRouteDialogModel, ConfigurationPageDialogModel } from '../../dialogs/route-dialog/route-dialog.component';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/shared/models/dialog-data.model';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { ContainerType } from 'src/app/shared/enums/container-type.enum';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.css']
})

export class ProfileMenuComponent implements OnDestroy, OnInit {

  loggedInUser: UserPerson;
  loggedInUserInitials: any;
  dependents: ProfileMenuDependentModel[]; 

  translations = {
    other: "",
    son: "",
    daughter: "",
    father: "",
    mother: "",
    language: "",    
  };
  private ngUnsubscribe = new Subject();
  @Input() dialog: MatDialogRef<any>;

  constructor(
    private appStorageService: AppStorageService,
    private userService: UserService,
    private authService: AuthService,
    private utilsService: UtilsService,    
    private routeDialogService: RouteDialogService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,   
    private translation: L10nTranslationService,
    private router: Router,
    private dialogRef: MatDialog,
    private messageBusService: MessageBusService
  ) {
    this.messageBusService.onRefreshProfileMenu().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.loadData.bind(this));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.translation.onChange().subscribe({
      next: () => {
          this.translations.other = this.translation.translate('shared.profileMenu.text8');
          this.translations.son = this.translation.translate('shared.profileMenu.text9');
          this.translations.daughter = this.translation.translate('shared.profileMenu.text10');
          this.translations.father = this.translation.translate('shared.profileMenu.text11');
          this.translations.mother = this.translation.translate('shared.profileMenu.text12');
          this.translations.language = this.translation.translate('language');
      }
    });

    this.loadData();
  }

  private loadData(): void {
    
    this.userService.getUserPersonLoggedIn().subscribe(this.setLoggedInUser.bind(this));

    this.userService.getUserDependents().subscribe(d => {
      this.dependents = d as ProfileMenuDependentModel[];

      // Order by age desc
      this.dependents = this.dependents.sort((d1: ProfileMenuDependentModel, d2: ProfileMenuDependentModel) => {
        let getTime = (date: Date) => {return date != null ? new Date(date).getTime() : 0;};
        
        return getTime(d1.fechaNacimiento) - getTime(d2.fechaNacimiento);
      });

      this.setUpDependentExtraData();
    });
  }

  private setLoggedInUser(p: UserPerson | null): void {
    if (p) {
      const token = this.appStorageService.getToken();

      this.loggedInUser = p;

      let fullName = this.loggedInUser.nombre + " " + (this.loggedInUser.apellido?? "");
      this.loggedInUserInitials = this.utilsService.getPersonInitials(this.loggedInUser.id, fullName);
      let birthDate = this.loggedInUser.fechaNacimiento? new Date(this.loggedInUser.fechaNacimiento) : null;

      this.loggedInUser.username = !!token ? token.username : '';

      if (!this.loggedInUser.ubicacionLogo)
        this.loggedInUser.ubicacionLogo = this.utilsService.getPersonDefaultAvatarUrl(birthDate, this.loggedInUser.sexo);        
    }
  }

  setUpDependentExtraData() {
    this.dependents.forEach(dependent => {
      dependent.fullName = dependent.nombre + " " + dependent.apellido;      
      dependent.relationshipName = this.userService.getDependentRelationshipName(dependent);

      let birthDate = dependent.fechaNacimiento? new Date(dependent.fechaNacimiento) : null;

      if (!dependent.ubicacionLogo)
        dependent.ubicacionLogo = this.utilsService.getPersonDefaultAvatarUrl(birthDate, dependent.sexo);
    });
  }  

  onEditUserClick() {
    let routeDialogModel = new ConfigurationPageDialogModel();
    routeDialogModel.route = "configuracion/usuario";
    routeDialogModel.state = null;
    
    this.routeDialogService.openDialog(routeDialogModel);
  }

  onEditDependentClick(dependent: ProfileMenuDependentModel) {
    let routeDialogModel = new ConfigurationPageDialogModel();
    routeDialogModel.route = "configuracion/dependiente/" + dependent.id;
    routeDialogModel.state = null;

    this.routeDialogService.openDialog(routeDialogModel);
  }

  onAddDependentClick() {
    let routeDialogModel = new ConfigurationPageDialogModel();
    routeDialogModel.route = "configuracion/dependiente/edicion/0";
    routeDialogModel.state = null;

    this.routeDialogService.openDialog(routeDialogModel);
  }

  onPaymentsClick() {
    let routeDialogModel = new ConfigurationPageDialogModel();
    routeDialogModel.route = "configuracion/pagos";
    routeDialogModel.state = null;

    this.routeDialogService.openDialog(routeDialogModel);
  }

  onLocaleEditClick() {
    let routeDialogModel = new ConfigurationPageDialogModel();
    routeDialogModel.route = "configuracion/locale";
    routeDialogModel.state = null;

    this.routeDialogService.openDialog(routeDialogModel);
  }
  
  onChangePasswordClick() {
    let routeDialogModel = new ChangePasswordRouteDialogModel();   
    routeDialogModel.route = "login/restablecer"; 
    routeDialogModel.state = null;
    
    this.routeDialogService.openDialog(routeDialogModel);
  }

  closeSession() {
    let dialogData = new DialogData();
    
    dialogData.title = this.translation.translate("shared.profileMenu.text13");    
    dialogData.confirmText = this.translation.translate("shared.profileMenu.text14");   
    dialogData.showHeaderIcon = false;
    //dialogData.headerIconClass = "phr-iconset-info";   
    dialogData.showCancelButton = true;
    dialogData.cancelText = this.translation.translate("shared.profileMenu.text15");

    const dialogRef = this.dialogRef.open(ConfirmDialogComponent, {
        data: dialogData,
        panelClass: ['cliniweb-theme-dialog', 'confirm-logout-dialog', 'confirm-logout-animation-in']
    });

    dialogRef.afterClosed().subscribe(accepted => {    
        if (accepted) {
          this.appStorageService.removeSessionItem(STORAGE.SIGNUP_WORKFLOW_VERIFICATION_TYPE);
          this.removeFloatingComponentToHeader();
          this.authService.logout();
          
          if (this.dialog)
            this.dialog.close();
          
          this.router.navigate(['/welcome/login'], {queryParams: { homeUrl: '/'}});
        }
    });
  }

  removeFloatingComponentToHeader(){
    let message = new FloatingComponentsMessage();
    let dummyComponentData = new FormComponentData();
    dummyComponentData.contenedor = ContainerType.HEADER;

    message.componentsData = [dummyComponentData];

    this.messageBusService.sendFloatingComponents(message);
  }
  
}

export class ProfileMenuDependentModel extends Dependent {  
  relationshipName: string;
  fullName: string;  
}