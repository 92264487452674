import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Theme, THEMES } from "src/themes/theme";

@Injectable({
    providedIn: 'root'
})
export class ThemeColorService {
    private _directoryClass= 'cliniweb';
    private renderer: Renderer2;
    private _color = ''

    constructor(@Inject(THEMES) private themes: Theme[], private rendererFactory: RendererFactory2) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }

    get theme() { return this.themes.find(t => t.name == this._directoryClass); }
    set directoryClass(args: string) { this._directoryClass = args; }

    setThemeColor(args: string = '', save = false): void {
        const metaThemeColor = document.querySelector(`meta[name="theme-color"]`);
        const color = this._color || args || this.theme?.properties['--primary'];
        if (metaThemeColor) {
            this.renderer.setAttribute(metaThemeColor, 'content', color);
        }
        if (save) {
            this._color = color;
        }
    }
}