<div class="layout-content" appTouchMoveListener [ngClass]="{'is-ios': isIOS, 'is-desktop-mac': isDesktopMac}" *ngIf="isBrowser">
  
  <!-- Header -->
  <app-dynamic [style.display]="!showHeader? 'none' : 'initial'" [componentName]="headerName" class="header-dynamic-container"></app-dynamic>
  
  <!-- Body -->
  <div class="body-container" cdkScrollable appTouchMoveListener
    (scroll)="onScroll($event)" 
    [style.z-index]="iframeZIndex" 
    [ngClass]="{'iframe-modal-open': isIframeModalOpen, 'hidden-header': !showHeader }"
    [@routeAnimations]="getRouteAnimationData()">
    <router-outlet></router-outlet> 
  </div>  
  
  <!-- Chat -->
  <app-chat></app-chat>

  <div *ngIf="floatingComponents.length > 0">
    <ng-container *ngFor="let floatingComponent of floatingComponents">
      <app-dynamic *ngIf="floatingComponent" [componentName]="floatingComponent.nombreComponente" [componentData]="floatingComponent"></app-dynamic>
    </ng-container>
  </div>
</div>