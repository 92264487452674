import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { DropdownPanel } from '../dropdown/dropdown.component';
import { OverlayRef } from '@angular/cdk/overlay';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { DirectoryFiltersGroupModel, DirectoryFiltersViewConfig } from '../search-results/provider-filters/provider-filters-content/provider-filters-content.component';
import { ProfileFilterSearchResultConcept, ProfileFilterSearchResultGroup } from '../../models/people/profile-filter-search-result.model';
import { TextLanguage } from '../../models/people/text-language.model';
import { LayoutService } from 'src/app/core/services/layout.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';

@Component({
  selector: 'app-check-autocomplete',
  templateUrl: './check-autocomplete.component.html',
  styleUrls: ['./check-autocomplete.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CheckAutocompleteComponent implements OnInit, OnDestroy {
  checkAutocompleteForm: FormGroup;
  control: FormControl;
  options: any[];
  
  filteredGroups: any[] = [];
  filteredOptions: any[] = [];

  visible:boolean = false;
  clasnameComponent:string;

  isMobile: boolean = false;

  overlayRef: OverlayRef;

  @Input() componentId: number;
  @Input() selectedOptions: any[];
  @Input() idAttributeName: string;
  @Input() placeholder: string;
  @Input() iconClassName: string;
  @Input() cssClass: string;
  @Input() loading: boolean;
  @Input() showInputSearch: boolean;
  @Input() singleSelection: boolean;
  @Input() groups: DirectoryFiltersGroupModel[];
  @Input() viewConfig: DirectoryFiltersViewConfig;
  @Input() optionsObs: Observable<any[]>;
  @Input() optionFilterFtn: (searchText: string) => any[];  
  @Input() optionDisplayNameFtn: (option: any) => string;
  @Input() optionDisplayTitleNameFtn: (option: any) => string;
  @Input() selectedOptionDisplayNameFtn: (option: any) => string;
  
  @Output() onAutocompleteClick = new EventEmitter();
  @Output() onAutocompleteOptionsChanged = new EventEmitter<any | null>();

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('button') button: ElementRef;
  @ViewChild('dropdown') dropdown: DropdownPanel;

  private ngUnsubscribe = new Subject();

  isBrowser: boolean;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private messageBusService: MessageBusService,
    @Inject(DOCUMENT) private document: Document,
    private layoutService: LayoutService,
    private publicProfileService: PublicProfileService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.isMobile = this.isBrowser ? window.innerWidth <= 800 : false;

    this.clasnameComponent = `check-autocomplete-${this.componentId}`;

    this.control = new FormControl('');

    this.checkAutocompleteForm = new FormGroup({
        control: this.control
    });

    this.optionsObs.subscribe(ops => {
      this.searchInput.nativeElement.value = '';
      this.options = ops;
      this.filteredOptions = ops;
      this.filteredGroups = this.groupConcepts(ops);
    });

    // Auto complete search function
    this.control.valueChanges.subscribe(v => {
      let filtered = this.filterItems(v);

      this.filteredGroups = this.groupConcepts(filtered);
    });

    this.messageBusService.onCloseCheckAutocomplete()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result=>{

        if(result != this.componentId)
          this.visible = false;
      });
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  toggleAutocomplete(){

    event?.stopPropagation();

    this.visible = !this.visible;

    if(this.visible && !this.isMobile){
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 100);      
    }
  }

  getClassNameButton(){
    return {
      'open':  this.visible,
      'selected': this.selectedOptions.length > 0
    }
  }

  getButtonText(){
    return this.selectedOptions.length > 0
      ? this.selectedOptionDisplayNameFtn(this.selectedOptions[0])
      : this.placeholder;
  }

  onClickAutocomplete() {
    this.toggleAutocomplete();
    this.onAutocompleteClick.emit();
  }

  optionClicked(event: Event, option: any) {
    event.stopPropagation();
    this.toggleSelection(option);
  }

  toggleSelection(option: any){
    let optionDeleted:any = null;

    if (this.singleSelection) {
      if (this.selectedOptions.length > 0) {
        optionDeleted = this.selectedOptions.splice(0, 1)[0];        
      }

      // If option was selected, then now was removed
      if (!this.isOptionSelected(option)) {
        this.selectedOptions.push(option);
      }

      this.onAutocompleteOptionsChanged.emit(optionDeleted ? [optionDeleted] : null);
    }
    else {
      if(this.isOptionSelected(option)){
        const i = this.selectedOptions.findIndex(value => value[this.idAttributeName] === option[this.idAttributeName]);
        this.selectedOptions.splice(i, 1);
  
        optionDeleted = option;
      }
      else{
        this.selectedOptions.push(option);
      }
  
      this.onAutocompleteOptionsChanged.emit(optionDeleted ? [optionDeleted] : null);
    }    
  }

  onClearFilter() {

    event?.stopPropagation();

    this.onAutocompleteOptionsChanged.emit(this.selectedOptions);

    this.selectedOptions = [];
    this.control.setValue("");
    this.visible = false;
  }

  isOptionSelected(option:any):boolean{
    return this.selectedOptions.length > 0 
      && this.selectedOptions.filter(s=> s[this.idAttributeName] === option[this.idAttributeName]).length > 0;
  }

  closeDropdown(){
    this.dropdown.closed.emit();
  }

  onFocusInputSearchDropdown(){
    if(!this.isMobile)
      return;

    event?.preventDefault()

    this.overlayRef.addPanelClass('fullscreen');
  }

  setOverlayRef(overlayRef: OverlayRef){
    this.overlayRef = overlayRef;
  }

  @HostListener('document:click', ['$event'])
  clickout(event : any){
    let container = this.document.getElementsByClassName(this.clasnameComponent)[0];

    if(container && !container.contains(event.target)){
      this.visible = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = event.target.innerWidth <= 800;
  }

  private filterItems(value: string): any[] {
    try {
      // Filter concepts base on search results
      let filteredOptions = this.optionFilterFtn(value);

      this.filteredOptions = filteredOptions;

      return filteredOptions;
    } 
    catch (error) {}

    return [];
  }

  private groupConcepts(filteredConcepts: ProfileFilterSearchResultConcept[]) : ProfileFilterSearchResultGroup[] {
    return this.publicProfileService.groupProfileFilters(filteredConcepts, this.groups, this.locale.language);
  }
}

export class CheckAutocompleteOption{
  object: any;
  selected: boolean;

  constructor(object: any){
    this.object = object;
  }
}
