import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProfileDetailModel, PublicProfileDetailType } from 'src/app/shared/models/people/profile-detail.model';
import { ProfileFilterSearchResult, ProfileFilterSearchResultConcept, ProfileFilterSearchResultGroup } from 'src/app/shared/models/people/profile-filter-search-result.model';
import { ProfileSearchResultsModel, PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { PublicProfile } from 'src/app/shared/models/people/public-profile.model';
import { SystemDocument } from 'src/app/shared/models/process/document.model';
import { environment } from 'src/environments/environment';
import { HttpBaseService } from './http-base-service';
import { BaseService } from './base.service';
import { PublicProfileVersion } from 'src/app/shared/models/systems/portal-phr.model';
import { PublicProfileRouteDialogModel } from 'src/app/shared/components/dialogs/route-dialog/route-dialog.component';
import { RouteDialogService } from './route-dialog.service';
import { Router } from '@angular/router';
import { DirectoryFiltersGroupModel } from 'src/app/shared/components/search-results/provider-filters/provider-filters-content/provider-filters-content.component';
import { LayoutService } from './layout.service';

@Injectable({
  providedIn: 'root'
})
export class PublicProfileService {

  constructor(
    private http: HttpBaseService,
    private baseService: BaseService,
    private routeDialogService: RouteDialogService,
    private router: Router,
    private layoutService: LayoutService
  ) { }      

  searchProfileByText(
    seed: string, language: string, searchText: string, filters: string, 
    accountName: string, page: number, pageSize: number, 
    country: string, state: string, city: string, profileType: PublicProfileType[], 
    accountId: number, locationCompaniesIds: string) :Observable<ProfileSearchResultsModel> {      
      let types = profileType.join(",");  
      let useElasticsearchForProfiles = environment.useElasticsearchForProfiles;
      let url = useElasticsearchForProfiles
        ? `api/perfiles-publicos/esprofiles?seed=${seed}&lenguaje=${language}&texto=${encodeURIComponent(searchText)}&page=${page}&pageSize=${pageSize}&tipoPerfil=${types}` 
        : `api/perfiles-publicos?seed=${seed}&lenguaje=${language}&texto=${encodeURIComponent(searchText)}&page=${page}&pageSize=${pageSize}&tipoPerfil=${types}`;      

      if (filters)
        url += `&filters=${filters}`;
      if (accountName)
        url += `&nombreCuenta=${accountName}`;
      if (accountId)
        url += `&idCuenta=${accountId}`;
      if (locationCompaniesIds)
        url += `&idEmpresasLocalidades=${locationCompaniesIds}`;
      if (country)
        url += `&pais=${country}`;
      if (state)
        url += `&provincia=${state}`;
      if (city)
        url += `&ciudad=${city}`;

      return this.http.get<ProfileSearchResultsModel>(url, null, false);
  }  

  searchProfileByConcept(
    seed: string,
    language: string,
    conceptNav: string,
    filters: string, 
    accountName: string,
    page: number,
    pageSize: number,
    country: string,
    state: string,
    city: string,
    profileType: PublicProfileType[],
    accountId: number, locationCompaniesIds: string, conceptId: number, conceptClassId: number
    ) :Observable<ProfileSearchResultsModel> {
      let types = profileType.join(",");
      let useElasticsearchForProfiles = environment.useElasticsearchForProfiles;
      let url = useElasticsearchForProfiles
        ? `api/perfiles-publicos/esprofiles${conceptNav}?seed=${seed}&lenguaje=${language}&page=${page}&pageSize=${pageSize}&tipoPerfil=${types}`
        : `api/perfiles-publicos${conceptNav}?seed=${seed}&lenguaje=${language}&page=${page}&pageSize=${pageSize}&tipoPerfil=${types}`;

        if (filters)
          url += `&filters=${filters}`;
        if (accountName)
          url += `&nombreCuenta=${accountName}`;
        if (country)
          url += `&pais=${country}`;
        if (state)
          url += `&provincia=${state}`;
        if (city)
          url += `&ciudad=${city}`;
        if (accountId)
          url += `&idCuenta=${accountId}`;
        if (locationCompaniesIds)
          url += `&idEmpresasLocalidades=${locationCompaniesIds}`;
        if (conceptId)
          url += `&idConcepto=${conceptId}`;
        if (conceptClassId)
          url += `&idClaseConcepto=${conceptClassId}`;

        return this.http.get<ProfileSearchResultsModel>(url, null, false);
  }

  getPublicProfile(nickname:string, language: string, accountName: string): Observable<PublicProfile>{
    let url = `api/perfiles-publicos/${nickname}?lenguaje=${language}&nombreCuenta=${accountName}`;
    return this.http.get<PublicProfile>(url, null, false);
  }
  
  getProfileDetails(profileId: number, profileDetailType: PublicProfileDetailType) {
    let url = "api/perfiles-publicos/" + profileId + "/detalles?tipoDetalle=" + profileDetailType;

    return this.http.get<ProfileDetailModel[]>(url, null, false);
  }

  getPublicProfileFilters(language:string, 
    tipoConcepto:string, 
    searchText: string, 
    concepto: string, 
    filters: string, 
    accountName:string, 
    pais: string,
    provincia: string,
    ciudad:string, 
    idTipoConceptoClinico: number, 
    idTipoPerfilPublico: PublicProfileType[],
    accountId: number, locationCompaniesIds: string, conceptId: number, conceptClassId: number): Observable<ProfileFilterSearchResult[]> {      
      let type = idTipoPerfilPublico? idTipoPerfilPublico.join(",") : "";
      let useElasticsearchForProfiles = environment.useElasticsearchForProfiles;
      let url = useElasticsearchForProfiles 
        ? "api/perfiles-publicos/esprofiles/filtros?language=" + language
        : "api/perfiles-publicos/filtros?language=" + language;

      if (tipoConcepto)
        url += "&tipoConcepto=" + tipoConcepto;
      if (concepto)
        url += "&concepto=" + concepto;
      if (searchText)
        url += "&searchText=" + encodeURIComponent(searchText);
      if (filters)
        url += "&filters=" + filters;
      if (accountName)
        url += "&accountName=" + accountName;
      if (pais)
        url += "&pais=" + pais;
      if (provincia)
        url += "&provincia=" + provincia;
      if (ciudad)
        url += "&ciudad=" + ciudad;
      if (idTipoConceptoClinico)
        url += "&idTipoConceptoClinico=" + idTipoConceptoClinico;
      if (type)
        url += "&idTipoPerfilPublico=" + idTipoPerfilPublico;
      if (accountId)
        url += `&idCuenta=${accountId}`;
      if (locationCompaniesIds)
        url += `&idEmpresasLocalidades=${locationCompaniesIds}`;
      if (conceptId)
        url += `&idConcepto=${conceptId}`;
      if (conceptClassId)
        url += `&idClaseConcepto=${conceptClassId}`;
      if (idTipoConceptoClinico)
        url += "&idClaseConceptoFiltro=" + idTipoConceptoClinico;  

        return this.http.get<ProfileFilterSearchResult[]>(url, null, false);
  }

  getProfileDetailsDocuments(profileId: number, profileDetailType: PublicProfileDetailType) {
    let url = "api/perfiles-publicos/" + profileId + "/detalles/documentos?tipoDetalle=" + profileDetailType;

    return this.http.get<SystemDocument[]>(url, null, false);
  }

  getProfileDetailsDocument(profileId: number, profileDetailType: PublicProfileDetailType, documentId: number) {
    let url = "api/perfiles-publicos/" + profileId + "/detalles/documento/" + documentId + "?tipoDetalle=" + profileDetailType;

    return this.http.get<SystemDocument>(url, null, false);
  }

  getPublicProfileUrl(nickName: string) {
    let publicProfileVersion = this.baseService.getPublicProfileVersion();
    let url = publicProfileVersion != PublicProfileVersion.V1 ? "perfil" : "perfil-v1";

    if (nickName.indexOf("/") >= 0)
      url += nickName;
    else
      url += ("/" + nickName);

    return url;
  }
  
  openPublicProfile(url: string){
    let publicProfileVersion = this.baseService.getPublicProfileVersion();

    if(publicProfileVersion == PublicProfileVersion.V1){
      this.router.navigateByUrl(url);
    }
    else{
      let routeDialogModel = new PublicProfileRouteDialogModel();

      routeDialogModel.route = url;
  
      this.routeDialogService.openDialog(routeDialogModel);
    }
  }

  getPublicProfileById(personId: number, language: string, accountName: string) {
    let url = `api/perfiles-publicos?idPersona=${personId}&lenguaje=${language}&nombreCuenta=${accountName}`;

    return this.http.get<PublicProfile>(url, null, false);
  }

  getPublicProfileByIds(personIds: number[]) {
    const arrayIds = [... new Set(personIds)];
    const url = `api/perfiles-publicos/${arrayIds}`;

    return this.http.get<(PublicProfile | null)[]>(url, null, false);
  }

  groupProfileFilters(filteredConcepts: ProfileFilterSearchResultConcept[], groupsDef: DirectoryFiltersGroupModel[], language: string) : ProfileFilterSearchResultGroup[] {    
    let groups: ProfileFilterSearchResultGroup[] = [];

    if (groupsDef) {
      groups = groupsDef.map(g => {
        let group = new ProfileFilterSearchResultGroup();
        
        group.idsConceptosClinicos = g.idsConceptosClinicos;
        group.results = [];
  
        if (g.tituloGrupo)
          group.titulo = this.layoutService.getTextByLanguage(g.tituloGrupo, language);
        
        if (g.tooltip)
          group.tooltip = this.layoutService.getTextByLanguage(g.tooltip, language);
  
          return group;
      });
    }

    // If there is not group on the configuration, let just create the default one
    if (groups.length == 0) {
      let defaultGroup = new ProfileFilterSearchResultGroup();
      defaultGroup.idsConceptosClinicos = [-1];
      defaultGroup.results = filteredConcepts;

      groups.push(defaultGroup);
    }
    else {
      // Add the concept in the first matching group.
      // If no maching group is found add it to the default one
      filteredConcepts.forEach(concept => {
        for(let group of groups) {
          if (group.idsConceptosClinicos.indexOf(concept.cc) > -1) {
            group.results.push(concept);
            break;
          }          
          else if (group.idsConceptosClinicos.length == 1 && group.idsConceptosClinicos[0] == -1) {
            group.results.push(concept);
          }       
        }
      });
    }

    return groups;
  }
}

