import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AppInitializationService } from '../services/app-initializer.service';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppInitializationGuard implements CanActivate {

  constructor(
    private authService: AuthService, 
    private router: Router,
    private appInitializationService: AppInitializationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.appInitializationService.isInitialized().pipe(
      filter(initialized => initialized),
      take(1),
      map(() => true)
    );    
  }
}
