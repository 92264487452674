<div class="growth-charts-tab-container">
    <div class="growth-charts-tab-wrapper" [ngClass]="{'skeleton-item': loading}">
        <div class="measurement-options-container">
            <mat-form-field appearance="outline">
                <mat-select #rangeSelect [(value)]="selectedRange" (selectionChange)="updateTab()">
                    <mat-option *ngFor="let range of ranges" [value]="range.id">
                        {{ range.nombre | i18nText:locale.language }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <app-growth-chart-type-select (valueChanges)="onChartTypeChange($event)"></app-growth-chart-type-select>
        </div>
        <div [id]="'chart_' + type" class="chart-container" *ngIf="selectedView == 1"></div>
        <app-growth-charts-tab-table-type
            *ngIf="selectedView == 2"
            [loading]="loading"
            [measurements]="measurements.mediciones"
            [range]="getRanges()"
            [type]="type"
        ></app-growth-charts-tab-table-type>
        <app-growth-charts-tab-data-type *ngIf="!loading" [gender]="gender"></app-growth-charts-tab-data-type>
    </div>
</div>
