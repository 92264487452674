import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AppInitializationService } from '../services/app-initializer.service';
import { filter, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthPrivateRoutesGuard implements CanActivate {

  constructor(
    private authService: AuthService, 
    private router: Router,
    private appInitializationService: AppInitializationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    return this.appInitializationService.isInitialized().pipe(
      filter(initialized => initialized),
      take(1),
      switchMap(() => {
        return this.isRouteValid(route, state);
      })
    );    
  }

  private isRouteValid(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const isRestrictedRoute: boolean = route.data['isRestrictedRoute'];

    if (!isRestrictedRoute) {
      return of(true);
    }

    const transferToken = route.queryParams['token'];

    if(transferToken){
      return this.authService.loginByTransferToken(transferToken).pipe(switchMap((response) => {
        return of(true);
      }));
    }

    if (this.authService.getToken()) {
      return of(true);
    }
    else{
      const homeUrl: string = state.url;
      this.router.navigate(['welcome', 'login'], { queryParams: { homeUrl } });

      return of(false);
    }
  }
}
