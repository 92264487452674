import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { map, startWith, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  private visualViewportSizeChangedSubject = new Observable<boolean>();
  private keyboardOpenSubject = new Observable<boolean>();

  constructor(
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    if(isPlatformBrowser(this.platformId)){
      this.initKeyboardDetection();
    }
  }

  private initKeyboardDetection() {
    this.zone.runOutsideAngular(() => {

      this.visualViewportSizeChangedSubject = window.visualViewport 
        ? fromEvent(window.visualViewport, 'resize').pipe(
            map(() => this.isKeyboardOpen()),
            startWith(this.isKeyboardOpen()),
            distinctUntilChanged()
          )
        : of(false);
      
      const focus$ = fromEvent(window, 'focusin').pipe(map(this.isKeyboardOpen.bind(this)));
      const blur$ = fromEvent(window, 'focusout').pipe(
        map(() => this.isKeyboardClosed()),
        startWith(this.isKeyboardOpen()),
        distinctUntilChanged()
      );

      this.keyboardOpenSubject = merge(this.visualViewportSizeChangedSubject, focus$, blur$);
    });
  }

  private isKeyboardOpen(): boolean {
    const visualViewport = window.visualViewport;
    return visualViewport != null && visualViewport.height < window.innerHeight;
  }

  private isKeyboardClosed(): boolean {
    const visualViewport = window.visualViewport;
    return visualViewport != null && visualViewport.height === window.innerHeight;
  }

  getVisualViewportSizeChangedObservable(): Observable<boolean> {
    return this.visualViewportSizeChangedSubject;
  }

  getKeyboardOpenObservable(): Observable<boolean> {
    return this.keyboardOpenSubject;
  }
}