import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { BaseService } from 'src/app/core/services/base.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { UserService } from 'src/app/core/services/user.service';
import { Link } from '../../models/people/link.model';
import { UserPerson } from '../../models/people/user-person.model';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('MenuComponent')
export class MenuComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  urlBack:string;
  links: Link[];
  isMobile$: Observable<boolean>;
  user$: Observable<UserPerson | null>;  

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private mediaObserver: MediaObserver,
    private appStorageService: AppStorageService,
    private baseService: BaseService,
    private translation: L10nTranslationService,
    private layoutService: LayoutService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {

    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: (locale: L10nLocale) => {

        let menuLanguage = this.appStorageService.getItem(STORAGE.MENU_LANGUAGE);

        if(locale.language != menuLanguage)
          this.populateLinks(locale.language);
      }
    });

    this.translation.onError()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: (error: any) => {
          if (error) console.log(error);
      }
    });

    this.isMobile$ = this.mediaObserver
      .asObservable()
      .pipe(
        map((changes) =>
          changes.some(
            (change: MediaChange) => change.mqAlias === "lt-md"
          )
        )
    );
  }

  ngOnInit(): void {
    this.links = this.appStorageService.getItemSession(STORAGE.MENU, true);
    this.urlBack = this.baseService.getBackUrlCompany();

    this.user$ = this.userService.getUserPersonLoggedIn();    
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  hideUrlBack(){
    return !this.urlBack;
  }

  onBackClick() {
    // Get directory
    let directory = this.baseService.getDirectory();

    // Logout
    this.authService.logout();

    // Redirect to directory corporate url
    this.router.navigate([directory.urlCorporativo]);
  }

  populateLinks(language: string){
    this.baseService.getMenuLinks(language)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(data => {
      if (!data || data.length == 0)
        return;
      
      this.links = data;

      this.appStorageService.setItem(STORAGE.MENU_LANGUAGE, language);
    });
  }
}
