import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Observable, Subject, Subscription, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { MessageBusService, ProviderSearchMessage } from 'src/app/core/services/message-bus.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { ProfileResultModel, ProfileSearchResultsModel, PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { TextLanguage } from 'src/app/shared/models/people/text-language.model';
import { environment } from 'src/environments/environment';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { SearchQsParamMapModel, SearchQsParametersModel } from 'src/app/shared/models/search-results/search-parameters.model';
import { DirectoryService } from 'src/app/core/services/directory.service';
import { TerritorialDivision } from 'src/app/shared/models/systems/territorial-division.model';
import { TerritorialDivisionsService } from 'src/app/core/services/territorial-divisions.service';
import { SearchResultTitleComponent } from '../search-result-title/search-result-title.component';
import { UtilsService } from 'src/app/core/services/utils.service';
import { LayoutService } from 'src/app/core/services/layout.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('SearchResultsComponent')
export class SearchResultsComponent implements OnInit, OnDestroy, IControlComponent {
  @ViewChild(SearchResultTitleComponent) private searchResultTitleComponent: SearchResultTitleComponent;
  data: FormComponentData;
  anchoPerfilBusqueda = 0;
  language:string;
  subtitle: TextLanguage[];
  description: TextLanguage[];
  showFullDescription: boolean = false;
  fullDescription: TextLanguage[];
  profileTypes: string;
  profileTypesArray:PublicProfileType[] = [];

  providerSearchMessage:ProviderSearchMessage = new ProviderSearchMessage();

  accountName:string;
  accountId: number;
  locationCompanyIds: string;    
  pageSize:number = 0;
  pageNumber:number = 1;
  disableScroll:boolean = false;
  seed:string = "";
  loadingInitialResults = false;
  showDivider:boolean = false;
  loadingResults:boolean = false;

  providersResult: ProfileSearchResultsModel;

  componentData: SearchResultsComponentData;

  private paramMap: SearchQsParamMapModel;
  private qsParams: SearchQsParametersModel;

  //subscribes
  private ngUnsubscribe = new Subject();

  private doSearchProfilesSubscribe:Subscription;

  private searchCountry: TerritorialDivision | null;

  constructor(
    private baseService: BaseService,
    private publicProfileService: PublicProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private messageBusService: MessageBusService,
    private directoryService: DirectoryService,
    private territorialDivisionService: TerritorialDivisionsService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private utilsService: UtilsService,
    private layoutService: LayoutService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

      this.language = this.locale.language;
      
      this.messageBusService.onScrollTopBody()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((scrollTop: number)=>{
        this.showDivider = scrollTop > 110;
      });

      // Subscribe to new search event
      this.messageBusService.onProviderSearch()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(message => {

        this.resetSearch();

        this.providerSearchMessage = message;

        this.updateRoute();

        this.searchProviders();
      });

      this.messageBusService.onLocaleChange()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(message =>{
        this.language = message.language;

        this.resetSearch();

        this.searchProviders();
      });

  }

  ngOnInit(): void {
    this.layoutService.addClassToBody('search-results');

    this.accountName = this.baseService.getCliniwebDirectoryCompanyName();
    this.accountId = this.baseService.getCliniwebDirectoryCompanyId();
    this.locationCompanyIds = this.baseService.getCliniwebDirectoryLocationCompanyIds();
    this.seed = this.baseService.getSearchSeed();
    this.pageSize = environment.searchProfilesPageSize;

    this.setSearchCountryByCountryApp();

    this.parseControlData();

    this.initRouteConfig();

    this.messageBusService.onSendTerritorialDivisionCountry()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(msg=>{

        this.setSearchCountry(msg.country);
        this.searchProviders();
    });    

    let containerEl = this.document.getElementsByTagName("html")[0];    
    this.utilsService.scrollToWithAnimation(containerEl, 0);

    // There are some cases in which the user navigate back to this page from login and header is not visible
    if (!this.layoutService.isHeaderVisible()) {
      
      setTimeout(() => {
        this.messageBusService.showHeader();
        this.messageBusService.showAnonymousMenu();
      }, 500);
    }
  }

  ngOnDestroy():void{
    this.layoutService.removeClassToBody('search-results');

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initRouteConfig(){
    let _paramMap$ = this.route.paramMap;
    let _queryParams$ = this.route.queryParamMap;

    zip(_paramMap$, _queryParams$)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(results =>{

      this.paramMap = new SearchQsParamMapModel();

      this.paramMap.param1 = results[0].get('param1') || '';
      this.paramMap.param2 = results[0].get('param2') || '';
      this.paramMap.param3 = results[0].get('param3') || '';
      this.paramMap.param4 = results[0].get('param4') || '';
      this.paramMap.param5 = results[0].get('param5') || '';

      this.qsParams = new SearchQsParametersModel();
      
      this.qsParams.t = results[1].get('t') || '';
      this.qsParams.filtros = results[1].get('filtros') || '';
      this.qsParams.idC = results[1].get('idC') || "";
      this.qsParams.idTc = results[1].get('idTc') || '';

      this.fireSearchByParams();
    }, error=>{
      console.log('error');
    });
  }

  fireSearchByParams(){    
    if(this.paramMap.param1){
      // busqueda por texto
      if(this.paramMap.param1 == "texto" || this.paramMap.param1 == "todos"){

        if(this.paramMap.param1 == "texto")
          this.providerSearchMessage.searchText = this.qsParams.t;

        // la ruta contiene division territorial
        if(this.paramMap.param2){
          let _id = this.paramMap.param2;

          if(this.paramMap.param3)
            _id +=`/${this.paramMap.param3}`;

          if(this.paramMap.param4)
            _id +=`/${this.paramMap.param4}`;

          this.providerSearchMessage.territorialDivisionIdentification = _id;
        }
      }
      // busqueda por tipo_concepto/concepto
      else {        
        this.providerSearchMessage.conceptNav = `/${this.paramMap.param1}/${this.paramMap.param2}`;
        this.providerSearchMessage.conceptId = this.qsParams.idC? parseInt(this.qsParams.idC) : 0;
        this.providerSearchMessage.conceptClaseId = this.qsParams.idTc? parseInt(this.qsParams.idTc) : 0;        

        // la ruta contiene division territorial
        if(this.paramMap.param3){
          let _id = this.paramMap.param3;

          if(this.paramMap.param4)
            _id +=`/${this.paramMap.param4}`;

          if(this.paramMap.param5)
            _id +=`/${this.paramMap.param5}`;

          this.providerSearchMessage.territorialDivisionIdentification = _id;
        }     
      }
    }

    this.providerSearchMessage.filtros = this.qsParams.filtros;
    
    this.searchProviders();
  }

  updateRoute(){
    // clear
    this.paramMap.param1 = '';
    this.paramMap.param2 = '';
    this.paramMap.param3 = '';
    this.paramMap.param4 = '';
    this.paramMap.param5 = '';
    this.qsParams.t = '';
    this.qsParams.filtros = '';

    // busqueda por texto
    if(this.providerSearchMessage.searchText){
      this.paramMap.param1 = 'texto';

      this.qsParams.t = this.providerSearchMessage.searchText;
    }
    // busqueda por concepto
    else if(this.providerSearchMessage.conceptNav){

      let conceptNavArray = this.providerSearchMessage.conceptNav.split('/');

      this.paramMap.param1 = conceptNavArray[1];
      this.paramMap.param2 = conceptNavArray[2];
    }
    else {
      this.paramMap.param1 = 'todos';
    }

    if(this.providerSearchMessage.territorialDivisionIdentification){

      let tdArray = this.providerSearchMessage.territorialDivisionIdentification.split('/');

      if(this.paramMap.param2){
        this.paramMap.param3 = tdArray[0];
        this.paramMap.param4 = tdArray[1] ?? '';
        this.paramMap.param5 = tdArray[2] ?? '';
      }
      else{
        this.paramMap.param2 = tdArray[0];
        this.paramMap.param3 = tdArray[1] ?? '';
        this.paramMap.param4 = tdArray[2] ?? '';
      }
    }

    this.qsParams.filtros = this.providerSearchMessage.filtros;
    
    let url = `${this.baseService.getLanguage()}/${this.router.url.split('/')[1].split('?')[0]}/${this.paramMap.param1}`;  
    url += this.paramMap.param2 ? `/${this.paramMap.param2}` : '';
    url += this.paramMap.param3 ? `/${this.paramMap.param3}` : '';
    url += this.paramMap.param4 ? `/${this.paramMap.param4}` : '';
    url += this.paramMap.param5 ? `/${this.paramMap.param5}` : '';

    let qs = this.qsParams.t ? `t=${this.qsParams.t}` : '';
    qs += this.qsParams.filtros
      ? qs
        ? `&filtros=${this.qsParams.filtros}`
        : `filtros=${this.qsParams.filtros}`
      : '';
    
    if (this.providerSearchMessage.conceptId > 0) {
      this.qsParams.idC = this.providerSearchMessage.conceptId.toString();
      this.qsParams.idTc = this.providerSearchMessage.conceptClaseId.toString();

      if (qs)
        qs += "&";
      qs += ("idC=" + this.providerSearchMessage.conceptId);
      qs += ("&idTc=" + this.providerSearchMessage.conceptClaseId);
    }

    this.location.go(url, qs);    
  }

  searchProviders(){
    this.getTitleText();

    this.loadingInitialResults = true;

    if(this.doSearchProfilesSubscribe){
      this.doSearchProfilesSubscribe.unsubscribe();
    }
      
    this.doSearchProfilesSubscribe = this.doSearchProfiles()
    .subscribe(result => {
      this.providersResult = result;

      this.loadingInitialResults = false;
    },
    error => {
      this.baseService.handleServiceError(error, "Error getting profiles");      
    });
  }

  resetSearch() {        
    this.pageNumber = 1;
    this.disableScroll = false;
  }

  onScrollDown() {
    this.loadMoreProfiles();
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll(event: Event) {
    if(isPlatformBrowser(this.platformId)){

      const windowHeight = window.innerHeight;
      const documentHeight = this.document.documentElement.scrollHeight;
      const scrollPosition = window.scrollY 
        || window.pageYOffset 
        || this.document.body.scrollTop + (this.document.documentElement && this.document.documentElement.scrollTop || 0);

      // Verifica si el usuario ha llegado al final del scroll
      if (scrollPosition + windowHeight >= documentHeight && window.innerWidth <= 800) {
        // El usuario ha llegado al final del scroll, ejecuta tu método aquí
        this.loadMoreProfiles();
      }
    }
  }

  loadMoreProfiles() {
    // all profiles has been loaded
    if(this.providersResult.cantidadTotalResulados == this.providersResult.perfiles.length)
      return;

    // Increase page number
    this.pageNumber = this.pageNumber + 1;
    this.loadingResults = true;

    this.doSearchProfiles()
    .subscribe(result => {

      this.loadingResults = false;
      
      if (result && result.perfiles && result.perfiles.length > 0) {

        // Add all the new profiles
        result.perfiles.map(p => this.providersResult.perfiles.push(p));
        
        // Join all locations
        for(let i=0; i < result.localidades.length; i++) {
          let exists = this.providersResult.localidades.find(l => l.id == result.localidades[i].id);

          if (!exists)
            this.providersResult.localidades.push(result.localidades[i]);
        }

        if (result.perfiles.length < this.pageSize)
          this.disableScroll = true;
      } 
      else 
        this.disableScroll = true;
    },
    error => {
      this.baseService.handleServiceError(error, "Error getting profiles");      
    });
  }

  showResults(){
    return this.providersResult && this.providersResult.perfiles && this.providersResult.perfiles.length > 0;
  }

  showEmptyState(){
    return !this.loadingInitialResults 
      && this.providersResult 
      && this.providersResult.perfiles 
      && this.providersResult.perfiles.length == 0;
  }

  providerTrackBy(index : number, item: ProfileResultModel){
    return item.id; 
  }

  getProfileTypeIconClassName(){

    let profileType = this.profileTypesArray[0];
    let className: string = 'phr-iconset-';

    switch(profileType){
      case PublicProfileType.Doctor:
      default:
        className += 'estetoscopio';
        break;
      case PublicProfileType.Farmacia:
        className += 'farmacia';
        break;
      case PublicProfileType.Clinica:
        className += 'hospital';
        break;
      case PublicProfileType.Fisioterapia:
          className += 'fisioterapia';
          break;
      case PublicProfileType.Laboratorio:
        className += 'laboratorios';
        break;
      case PublicProfileType.CentroDeImagenes:
        className += 'imagenologia';
        break;  
      case PublicProfileType.ServiciosOdontologicos:
        className += 'odontologia';
        break;
      case PublicProfileType.Opticas:
        className += 'open-eye';
        break;
      case PublicProfileType.ClinicasDeAtencionPrimaria:
        className += 'clinic';
        break;
      case PublicProfileType.Todos:
        className += 'todos';
        break;
    }

    return className;
  }

  getLocationsByProvider(provider: ProfileResultModel){
    return this.providersResult.localidades.filter(l => l.idPersona == provider.idPersona);
  }

  onShowFullDescription(){
    this.showFullDescription = true;
  }

  getTitleText(){

    let territorialDivisionIdentification = this.providerSearchMessage.territorialDivisionIdentification
      ? this.providerSearchMessage.territorialDivisionIdentification
      : this.searchCountry
        ? this.searchCountry.identificador
        : '';

    this.directoryService.getSearchTitle(
      this.language,
      this.profileTypesArray[0],
      this.providerSearchMessage.searchText,
      this.providerSearchMessage.conceptClaseId,
      this.providerSearchMessage.conceptId,
      territorialDivisionIdentification,
      this.providerSearchMessage.filtros)
      .subscribe(x => {
        do {
          this.searchResultTitleComponent?.setTitle(x);
        } while(!this.searchResultTitleComponent);
      }
      , error => {
        this.baseService.handleServiceError(error, "Error getting profiles");      
      });
  }

  private getAllProfileTypes(){
    // se filtra el tipo de perfil sede sura porque si se deja no lista las clinicas
    return Object.values(PublicProfileType).filter((v) => !isNaN(Number(v))).map((p) => Number(p));
  };

  private parseControlData(){
    if(this.data && this.data.valor){
      try {
        this.componentData = this.baseService.parseObject<SearchResultsComponentData>(JSON.parse(this.data.valor));

        this.profileTypes = this.componentData.tipos ? this.componentData.tipos : "1";

        this.profileTypesArray = this.profileTypes.split(",").map(pt => +pt);

        if(this.profileTypesArray.length == 1 && this.profileTypesArray[0] == PublicProfileType.Todos){
          this.profileTypesArray = this.getAllProfileTypes();
          this.profileTypes = this.profileTypesArray.join(',');
        }

        // subtitulo de la pagina
        this.subtitle = this.componentData.subtitulo;

        // decripcion
        this.description = this.componentData.descripcion;

        // descripcion detallada
        this.fullDescription = this.componentData.descripcionDetallada;

      } catch (ex) {}   
    }    
  }  

  private getFiltersString(){

    let filters:number[] = [];

    if(this.providerSearchMessage.filtros){
      filters = filters.concat(this.providerSearchMessage.filtros.split(',').map(f => +f));
    }

    filters = filters.filter((item, index)=> { return (filters.indexOf(item) == index)});

    return filters.length > 0 ? filters.join(',') : '';
  }

  private doSearchProfiles(): Observable<ProfileSearchResultsModel>{
    let _results$:Observable<ProfileSearchResultsModel>;
    let filters = this.getFiltersString();
    let location = this.baseService.getLocationFromIdentifier(this.providerSearchMessage.territorialDivisionIdentification);

    if(!location.country && this.searchCountry){
      location.country = this.searchCountry.identificador;
    }

    // Search text
    if (!this.providerSearchMessage.conceptNav) {
      let text = this.providerSearchMessage.searchText;

      if (!text)
        text = "{-1}";

      _results$ = this.publicProfileService.searchProfileByText(
        this.seed, 
        this.language, 
        text, 
        filters, 
        this.accountName,
        this.pageNumber, 
        this.pageSize, 
        location.country, 
        location.state, 
        location.city, 
        this.profileTypesArray,
        this.accountId,
        this.locationCompanyIds);
    }
    // Concept search
    else {
      _results$ = this.publicProfileService.searchProfileByConcept(
        this.seed,
        this.language,
        this.providerSearchMessage.conceptNav, 
        filters,
        this.accountName,
        this.pageNumber,
        this.pageSize,
        location.country,
        location.state,
        location.city,
        this.profileTypesArray,
        this.accountId,
        this.locationCompanyIds,
        this.providerSearchMessage.conceptId,
        this.providerSearchMessage.conceptClaseId);
    }

    return _results$;
  }

  private setSearchCountry(country:TerritorialDivision){
    let availableCountries = this.territorialDivisionService.getAvailableCountries();

    if(country.idPais != 9 && country.idPais != 1 && availableCountries.find(c=> c.idPais == country?.idPais)){
      this.searchCountry = country;
      this.providerSearchMessage.countryIdentification = country.identificador;
    }
    else{
      this.searchCountry = null;
      this.providerSearchMessage.countryIdentification = '';
    }
  }

  private setSearchCountryByCountryApp(){
    this.territorialDivisionService.getCountryApp().subscribe(countryApp=>{
      if(countryApp && countryApp.territorialDivision){
        this.setSearchCountry(countryApp.territorialDivision)
      }
    });
  }
}

export class SearchResultsComponentData {
  tipos: string;
  subtitulo: TextLanguage[];
  descripcion: TextLanguage[];
  descripcionDetallada: TextLanguage[];
}