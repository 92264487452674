import { Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ComponentDataSetMessage, ComponentFinishLoadingMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { IControlComponent } from '../../interfaces/control-component';
import { FormComponentData } from '../../models/people/form-control.model';
import { PatientFormAppointmentDataModel } from '../../models/workflow/models/patient-form-appointment-data.model';
import { ComponentName } from '../../enums/component-name.enum';
import { ValidatePersonName } from '../../validators/person-name.validator';
import { StateType } from '../../enums/state-type.enum';
import CommonTypesHelper from '../../helpers/common-types-helper';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentInfoDialogComponent } from '../dialogs/appointment-info-dialog/appointment-info-dialog.component';
import { ValidateGender } from '../../validators/gender.validator';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-patient-form-appointment-checkout',
  templateUrl: './patient-form-appointment-checkout.component.html',
  styleUrls: ['./patient-form-appointment-checkout.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('PatientFormAppointmentCheckoutComponent')
export class PatientFormAppointmentCheckoutComponent implements IControlComponent, OnInit {
  data: FormComponentData;
  model: PatientFormAppointmentDataModel = new PatientFormAppointmentDataModel();

  language: string;
  patientForm: FormGroup;
  showAppointmentInfo: boolean;
  state: any;

  minDate: Date;
  maxDate: Date;

  formSubmitted: boolean = false;
  loading: boolean = false;

  isBrowser: boolean;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private messageBusService: MessageBusService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.language = locale.dateLanguage ?? 'es-AR';
    this.isBrowser = isPlatformBrowser(this.platformId);

    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 120, 0, 1);
    this.maxDate = new Date();

    this.patientForm = this.formBuilder.group(
      {
        firstname: [this.model.firstname, [Validators.required, ValidatePersonName(true)]],
        lastname: [this.model.lastname, [Validators.required, ValidatePersonName(true)]],
        birthdate: [this.model.birthdate, [Validators.required]],
        gender: [this.model.gender, [Validators.required, ValidateGender]]
      }
    );
  }
  
  ngOnInit(): void {
    this.loadDataModel();
    this.loadForm();
    this.sendComponentFinishLoadingMessage();

    this.showAppointmentInfo = this.isBrowser && window.innerWidth > 799;
  }

  loadForm(){
    if (this.model.identityPersonValidationStateId == StateType.SinValidar) {
      this.patientForm.get('firstname')?.setValue(undefined);
      this.patientForm.get('lastname')?.setValue(undefined);
      this.patientForm.get('birthdate')?.setValue(undefined);
      this.patientForm.get('gender')?.setValue(undefined);
    } else {
      this.patientForm.get('firstname')?.setValue(this.model.firstname);
      this.patientForm.get('lastname')?.setValue(this.model.lastname);
      this.patientForm.get('birthdate')?.setValue(this.model.birthdate);
      this.patientForm.get('gender')?.setValue(this.model.gender);
    }
  }

  sendComponentFinishLoadingMessage(){
    let event = new ComponentFinishLoadingMessage();
      event.idFormulario = this.data.idFormulario;
      event.idControl = this.data.idControlPadre;

    this.messageBusService.componentFinishLoading(event);
  }

  onClickContinue(){
    this.patientForm.markAllAsTouched();
    this.formSubmitted = true;

    // stop here if form is invalid
    if (this.patientForm.invalid) {
      return;
    }

    this.loading = true;

    this.updateModel();

    let message = new ComponentDataSetMessage();
    message.componentName = ComponentName.PATIENT_FORM_APPOINTMENT;
    message.data = this.model;

    this.messageBusService.onComponentDataSetMessage(message);  
  }

  updateModel(){
    this.model.firstname = this.patientForm.controls.firstname.value.trim();
    this.model.lastname = this.patientForm.controls.lastname.value.trim();
    this.model.birthdate = this.patientForm.controls.birthdate.value;
    this.model.gender = this.patientForm.controls.gender.value;

    if (this.model.firstname && this.model.lastname) {
      this.model.identityPersonValidationStateId = StateType.NombreYApellidoValidados;

      if (CommonTypesHelper.dateHasValue(this.model.birthdate) && CommonTypesHelper.stringHasValue(this.model.gender)) {
        this.model.identityPersonValidationStateId = StateType.NombreApellidoSexoFechaNacimientoValidados;
      }
    }
  }

  getControl(controlName: string) {
    return this.patientForm.controls[controlName];
  }
  
  isControlInvalid(controlName: string) {
    let control = this.getControl(controlName);

    // First check if the form is submitted or control is dirty
    if (!this.formSubmitted && !control.touched)
      return false;

    return control.invalid;
  }

  getBirthdateControl():FormControl | null{
    return this.patientForm.get('birthdate') 
      ? this.patientForm.get('birthdate') as FormControl 
      : null;
  }

  openAppointmentInfoDialog(){
    this.dialog.open(AppointmentInfoDialogComponent, {          
      panelClass: ['appointment-info-dialog', 'appointment-info-animation-in'],
      data: this.model.appointmentInfo
    });
  }

  private loadDataModel() {
    if (this.data && this.data.configurationData) {
      this.model = this.data.configurationData as PatientFormAppointmentDataModel;        
    }     
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.showAppointmentInfo = event.target.innerWidth > 799;
  }
}