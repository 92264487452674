import { Component, ChangeDetectorRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.css']
})
export class CompanyLogoComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  isLicensed: boolean;
  showCliniwebLogo: boolean;
  companyName: string;
  companyLogo:string;
  loading:boolean = true;
  isCliniwebPhr:boolean = false;

  companyLogos:{ [key: string]: string } = {
    'town-center':'assets/images/logos/townCenter.png',
    'mapfre': 'assets/images/logos/mapfre.png',
    'hospital-paitilla': 'assets/images/logos/paitilla-round.png',
    'pacifica-salud-phr': 'assets/images/logos/PuntaPacifica.png',
    'hsantafe-phr': 'assets/images/logos/HSantaFe.png'
  , 'chpanamericano-phr': 'assets/images/logos/chpanamericano-phr.png',
	'chsf-phr': 'assets/images/logos/chsf-phr.png',
    'thepanamaclinic-phr': 'assets/images/logos/thepanamaclinic-phr.png',
	'cmcaribe-phr': 'assets/images/logos/cmcaribe-phr.png',
	'hchiriqui-phr': 'assets/images/logos/hchiriqui-phr.png',
	'cmpaitilla-phr': 'assets/images/logos/cmpaitilla-phr.png'};
  
  @Input() showCompanyImage:boolean = false;

  constructor(
    private baseService: BaseService,
    private cd: ChangeDetectorRef,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
  ) { }

  ngOnInit(): void {
    this.isCliniwebPhr = this.baseService.isCliniwebCompany();

    if(this.isCliniwebPhr){
      this.loading = false;
      this.isLicensed = true;
    }
    else{
      this.baseService.isLicensedCompany()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result=>{
        this.loading = false;
        this.isLicensed = result;
        this.companyName = this.baseService.getDirectoryTitle();
        this.companyLogo = this.getCompanyLogoValueByPartialKey(this.baseService.getDirectoryClass());
        setTimeout(() => this.cd.detectChanges(), 100);
      });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private getCompanyLogoValueByPartialKey(partialKey: string): string {
    for (const key in this.companyLogos) {
      if (key.includes(partialKey)) {
        return this.companyLogos[key];
      }
    }
    return '';
  }
}
