<div class="header-container" fxFlex fxLayout="column" [ngClass]="routeClassName">
    <mat-toolbar class="header-toolbar">
        <button mat-icon-button (click)="gotoBack()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Volver"></mat-icon>
        </button>
        
        <img src="/assets/images/thepanamaclinic-phr/logo-thepanamaclinic-phr.svg" (click)="navigateToCompany()"/>

        <!-- Anonymous Menu -->
        <app-anonymous-menu *ngIf="!loading && isLicensedCompany"></app-anonymous-menu>

        <!-- Unlicensed Menu -->
        <app-unlicensed-menu *ngIf="!loading && !isLicensedCompany"></app-unlicensed-menu>
    </mat-toolbar>
</div>
