import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Token } from 'src/app/shared/models/token.model';
import { AppStorageService, STORAGE } from './app-storage.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoginResponse } from 'src/app/shared/models/login-response.model';
import { BaseService } from './base.service';
import { LoginCompletedMessage, LogoutMessage, MessageBusService, TerritorialDivionCountryMessage } from './message-bus.service';
import { Link } from 'src/app/shared/models/people/link.model';
import { Router } from '@angular/router';
import { HttpBaseService } from './http-base-service';
import { L10nTranslationService } from 'angular-l10n';
import { UserService } from './user.service';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { TerritorialDivisionsService } from './territorial-divisions.service';
import { isPlatformBrowser } from '@angular/common';

const AUTH_API = 'oauth/token';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  constructor(
    private http: HttpBaseService,
    private baseService: BaseService,
    private userService: UserService,
    private territorialDivisionService: TerritorialDivisionsService,
    private messageBusService: MessageBusService,
    private appStorageService: AppStorageService,
    private translation: L10nTranslationService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ){}

  login(username: string, password: string): Observable<LoginResponse> {
    return this.baseService.getDirectoryDomain()
    .pipe(switchMap((domain:string)=>{
      let body = new HttpParams()
        .set('username', username)
        .set('password', password)
        .set('domain', domain)
        .set('grant_type', 'password');

      return this.http.post<Token>(AUTH_API, body.toString(), httpOptions, false)
        .pipe(
          map((token) => {
            token.username = username;
            this.appStorageService.setToken(token);

            this.appStorageService.removeSessionItem(STORAGE.USER);

            let response = new LoginResponse();
            response.success = true;
            return response;
          }),
          catchError((err) => {
            let response = new LoginResponse();
            response.success = false;
            response.message = this.translation.translate("invalidCredentials");

            return of(response);
          }));
    }));
  }

  getTransferToken():Observable<string>{
    let token = this.getToken();

    return this.http.post<string>("api/authentication/transfer-token/generate", token);
  }

  getTokenByTransferToken(transferToken:string):Observable<string>{
    let transferTokenObject = { token: transferToken };

    return this.http.post<string>("api/authentication/transfer-token/process", transferTokenObject, null, false);
  }

  loginByTransferToken(transferToken:string):Observable<boolean>{

    return this.getTokenByTransferToken(transferToken)
    .pipe(switchMap((tokenString:string)=>{

      this.appStorageService.setSerializedToken(tokenString);

      return this.userService.getUserPersonLoggedIn()
      .pipe(switchMap((user:UserPerson | null) => {
        if (user)
          this.messageBusService.loginCompleted(new LoginCompletedMessage(user));
        return of(true);
      }));
    }));
  }

  /*getByKey(key: string): Observable<Token>{
    let url = environment.apiUrl + "api/authentication?key=" + key;

    return this.http.get<Token>(url);
  }*/

  isLoggedInFull(): Observable<boolean>{
    let token = this.appStorageService.getToken();

    if(token){
      return this.getAuthenticationToken(false)
      .pipe(
        switchMap(token => {
          return of(token != null);
        }));
    }
    else{
      return of(false);
    }
  }

  isLoggedIn(): boolean {
    let token = this.appStorageService.getToken();

    return !!token && !this.http.isTokenExpired(token);
  }

  getToken(){
    return this.appStorageService.getToken();
  }

  getSerializedToken(){
    return this.appStorageService.getSerializedToken();
  }

  getAuthenticationToken(redirectToLogin:boolean = true): Observable<Token | null> {
    return this.http.getValidAuthenticationToken(redirectToLogin);
  }

  logout() {
    // Clear all object and cookies
    this.appStorageService.clearLogout();

    this.territorialDivisionService.getCountryByIp()
    .subscribe(country=>{

        let msg = new TerritorialDivionCountryMessage();
        msg.country = country.territorialDivision;

        this.messageBusService.sendTerritorialDivisionCountry(msg);
    });

    // Get directory again
    // this.baseService.getAppConfig().subscribe((any) => {});

    // Send logout message
    this.messageBusService.logout(new LogoutMessage());
  } 

  isPathEnabled(path: string, menuLinks: Link[]):boolean {
    let _path = path.replace("/", "").split("/")[0].split("?")[0];

    let _linkSelected = menuLinks
    ? _path == ''
      ? menuLinks[0].identificadorUrl
        ? menuLinks[0]
        : menuLinks[0].subMenu.length > 0
          ? menuLinks[0].subMenu[0]
          : menuLinks[1]
      : this.getLinkSelected(_path, menuLinks)
    : null;

    // let result:boolean = (_linkSelected != null && (_linkSelected.nombreComponenteAnonimo != null || _linkSelected.accesoPublico == 'S'));

    let result:boolean;

    if(_linkSelected == null) {
      let resutRouteConfig = this.router.config.filter(p => p.path == _path);

      result = resutRouteConfig.length > 0;
    }
    else{
      result = (_linkSelected.nombreComponenteAnonimo != null || _linkSelected.accesoPublico == 'S');
    }

    return result;
  }

  hasPermissions(path: string):Observable<boolean>{
    return this.baseService.getMenuLinks()
    .pipe(map((links: Link[])=>{
      let _pathEnabled = this.isPathEnabled(path, links);
      let _tokenObject = this.appStorageService.getToken();
  
      if(_tokenObject) {
        return true;
      }
      else {
        if(_pathEnabled && path == '/empty'){
          this.router.navigate(['/login'], { queryParams: { returnUrl: "empty" } });
  
          if(isPlatformBrowser(this.platformId)){
            window.parent.postMessage({ eventName: 'pre_login' }, '*');
          }
          
          return false;
        }
        else {
          return true;
        }
      }
    }));
  }

  private getLinkSelected(path: string, links: Link[]){
    var linkSelected = links.find(link => this.baseService.getBasePath(link.identificadorUrl) == path);

    if(!linkSelected){
      links.filter(link => link.subMenu && link.subMenu.length > 0).forEach(link => {

        let subLinkSelected = link.subMenu.find(sublink => this.baseService.getBasePath(sublink.identificadorUrl) == path);

        if(subLinkSelected)
          linkSelected = subLinkSelected;
      });
    }

    return linkSelected;
  }
}