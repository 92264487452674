import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AppInitializationService {
    private initializedSubject = new BehaviorSubject<boolean>(false);  // Inicialmente no está listo

    setInitialized() {
      this.initializedSubject.next(true);  // Cambiar estado cuando la inicialización esté completa
    }
  
    isInitialized() {
      return this.initializedSubject.asObservable();  // Devuelve un observable para que los guards se suscriban
    }
}