<div class="anonymous-language-menu-container">
    <button mat-button class="language-button" [matMenuTriggerFor]="menu" yPosition="above">
      <span>{{'languageShort' | translate:locale.language}}</span>
      <mat-icon fontSet="phr-iconset" class="phr-iconset-arrow-down icon phr-iconset"></mat-icon>
    </button>      
    <mat-menu #menu="matMenu" class="language-header-menu-options">
      <a mat-menu-item *ngFor="let item of schema" (click)="setLocale(item.locale)">
        {{ item.locale.language | l10nDisplayNames:locale.language:{ type: 'language' } }}
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-check icon phr-iconset" *ngIf="item.locale.language == locale.language"></mat-icon>
      </a>
</mat-menu>
</div>