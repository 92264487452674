import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Direction } from 'src/app/shared/enums/direction.enum';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { Form } from 'src/app/shared/models/people/form.model';
import { Link } from 'src/app/shared/models/people/link.model';
import { TextLanguage } from 'src/app/shared/models/people/text-language.model';
import { BaseService } from './base.service';
import { HttpBaseService } from './http-base-service';
import { map } from 'rxjs/operators';
import { THEMES, Theme } from 'src/themes/theme';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private mobileDelimiter: number = 800;

  private hideComponentSource = new Subject<FormComponentData>();
  hideComponentSource$ = this.hideComponentSource.asObservable();

  private postNavigateSource = new Subject<void>();
  postNavigateSource$ = this.postNavigateSource.asObservable();

  private scrollingBodySource = new Subject<Direction>();
  scrollingBodySource$ = this.scrollingBodySource.asObservable();

  constructor(
    private http: HttpBaseService,
    private baseService:BaseService,
    @Inject(THEMES) private themes: Theme[],
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  getForm(idAreaSistema:number):Observable<Form>{
    let companyId = this.baseService.getCompanyId();    
    let url = "api/layout/formulario?idEmpresa=" + companyId + "&idAreaSistema=" + idAreaSistema;

    return this.http.get<Form>(url, null, false);
  }

  getTextByLanguage(objectSerialized: any, language: string): string{
    try {      
      let language = this.baseService.getLanguage();

      let textArray:TextLanguage[] = (typeof(objectSerialized) == 'object')
        ? objectSerialized
        : JSON.parse(objectSerialized);

      let textLanguage = textArray.find(t => t.lenguaje == language);

      return textLanguage ? textLanguage.texto : objectSerialized;
    }
    catch {
      return objectSerialized;
    }
  }

  hideComponent(data: FormComponentData){
    this.hideComponentSource.next(data);
  }

  postNavigate(){
    this.postNavigateSource.next();
  }

  onScrollingBody(dir:Direction){
    this.scrollingBodySource.next(dir);
  }

  loadLicenseTheme(classNameContainer:string, idResponsableServicio?: number){
    return this.baseService.isLicensedCompany()
    .pipe(
      map((result:boolean)=>{

        let directoryClassname: string = 'cliniweb'; 

        if(result){
          directoryClassname = this.baseService.getDirectoryClass();
        }

        const theme = this.themes.find(t => t.name === directoryClassname);

        if(!theme) return;

        const containerElement = this.document.getElementsByClassName(classNameContainer)[0];

        for (const key in theme.properties) {
          containerElement.style.setProperty(key, theme.properties[key]);
        }
     })
    );
  }

  isMobile(event?: any):boolean{
    if(event && event.target && event.target.innerWidth){
      return event.target.innerWidth <= this.mobileDelimiter;
    }
    else if(isPlatformBrowser(this.platformId)){
      return window.innerWidth <= this.mobileDelimiter;
    }
    else{
      return false;
    }
  }

  overflowBodyDisabled(){
    this.document.documentElement.classList.add('overflow-disabled');
  }

  overflowBodyEnabled(){
    this.document.documentElement.classList.remove('overflow-disabled');
  }

  addComponentInitClass(componentClass: string){
    this.document.documentElement.classList.add(componentClass);
  }

  removeComponentInitClass(componentClass: string){
    this.document.documentElement.classList.remove(componentClass);
  }

  isHeaderVisible(){
    return this.document.getElementsByClassName("header-dynamic-container")[0].offsetParent !== null;
  }

  addClassToBody(className:string){
    if(className)
      this.document.body.classList.add(className);
  }

  removeClassToBody(className:string){
    if(className)
      this.document.body.classList.remove(className);
  }
}