<div class="unlicensed-login-signup-menu-container">
  
    <button
      mat-button class="menu-button"
      [matMenuTriggerFor]="unlicensedmenu"
      yPosition="above"
      *ngIf="showAnonymousMenu | async"
      (click)="onMenuOpened()"
    >
      <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-menu icon phr-iconset"></mat-icon>              
    </button>

    <mat-menu #unlicensedmenu="matMenu" class="unlicensed-mat-menu-container" (closed)="onMenuClosed()">          
      
      <button tabindex="-1" mat-icon-button class="btn-close-mobile" (click)="onCloseClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Cerrar"></mat-icon>
      </button>
      <div class="company-logo-container">
        <app-company-logo></app-company-logo>
      </div> 
     
      <span class="menu-title">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-ISO icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text1' | translate:locale.language}}</span>
      </span>
     
      <!-- Appointments -->
      <button mat-menu-item (click)="onViewAppointmentsClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-date-2 icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text2' | translate:locale.language}}</span>            
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window icon phr-iconset"></mat-icon>
      </button>
      <!-- Cliniweb for patients -->
      <button mat-menu-item (click)="onCliniwebForPatientsClick()">            
        <mat-icon fontSet="phr-iconset" class="phr-iconset-help icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text3' | translate:locale.language}}</span>   
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window icon phr-iconset"></mat-icon>         
      </button>

      <!-- Logout -->
      <button mat-menu-item (click)="onCliniwebLogoutClick()" *ngIf="showLogout">            
        <mat-icon fontSet="phr-iconset" class="phr-iconset-logout icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text8' | translate:locale.language}}</span>         
      </button> 

      <app-anonymous-language-menu-title color="linear-gradient(90deg, #0094C7 0%, #409954 100%)" icon="ISO" text="unlicensedMenu.text4"></app-anonymous-language-menu-title>

      <!-- Login -->
      <button mat-menu-item (click)="goToPortal()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-login icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text5' | translate:locale.language}}</span> 
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window icon phr-iconset"></mat-icon>           
      </button>
      <!-- Create public profile -->
      <button mat-menu-item (click)="goToPortalPublicProfilePage()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-star icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text6' | translate:locale.language}}</span>  
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window icon phr-iconset"></mat-icon>          
      </button> 
      <!-- Cliniweb for doctors -->
      <button mat-menu-item (click)="onCliniwebForDoctorsClick()">
        <mat-icon fontSet="phr-iconset" class="phr-iconset-help icon phr-iconset"></mat-icon>
        <span>{{'unlicensedMenu.text7' | translate:locale.language}}</span> 
        <mat-icon fontSet="phr-iconset" class="phr-iconset-new-window icon phr-iconset"></mat-icon>           
      </button>

      <app-anonymous-language-menu-title [themeColor]="true" icon="url" text="unlicensedMenu.text9"></app-anonymous-language-menu-title>

      <app-anonymous-language-menu-item class="language-menu"></app-anonymous-language-menu-item>
      
    </mat-menu>
</div>