import { Component, Inject, Input, OnDestroy, OnInit, ViewChild, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { GrowthMeasurementsService } from 'src/app/core/services/growth-measurements.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { ChartType, GrowthMeasurementRange, GrowthMeasurementUnitType } from 'src/app/shared/models/clinic-history/growth-measurement-type.model';
import { GrowthMeasurement, GrowthMeasurements } from 'src/app/shared/models/clinic-history/growth-measurements.model';
import { GrowthPercentile } from 'src/app/shared/models/clinic-history/growth-percentile.model';
import { MatSelect } from '@angular/material/select';
import { GropwthViewTypeOption, GrowthChartTypeSelectComponent } from '../growth-chart-type-select/growth-chart-type-select.component';
declare var CanvasJS : any;

@Component({
  selector: 'app-growth-charts-tab',
  templateUrl: './growth-charts-tab.component.html',
  styleUrls: ['./growth-charts-tab.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GrowthChartsTabComponent implements OnInit, OnDestroy {

  //subscribes
  private ngUnsubscribe = new Subject();

  @Input() loading: boolean = false;
  @Input() id: string;
  @Input() type: ChartType;
  @Input() ranges: GrowthMeasurementRange[] = [];
  @Input() gender: string;
  @Input() measurements: GrowthMeasurements;
  @ViewChild('rangeSelect') rangeSelect: MatSelect;
  @ViewChild(GrowthChartTypeSelectComponent) viewTypeSelect: GrowthChartTypeSelectComponent;

  percentiles: GrowthPercentile[] = [];

  selectedRange: number;
  selectedView = GropwthViewTypeOption.GRAFICO;

  axisYText: string;

  constructor(
    private baseService: BaseService,
    private layoutService: LayoutService,
    private growthMeasurementsService: GrowthMeasurementsService,
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
  ) {
  }

  ngOnInit(): void {
    this.fillAxisYText();

    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
        this.fillAxisYText();

        if(!this.loading)
          this.renderCharts();
      }
    });
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.measurements && changes.measurements.currentValue) {
      this.selectRangeByPatientAge();
      this.updateTab(0);
    }
  }

  private selectRangeByPatientAge() {
    if (this.type == ChartType.WEIGHT_FOR_SIZE) {
      this.selectedRange = this.ranges.map(x => x.id)[0] ;
    } else {
      const ranges = this.ranges.filter(r => this.measurements.mediciones.some(m => this.isWithinRange(m, r))).map(r => r.id);

      this.selectedRange = ranges.length ? ranges[0] : this.ranges[0].id;
    }
  }

  private isWithinRange(m: GrowthMeasurement, r: GrowthMeasurementRange): boolean {
    const age = this.getDecimalAge(m.edadAnios, m.edadMeses, m.edadDias, r.codigoUnidadMedida);
    return age >= r.rangoInicial && age <= r.rangoFinal;
  }

  updateTab(rangeIndex = -1){
    const range = this.ranges.find(r => r.id == this.selectedRange);

    if(!range){
      return;      
    }

    this.loading = true;

    const chartContainer = document.getElementById('chart_' + this.type);

    if (chartContainer) {
      chartContainer.innerHTML = '';

      const emptyStateDiv = document.createElement('div');
      emptyStateDiv.className = 'loading-state';
      emptyStateDiv.innerHTML = 'Cargando...';

      chartContainer.appendChild(emptyStateDiv);
    }

    this.growthMeasurementsService.getPercentiles(
      range.codigoEstandar, 
      this.type,
      this.gender,
      range.rangoInicial,
      range.rangoFinal,
      range.codigoUnidadMedida
    ).subscribe(p=> this.processPercentiles(p, rangeIndex));
  }

  private processPercentiles(percentiles: GrowthPercentile[], rangeIndex: number) {
    this.percentiles = percentiles;

    this.loading = false;

    this.renderCharts(rangeIndex);
  }

  renderCharts(rangeIndex = -1) {
    const chartContainer = document.getElementById('chart_' + this.type);

    if (chartContainer) {
      chartContainer.innerHTML = '';
    }

    let selectedRangeObj = this.ranges.find(r => r.id == this.selectedRange);

    if (!selectedRangeObj) {
      return;
    }

    if (!this.measurements || this.measurements.mediciones.length === 0 || this.getPatientGrowthMeasurementsByRange(selectedRangeObj).length === 0) {
      this.viewTypeSelect?.goToEmptyState();
      chartContainer?.parentElement?.classList.add('empty-state');
      this.showEmptyState();
      if (rangeIndex >= 0 && rangeIndex < this.ranges.length && this.type == ChartType.WEIGHT_FOR_SIZE) {
        this.updateTab(++rangeIndex);
      }
      return;
    }

    chartContainer?.parentElement?.classList.remove('empty-state');

    // agrupo los percentiles por el nombre
    const groupedData = this.percentiles.reduce((acc:any, item:GrowthPercentile) => {
      if (!acc[item.nombre]) {
          acc[item.nombre] = [];
      }
      acc[item.nombre].push({ x: item.x, y: item.y, legendText: item.nombre });
      return acc;
    }, {});

    // genero la estructura del objeto de canvajs para cada percentil
    const data = Object.keys(groupedData).map(key => {
      const dataPercentile = new CanvasDataModel();

      dataPercentile.type = "line";
      dataPercentile.name = key;
      dataPercentile.markerSize = 0;
      dataPercentile.indexLabelFontSize = 13;
      dataPercentile.indexLabelFontFamily = "Segoe UI";
      dataPercentile.indexLabelFontColor = "#404040";
      dataPercentile.showInLegend = true;
      dataPercentile.dataPoints = groupedData[key];

      return dataPercentile;
    });

    // datos del paciente
    const dataPatient = new CanvasDataModel();
    dataPatient.type = "line";
    dataPatient.name = "Paciente";
    dataPatient.lineColor = "#000000";
    dataPatient.markerColor = "#000000";
    dataPatient.color = "#000000";
    dataPatient.indexLabelFontSize = 13;
    dataPatient.indexLabelFontFamily = "Segoe UI";
    dataPatient.indexLabelFontColor = "#404040";
    dataPatient.showInLegend = true;
    dataPatient.dataPoints = this.getPatientGrowthMeasurementsByRange(selectedRangeObj);
    
    data.push(dataPatient);

    // opciones para canvasjs
    let options = {
      animationEnabled: true,
      animationDuration: 500,
      theme: "light2",
      axisY: {
          title: this.axisYText,
          titleFontSize: 16,
          titleFontFamily: "Segoe UI",
          titleFontColor: '#404040',
          includeZero: false,
          interval: selectedRangeObj.intervaloY
      },
      axisX: {
      },
      data: data,
      toolTip: {
          backgroundColor: 'black',
          contentFormatter: (e:any) => {
              let content = null;
              for (var i = 0; i < e.entries.length; i++) {
                  const item = e.entries[i];
                  if (item.dataSeries.name === 'Paciente')
                      content = `<b>${item.dataPoint.xReference}</b><br/>${item.dataPoint.yReference}<br/>${item.dataPoint.references}`;
              }
              return content;
          },
          fontColor: 'white',
          fontSize: 12
      }  
    };

    // configuración de eje x
    const axisX = new AxisXCanvasDataModel();
    axisX.title = this.layoutService.getTextByLanguage(selectedRangeObj.nombreUnidadMedida, this.locale.language);
    axisX.titleFontSize = 16;
    axisX.titleFontFamily = "Segoe UI";
    axisX.titleFontColor = '#404040';
    axisX.interval = selectedRangeObj.intervaloX;

    if(this.type == ChartType.WEIGHT_FOR_SIZE){
      axisX.title = this.translation.translate('shared.growthChartsTab.axisY1');
      axisX.minimum = data[0].dataPoints[0].x;
      axisX.maximun = data[0].dataPoints[data[0].dataPoints.length - 1].x;
      axisX.viewportMinimum = data[0].dataPoints[0].x;
      axisX.viewportMaximun = data[0].dataPoints[data[0].dataPoints.length - 1].x;
    }
    else{
      axisX.minimum = selectedRangeObj.rangoInicial;
      axisX.maximun = selectedRangeObj.rangoFinal;
      axisX.viewportMinimum = selectedRangeObj.rangoInicial;
      axisX.viewportMaximun = selectedRangeObj.rangoFinal;
    }

    options.axisX = axisX;

    if (this.viewTypeSelect.getValue() === GropwthViewTypeOption.GRAFICO) {
      let chart = new CanvasJS.Chart('chart_' + this.type, options);
      chart.render();
    }
    
    this.viewTypeSelect.resume();
  }

  private fillAxisYText(){
    this.axisYText = this.translation.translate('shared.growthChartsTab.axisY' + this.type);
  }

  private getPatientGrowthMeasurementsByRange(range: GrowthMeasurementRange){
    if(!this.measurements || this.measurements.mediciones.length == 0){
      return [];
    }

    let measurements:CanvasDataPoint[] = [];

    if (this.type == ChartType.WEIGHT_FOR_SIZE) {
      measurements = this.measurements.mediciones.map(m => {
          const hca = JSON.parse(m.jsonHistoriaClinicaActual);
          const data = hca && hca['PesoTalla'] || { Percentiles: [], Unidad: 'cm' };
          const percentiles = data.Percentiles;

          const dataPoint:CanvasDataPoint = {
              x: m.valorTallaCm,
              y: m.valorPesoKg,
              xReference: this.translation.translate(`shared.growthChartsTab.axisYWithValue1`, { value: m.valorTallaCm }),
              yReference: this.translation.translate(`shared.growthChartsTab.axisYWithValue2`, { value: m.valorPesoKg }),
              references: percentiles.reduce((prev:any, curr:any) => `${prev}${curr.Nombre}: ${curr.Valor.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${data.Unidad}<br/>`, '')
          }

          return dataPoint;
      });

      measurements = measurements.filter(m => m.x >= this.percentiles[0]?.x && m.x <= this.percentiles[this.percentiles.length -1]?.x);
    }
    else {
      const measurementsForRange = this.measurements.mediciones.filter(obj => {
          let edadTotal = this.getDecimalAge(obj.edadAnios, obj.edadMeses, obj.edadDias, range.codigoUnidadMedida);
          return edadTotal >= range.rangoInicial && edadTotal < range.rangoFinal;
      });

      measurements = measurementsForRange.map(m => {
          const hca = JSON.parse(m.jsonHistoriaClinicaActual);
          const data = hca && hca[this.growthMeasurementsService.getHcaFieldByType(this.type)] || { Percentiles: [], Unidad: '' };
          const percentiles = data.Percentiles;
          const edadTotal = this.getDecimalAge(m.edadAnios, m.edadMeses, m.edadDias, range.codigoUnidadMedida);
          const valor = this.getAxisYTextByType(m);
          const y = this.getAxisYByType(m);

          const dataPoint:CanvasDataPoint ={
              x: edadTotal,
              y: y,
              xReference: this.baseService.getDetailedAge(m.edadAnios, m.edadMeses, m.edadDias),
              yReference: valor,
              references: percentiles.reduce((prev:any, curr:any) => `${prev}${curr.Nombre}: ${curr.Valor.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${data.Unidad}<br/>`, '')
          };

          return dataPoint;
      });

      measurements = measurements.filter(m => m.x >= range.rangoInicial && m.x <= range.rangoFinal);
    }

    return measurements.filter(m => m.y > 0);
  }

  private getDecimalAge(yearAge:number, monthsAge: number, daysAge: number, unitType: GrowthMeasurementUnitType):number{
    switch (unitType) {
      case GrowthMeasurementUnitType.YEARS:
        return yearAge + (monthsAge / 12) + (daysAge / 365.25); // 365.25 para años bisiestos
      case GrowthMeasurementUnitType.MONTHS:
        return (yearAge * 12) + monthsAge + (daysAge / 30.44); // 30.44 para promedio mensual
      case GrowthMeasurementUnitType.WEEKS:
        return (yearAge * 52) + (monthsAge * 4.34524) + (daysAge / 7); // 4.34524 promedio de semanas por mes
      default:
          return 0;
    }
  }

  private getAxisYTextByType(hca:any):string{
    let value = this.getAxisYByType(hca);

    return this.translation.translate(`shared.growthChartsTab.axisYWithValue${this.type}`, { value });
  }

  private getAxisYByType(hca:any):number{
    switch (this.type) {
      case ChartType.SIZE_FOR_AGE:
      default:
          return hca.valorTallaCm;
      case ChartType.WEIGHT_FOR_AGE:
          return hca.valorPesoKg;
      case ChartType.BMI_FOR_AGE:
          return hca.valorIMC;
      case ChartType.HEAD_CIRCUMFERENCE_FOR_AGE:
          return hca.valorCircunferenciaCefalicaCm;
    }
  }

  private showEmptyState() {
    const chartContainer = document.getElementById('chart_' + this.type);

    if (chartContainer) {
      chartContainer.innerHTML = '';

      const emptyStateDiv = document.createElement('div');
      emptyStateDiv.className = 'empty-state';

      const img = document.createElement('img');
      img.src = '/assets/images/curvas_crecimiento/empy_state_curvas.png';
      img.alt = 'Empty State';
      img.className = 'image-empty';
      emptyStateDiv.appendChild(img);

      const selectedRange = this.ranges.find(r => r.id === this.selectedRange);
      const selectedRangeText = this.layoutService.getTextByLanguage(selectedRange?.nombre, this.locale.language).toLowerCase();

      const tipoMedicion = this.layoutService.getTextByLanguage(this.translation.translate(`shared.growthCharts.labeltab_${this.type}`), this.locale.language);

      let tabText;

      switch (this.type) {
        case 4: 
          tabText = "IMC";
          break;
        default:
          tabText = tipoMedicion.split(' ')[0].toLowerCase();
          break;
      }

      const text = `No hay datos de ${tabText} que graficar para la escala `;

      const textDiv = document.createElement('div');
      textDiv.className = 'empty-state-text text-common';
      textDiv.innerHTML = text;

      // Creo un span para el selectedRangeText
      const rangeTextSpan = document.createElement('span');
      rangeTextSpan.className = 'range-text';
      rangeTextSpan.innerText = selectedRangeText;

      // Agrego evento de clic al span para desplegar el menu de rangos
      rangeTextSpan.addEventListener('click', () => {
        if (this.rangeSelect) {
          this.rangeSelect.open();
        }
      });

      textDiv.appendChild(rangeTextSpan);
      emptyStateDiv.appendChild(textDiv);

      // Agrego texto descripcion
      const additionalTextDiv = document.createElement('div');
      additionalTextDiv.className = 'additional-text text-common';
      additionalTextDiv.innerText = "Para ver información de crecimiento, puedes probar cambiando la escala y/o navegando entre las diferentes pestañas de Peso, Talla, Cabeza, etc.";

      emptyStateDiv.appendChild(additionalTextDiv);
      chartContainer.appendChild(emptyStateDiv);
    }
  }

  onChartTypeChange(arg: GropwthViewTypeOption) {
    this.selectedView = arg;
    if ( arg === GropwthViewTypeOption.GRAFICO)
     this.updateTab();
  }

 getRanges(): GrowthMeasurementRange {
  return this.ranges.find(r => r.id == this.selectedRange) || new GrowthMeasurementRange();
 }
}

export class CanvasDataModel{
  type: string;
  name: string;
  lineColor: string;
  markerSize: number;
  markerColor: string;
  color: string;
  indexLabelFontSize: number;
  indexLabelFontFamily: string;
  indexLabelFontColor: string;
  showInLegend: boolean;
  dataPoints: any
}
export class AxisXCanvasDataModel{
  title: string | undefined;
  titleFontSize: number;
  titleFontFamily: string;
  titleFontColor: string;
  interval: number;
  viewportMinimum: number;
  viewportMaximun: number;
  minimum: number;
  maximun: number;
}

export class CanvasDataPoint {
  x: number;
  y: number;
  xReference: string;
  yReference: string;
  references: string;
}
