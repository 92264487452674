import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { DirectorySearchTitle } from 'src/app/shared/interfaces/directory-search-title';

@Component({
  selector: 'app-search-result-title',
  templateUrl: './search-result-title.component.html',
  styleUrls: ['./search-result-title.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchResultTitleComponent implements OnChanges {

  @Input() nombreFormulario: string;
  texto1 = 'Doctores';
  texto2 = '';
  textoPrep = '';
  texto3 = '';
  private title: DirectorySearchTitle = {aseguradora: '', id: 0, lugar: '', perfilBusqueda: '', preposicion: ''};
  private texto1Tamanios: {[key: string]: number} = {'600':40, '800':36, '1000':40, '2560':50};
  private texto2Tamanios: {[key: string]: number} = {'600':12, '800':10, '1000':12, '2560':18};
  private texto3Tamanios: {[key: string]: number} = {'600':34, '800':30, '1000':34, '2560':40};

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nombreFormulario) {
      this.title.perfilBusqueda = (JSON.parse(this.nombreFormulario) as Array<{lenguaje: string, texto: string}>)
      .find(x => x.lenguaje == this.locale.language)?.texto ?? '';
    }
  }

  setTitle(arg: DirectorySearchTitle): void {
    this.title = arg;
    this.textoPrep = arg.preposicion;
    this.windowResize()
  }

  @HostListener('window:resize', ['$event'])
  private windowResizeEvent(_?: Event) {
    this.windowResize();
  }

  private windowResize() {
    if(isPlatformBrowser(this.platformId)){    
      const width = window.innerWidth;
      this.texto1 = this.truncar(this.texto1Tamanios, width, this.title.perfilBusqueda);
      this.texto2 = this.truncar(this.texto2Tamanios, width, this.title.aseguradora);
      this.texto3 = this.truncar(this.texto3Tamanios, width, this.title.lugar);
    }
  }

  private truncar(arg0: {[key: string]: number}, arg1: number, arg2: string): string {
    const listKeys = Object.keys(arg0).filter(x => arg1 <= parseInt(x));
    const breakpoint = parseInt(listKeys[0]);
    const maxWidth = arg0[breakpoint] - 1;
    const ellipsis = arg2.length > maxWidth;
    return `${arg2.slice(0, maxWidth).trim()}${ellipsis ? '...' : ''}`;
  }

}
