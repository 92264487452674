
<div class="map-dialog-container">
    <div class="close">
        <a (click)="onCloseClick()">
            <mat-icon fontSet="phr-iconset" class="phr-iconset-close icon-close"></mat-icon>
            <span *ngIf="data.showCloseTextButton">{{ 'close' | translate:locale.language}}</span>
        </a>
    </div>

    <div #titleTag mat-dialog-title class="title" [ngClass]="{'with-subtitle': description }">{{title}}</div>
    <div mat-dialog-title class="subtitle" *ngIf="description">{{description}}</div>

    <div mat-dialog-content>
        <google-map class="map-container" [center]="{lat: lat, lng: lng}" [zoom]="zoom">
            <map-marker [position]="{lat: lat, lng: lng}"></map-marker>
        </google-map>
    </div>
    <a mat-button mat-flat-button class="go-with-waze-btn primary outline small" target="_blank" [href]="buildWazeLink()" *ngIf="showWazeButton">   
        <mat-icon fontSet="phr-iconset" color="primary" class="phr-iconset-waze"></mat-icon>             
        <span class="text-btn">{{ 'shared.mapDialog.text1' | translate:locale.language }}</span>
    </a>
</div>

