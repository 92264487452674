import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { MapDialogData } from 'src/app/shared/models/dialog-data.model';
import { ProfileLocationResultModel, ProfileResultModel } from 'src/app/shared/models/people/profile-search-results.model';
import { MapDialogComponent } from '../../../dialogs/map-dialog/map-dialog.component';

@Component({
  selector: 'app-provider-card-location',
  templateUrl: './provider-card-location.component.html',
  styleUrls: ['./provider-card-location.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProviderCardLocationComponent implements OnChanges {

  @Input() location: ProfileLocationResultModel;
  @Input() isDoctorProfile = false;
  @Input() provider: ProfileResultModel;
  @Output() goToProfile: EventEmitter<Event> = new EventEmitter();

  locationAddress = '';
  locationAvailability = '';
  locationIconClass = '';
  locationTitle = '';

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private dialog: MatDialog
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.location) {
      this.setLocationIconClass();
      this.setLocationAddress();
      this.setLocationAvailability();
      this.setLocationTitle();
    }
  }

  setLocationAddress(): void {
    let result = "";

    if (this.location.diLocalidad2)
        result += this.location.diLocalidad2;

    if (this.location.diLocalidad1) {
        if (result && !result.endsWith(",") && !result.endsWith(".") && !result.endsWith("-"))
            result += " - ";

        result += this.location.diLocalidad1;
    }

    if (this.location.ciudad) {
        if (result && !result.endsWith(",") && !result.endsWith("."))
            result += ", ";

        result += " " + this.location.ciudad;
    }

    if (this.location.pais) {
        if (result && !result.endsWith(",") && !result.endsWith("."))
            result += ", ";

        result += " " + this.location.pais;
    }

    this.locationAddress =  result;
  }

  private setLocationIconClass(): void {
    this.locationIconClass = this.location?.teleconsulta != "S" 
      ? 'phr-iconset-PHR-place' 
      : 'phr-iconset-PHR-video';
  }

  private setLocationAvailability(): void {
    const availability = this.provider?.citaEnLineaHab != 'N' ? 'bookAppointment' : 'viewProfile';

    this.locationAvailability = `shared.searchResults.providerCard.${availability}`;
  }

  goToProfileEvent($event:Event) {
    this.goToProfile.emit($event)
  }

  onClickHowToGetButton(location: ProfileLocationResultModel, $event:Event){
    
    $event.preventDefault();
    $event.stopPropagation();

    let data = new MapDialogData();
    
    data.title = this.locationTitle;
    // data.description = this.getLocationAddress(location);
    data.showWazeButton = true;
    data.message = location.objetoDetalle3;
    data.animationIn = 'map-phr-animation-in';
    data.animationOut = 'map-phr-animation-out';
    data.showCloseTextButton = true;

    this.dialog.open(MapDialogComponent, {          
      panelClass: ['map-dialog','map-phr-dialog', 'map-phr-animation-in'],
      data: data
    });
  }

  setLocationTitle(): void {
    let result = "";

    if (this.location.empresaObjetoPerfilPublico)
        result = this.location.empresaObjetoPerfilPublico;

    if (this.location.empresaPerfilPublico && this.location.empresaPerfilPublico.trim() != ".")
        result += (result ? " - " : "") + this.location.empresaPerfilPublico;

    this.locationTitle =  result;
  }

}
