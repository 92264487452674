import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ComponentLookup } from '../../decorators/component-lookup.decorator';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { BaseService } from 'src/app/core/services/base.service';
import { DoctorService } from 'src/app/core/services/doctors.service';
import { TerritorialDivisionsService } from 'src/app/core/services/territorial-divisions.service';
import { DoctorSearchResult } from 'src/app/shared/models/cdr/doctor-search-result.model';
import { TerritorialDivision } from 'src/app/shared/models/systems/territorial-division.model';
import { ActivatedRoute,  Router } from '@angular/router';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { PublicProfileType } from '../../models/people/profile-search-results.model';
import { MessageBusService, ProfileSearchMessage } from 'src/app/core/services/message-bus.service';
import { FormComponentData } from '../../models/people/form-control.model';
import { IControlComponent } from '../../interfaces/control-component';
import { UtilsService } from 'src/app/core/services/utils.service';
import { DirectoryFilters } from '../../models/systems/directory.model';
import { PortalPhrConfiguracionLocalidad } from '../../models/systems/portal-phr.model';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'search-doctors-container',
  templateUrl: './search-doctors.component.html',
  styleUrls: ['./search-doctors.component.css']
})
@ComponentLookup('SearchDoctorsComponent')
export class SearchDoctorsComponent implements OnInit, IControlComponent {  
    data: FormComponentData;
    searchDoctorsForm: FormGroup;
    cityControl: FormControl;
    searchText : FormControl;
    searchValue = "";
    searchTimeout: number;    
    locationGroups: TerritorialDivisionGroup[] = [];
    selectedCity: TerritorialDivision;
    filteredLocationsGroups: TerritorialDivisionGroup[];    
    resultWrapper: DoctorSearchResultUIWrapper;    
    companyName: string = "";
    qsParams: SearchResultsQsParameters;     
    placeholderTexts: [];
    onlyDoctorsProvider = false;   
    clinicalConcept: DoctorSearchResult;    
    disabledSearch = false;    
    profileSearchTypes = [PublicProfileType.Doctor];
    locationConfiguration: PortalPhrConfiguracionLocalidad;
    cityText: string;
    stateText: string;
    countryText: string;
    headquartersCompIds: number[] = [];

    @Input() parentData: FormComponentData;

    @Input() useSearchEvents = false;    
    @Input() profileType = PublicProfileType.Doctor;

    isBrowser: boolean;

    constructor(
        private doctorService: DoctorService, 
        private territorialDivisionsService: TerritorialDivisionsService,
        private baseService: BaseService,
        private router: Router,
        private route : ActivatedRoute,
        private messageBusService: MessageBusService,
        private translation: L10nTranslationService,
        private utilsService: UtilsService,
        private publicProfileService: PublicProfileService,
        @Inject(L10N_LOCALE) public locale: L10nLocale,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    ngOnInit(): void {
        this.parseControlData();

        this.cityControl = new FormControl('');
        this.searchText = new FormControl('');

        this.searchDoctorsForm = new FormGroup({
            searchText: this.searchText,
            city: this.cityControl
        });       

        let companyId = this.baseService.getCompanyId();
        this.companyName = this.baseService.getCliniwebDirectoryCompanyName();
        
        let dir = this.baseService.getDirectory();
        this.headquartersCompIds = dir.idEmpresasSedes? dir.idEmpresasSedes.split(",").map(id => parseInt(id)) : [];

        this.resultWrapper = new DoctorSearchResultUIWrapper(companyId, this.headquartersCompIds);       

        this.translation.onChange().subscribe({
            next: () => {
                this.placeholderTexts = this.translation.translate(this.isMobile()? 'shared.searchDoctors.text13': 'shared.searchDoctors.text12');                                  
                this.cityText = this.translation.translate('city');  
                this.stateText = this.translation.translate('province');  
                this.countryText = this.translation.translate('country');                                  
            }
        });        
        
        if (dir && dir.configuracionBusquedaLocalidad)
            this.locationConfiguration = this.baseService.parseObject<PortalPhrConfiguracionLocalidad>(JSON.parse(dir.configuracionBusquedaLocalidad)); 

        this.loadAllCities();

        // Auto complete search function       
        this.cityControl.valueChanges.subscribe(v => {
            // When an option is selected here we receive the complete object (TerritorialDivision), in those cases we want to filter by the object name
            if (v && v.id)
                v = v.nombre;

            if (!v)
                this.filteredLocationsGroups = this.locationConfiguration? this.goupCities(this.locationConfiguration.localidadesPorDefecto) : this.locationGroups;
            else {
                let filtered = this._filterCities(v);

                this.filteredLocationsGroups = filtered;
            }            
        });

        this.setDirectorySearchProfileTypes();

        // On search results page we will initialize the control base con QS parameters
        this.initializeFromQs();
    }   

    isMobile() {
        return this.isBrowser && window.outerWidth < 959;
    }

    initializeFromQs() {
         // Get search parameters
        this.route.queryParams.subscribe(params => {
            this.qsParams = this.baseService.parseObject<SearchResultsQsParameters>(params);

            if (this.qsParams && this.qsParams.t) {
                // Disabled search for 1 second so it does not fire after we change the search input text
                this.disableSearch(1000);

                this.searchDoctorsForm.controls["searchText"].setValue(this.qsParams.t);
            }   

            if (this.qsParams && this.qsParams.tp)
                this.profileType = this.qsParams.tp;
            
            if (this.qsParams && this.qsParams.ds)
                this.onlyDoctorsProvider = true;

            if (this.qsParams && this.qsParams.nav)
            {
                this.clinicalConcept = new DoctorSearchResult();
                this.clinicalConcept.claseId = this.qsParams.idTc;
                this.clinicalConcept.id = this.qsParams.idC;
                this.clinicalConcept.url = this.qsParams.nav;
                this.clinicalConcept.nombre = this.qsParams.t;
            }

            this.setDirectorySearchProfileTypes();

            if (this.qsParams)
                setTimeout(() => {
                    this.fireSearchEvent(this.qsParams);    
                }, 50);                
        });        
    }

    setDirectorySearchProfileTypes() {        
        let directory = this.baseService.getDirectory();

        if (directory && directory.filtros) {
            let filtersObj = JSON.parse(directory.filtros);
            let filters = this.baseService.parseObject<DirectoryFilters>(filtersObj);            

            // Find the filter for this provider
            let provider = filters.providers.find(p => p.type == this.profileType);

            // Check if the provider has special configuracion for search
            if (provider && provider.searchProviderTypes) {
                this.profileSearchTypes = provider.searchProviderTypes;
            }
            else             
                this.profileSearchTypes = [this.profileType];
        }        
    }    

    onOptionSelected(event: MatAutocompleteSelectedEvent) {
        this.selectedCity = event.option.value        

        // If the user selected previously a concept, let just fire the search
        if (this.clinicalConcept)
            this.navigateToSearchResult(this.clinicalConcept);
        else if (this.useSearchEvents)
            this.onSearchClick();
    }

    autoCompleteDisplayWith(item: TerritorialDivision) {
        return item? item.nombre : "";
    }   

    onAutocompleteOpened() {
        
        // Each time the user open the autocomplete we want to show ALL options
        // After that the autocomplete will work as always
        //let filtered = this._filterCities("");

        //this.filteredLocationsGroups = filtered;
    }  
    
    onAutocompleteClosed() {
        // When autocomplete closed always set the name of the selected city/state
        if (this.selectedCity) {
            this.cityControl.setValue(this.selectedCity);
        }
    }

    clearSelectedName() {
        this.searchText.setValue("");
        this.clinicalConcept = null as any;
        this.resultWrapper.clear();

        // Fire search
        if (this.useSearchEvents)
            this.onSearchClick();
    }

    clearSelectedLocation(trigger : MatAutocompleteTrigger) {
        this.selectedCity = null as any;
        this.cityControl.setValue(this.selectedCity);
        
        // Fire search
        if (this.useSearchEvents)
            this.onSearchClick();

        // Open combo to select a new option
        setTimeout(() => {
            trigger.openPanel();
        }, 100);        
    } 

    private _filterCities(value: string): TerritorialDivisionGroup[] {
        
        try {
            // We need to use a try catch, because when searching this is an string but when an option is selected this is an object
            const filterValue = value.toLowerCase();           
           
            let result: TerritorialDivisionGroup[] = [];

            for (let index = 0; index < this.locationGroups.length; index++) {
                const group = this.locationGroups[index];
                
                let locations = group.values.filter(l => l.nombre.toLowerCase().indexOf(filterValue) === 0);

                if (locations.length > 0)
                    result.push(new TerritorialDivisionGroup(group.key, locations, this.getLocationDisplayNameFromLevel(group.key)))
            }

            //return this.cities.filter(c => c.nombre.toLowerCase().indexOf(filterValue) === 0);           

            return result;
        } 
        catch (error) {            
        }

        return [];
    }

    loadAllCities(): void {
        let levels = "3"; // Cities

        if (this.locationConfiguration)
            levels = this.locationConfiguration.niveles;

        this.territorialDivisionsService.getByLevels(this.locale.language, "Panama", levels).subscribe(locations => {
            locations.forEach(c => c.nombre = c.nombre.toLowerCase());
            
            this.locationGroups = this.goupCities(locations);            
            this.filteredLocationsGroups = this.goupCities(this.locationConfiguration? this.locationConfiguration.localidadesPorDefecto : locations);            
           
            if (this.qsParams && this.qsParams.idP) {
                let city = locations.find(c => c.id == this.qsParams.idP);

                if (city) {
                    this.cityControl.setValue(city);
                    this.selectedCity = city;
                }
            }
        },
        error => {
            this.baseService.handleServiceError(error, "Error getting cities");
        });
    }    

    goupCities(locations: TerritorialDivision[]) : TerritorialDivisionGroup[] {
        var groups: any = [];
        var result: TerritorialDivisionGroup[] = [];

        for (let index = 0; index < locations.length; index++) {
            const location = locations[index];
            
            if (!groups[location.nivel]) {
                groups[location.nivel] = new TerritorialDivisionGroup(location.nivel, [], this.getLocationDisplayNameFromLevel(location.nivel));
            }

            groups[location.nivel].values.push(location);
        }

        var keys = Object.keys(groups);

        for (let index = 0; index < keys.length; index++) {
            result.push(groups[keys[index]]);           
        }

        return result;
    };

    getLocationDisplayNameFromLevel(level: number) {
        return level == 1? this.countryText : (level == 2? this.stateText : this.cityText);
    }

    @HostListener('document:click', ['$event'])
    clickout(event : any) {
        let elResults = document.getElementsByClassName("search-doctor-search-results")[0];        
        let elSearchBox = document.getElementsByClassName("search-doctors-container")[0];        

        if(elResults && !elResults.contains(event.target) && !elSearchBox.contains(event.target))
            this.resultWrapper.hide();
    }

    onSearchInputClick() {
        let containerClass = this.useSearchEvents? "body-container" : "mat-sidenav-content";

        let container = this.document.getElementsByClassName(containerClass)[0];
        let el = this.document.getElementsByClassName("left-box")[0];

        this.utilsService.scrollToElementWithAnimation(container, el);

        this.resultWrapper.show();
    }   

    onSearch(search : string) {
        if (this.searchTimeout)
            clearTimeout(this.searchTimeout as number);

        if (this.disabledSearch)
            return;

        this.searchTimeout = setTimeout(() => {
            this.doSearch(search);
        }, 300) as unknown as number;
    }
  
    doSearch(search : string) {
        if (!search || !search.trim())
            return;

        search = search.trim();

        // If the autocomplete is not enabled then does not search.
        // It will only search when user press the search btn, and the results will be shown on the search-results component
        if(!this.isAutoCompleteEnabled())
            return;

        this.searchValue = search;

        this.doctorService.searchDoctors(this.locale.language, search, this.companyName, this.profileSearchTypes).subscribe(results =>{           
            
            // Clear previous results
            this.resultWrapper.clear();

            this.clinicalConcept = null as any;
            
            // Add new items
            results.forEach(result => {
                this.resultWrapper.addItem(result);
            });                   
          },
          error => {
            this.baseService.handleServiceError(error, "Error getting profiles");            
          });
    }

    wrappersTrackBy(index : number, item: DoctorSearchResultUIWrapperGroup){
        return item.classId; 
    }

    resultsTrackBy(index : number, item: DoctorSearchResult){
        return item.id; 
    }

    territorialDivisionsTrackBy(index : number, item: TerritorialDivision){
        return item.id; 
    }

    setSelectedClinicalConcept(item: DoctorSearchResult, trigger : MatAutocompleteTrigger) {        
        
        this.clinicalConcept = item;                

        // Disabled search for 1 second so it does not fire after we change the search input text
        this.disableSearch(1000);

        // Set concept name to search input
        this.searchDoctorsForm.controls["searchText"].setValue(item.nombre);        

        this.resultWrapper.hide();

        if (!this.selectedCity)
            // Open city panel
            trigger.openPanel();       
        else 
            this.navigateToSearchResult(item);
    }

    disableSearch(time: number) {
        this.disabledSearch = true;

        if (time)
            setTimeout(() => {
                this.disabledSearch = false;
            }, time);    
    }

    // Concept search
    navigateToSearchResult(item: DoctorSearchResult) {
        
        let params = new SearchResultsQsParameters();

        params.idTc = item.claseId;
        params.idC = item.id;
        params.nav = item.url;
        params.idP = this.selectedCity? this.selectedCity.id : 0;
        params.idDt = this.selectedCity? this.selectedCity.identificador : "";
        params.t = item.nombre;        
        params.tp = this.profileType;       
        params.ds = this.onlyDoctorsProvider;
        params.rUrl = this.router.url;

        this.resultWrapper.hide();

        if (this.useSearchEvents)
            this.fireSearchEvent(params);
        else
            this.router.navigate(['/busqueda'], { queryParams: params });
    }
    
    // Text search
    onSearchClick() {
        let searchText = this.searchDoctorsForm.controls["searchText"].value;
        
        if (searchText)
            searchText = searchText.trim();

        // If the user click search but before a clinical concept was selected let just do a concept search
        if (this.clinicalConcept) {
            this.navigateToSearchResult(this.clinicalConcept);
            return;
        }            

        let params = new SearchResultsQsParameters();

        params.idTc = 0;
        params.idC = 0;
        params.nav = "";
        params.idP = this.selectedCity? this.selectedCity.id : 0;
        params.idDt = this.selectedCity? this.selectedCity.identificador : "";
        params.t = searchText;        
        params.tp = this.profileType;   
        params.ds = this.onlyDoctorsProvider;
        params.rUrl = this.router.url;     

        this.resultWrapper.hide();

        if (this.useSearchEvents)
            this.fireSearchEvent(params);
        else
            this.router.navigate(['/busqueda'], { queryParams: params });
    }

    fireSearchEvent(params: SearchResultsQsParameters) {
        // Fire a new search event for all the suscribers
        var message = new ProfileSearchMessage();
        message.profileType = this.profileType;
        message.searchText = params.nav? "" : params.t;
        message.conceptNav = params.nav;
        message.doctorSearch = params.ds; 
        message.conceptId = params.idC;
        message.conceptClaseId = params.idTc;      

        if (params.idDt) {            
            message.territorialDivisionIdentification = params.idDt;            
        }

        this.messageBusService.profileSearch(message)
    }

    onDoctorClick(item: DoctorSearchResult) {
        let url = this.publicProfileService.getPublicProfileUrl(item.url);

        this.router.navigate([url]); 
    }

    getPlaceholderText() {
        return this.placeholderTexts[this.profileType - 1];
    }

    getDisplayColumns() {
        if (this.profileType == PublicProfileType.Doctor)
            return ['name', 'specialty', 'city'];
        else 
            return ['name', 'city'];
    }

    areDoctorsResults() {
        return this.profileType == PublicProfileType.Doctor;
    }

    isAutoCompleteEnabled() {
        return this.profileType == PublicProfileType.Doctor
            || this.profileType == PublicProfileType.CentroDeImagenes;            
    }

    private parseControlData() {
        if (!this.data)
            this.data = this.parentData;

        if (this.data && this.data.valor) {
          try {
            this.data.valor = JSON.parse(this.data.valor);       
    
            let data = <SearchWidgetConfigurationDataModel>this.data.valor;
    
            this.onlyDoctorsProvider = data.showOnlyDoctors;
          } catch (ex) {}      
        }    
      }
}

export class DoctorSearchResultUIWrapper {
    companyId: number = 0;
    groups: DoctorSearchResultUIWrapperGroup[];
    hidden: boolean = false;
    searchDone: boolean = false;
    headquartersCompIds: number[] = [];
    
    constructor(companyId: number, headquartersCompIds: number[]) {
        this.groups = [];
        this.companyId = companyId;
        this.headquartersCompIds = headquartersCompIds;
    }

    clear() {
        this.groups = [];
        this.hidden = false;        
    }

    isEmpty() {
        return this.groups.length == 0;
    }

    hide() {
        this.hidden = true;
    }

    show() {
        this.hidden = false;
    }

    isHidden() {
        return this.hidden;
    }

    addItem(item : DoctorSearchResult) {
        let relatedComp = item.empresasRelacionadas.split(",").map(id => parseInt(id))
        let classId = item.claseId;                
        
        this.searchDone = true;

        // Current directory doctos
        //if (classId == 0 && this.companyId > 0 && item.idEmpresa == this.companyId) {
        if (classId == 0 && this.companyId > 0 && this.containsAnyNumber(relatedComp, this.headquartersCompIds)) {
            classId = -2;
        }
        else if (classId == 0) {
            classId = -1;
        }

        let wrapper = this.groups.find(w => w.classId == classId);

        if (wrapper == null) {
            wrapper = new DoctorSearchResultUIWrapperGroup();

            wrapper.classId = classId;
            wrapper.className = item.clase;
            wrapper.results = [];

            // Push new wrapper
            this.groups.push(wrapper);
        }

        // Push new wapper item
        wrapper.results.push(item);
    }

    getClassItems() {
        return this.groups.filter(g => g.isClassGroup());
    }

    getDirectoryItems() {       
        return this.groups.filter(g => g.isDirectoryGroup());
    }

    getDoctorItems() {
        return this.groups.filter(g => g.isDoctorGroup());
    }

    containsAnyNumber(arrayNumber: number[], valueArray: number[]) {
        if (!arrayNumber)
            return false;
    
        return arrayNumber.filter(elem => valueArray.indexOf(elem) > -1).length > 0;
    }
}

export class DoctorSearchResultUIWrapperGroup {
    classId: number;
    className: string;
    results: DoctorSearchResult[]

    isClassGroup() {
        return this.classId > 0;
    }

    isDoctorGroup() {
        return this.classId == -1;
    }

    isDirectoryGroup() {
        return this.classId == -2;
    }
}

export class TerritorialDivisionGroup {
    key: number;
    values: TerritorialDivision[];
    displayName: string;    

    constructor(key: number, values :TerritorialDivision[], displayName: string) {
        this.key = key;
        this.values = values;
        this.displayName = displayName;
    }
}

export class SearchResultsQsParameters {    
    idTc: number;   // Id Tipo Concepto
    idC: number;    // Id Concepto
    nav: string;    // URL navegacion
    idP: number;    // Id Pais
    idDt: string;   // Id Division territorial
    t: string;      // Texto busqueda
    tp: number;     // Tipo Perfil Publico (Medico, Farmacia, Clinica, etc)
    ds: boolean;    // Doctor Search (this will be false when navigating from the Home menu)
    hideHeader: string;
    rUrl: string; //Return url
}

export interface SearchWidgetConfigurationDataModel {
    showOnlyDoctors: boolean;
}