import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-workflow-progress-bar',
  templateUrl: './workflow-progress-bar.component.html',
  styleUrls: ['./workflow-progress-bar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowProgressBarComponent implements OnInit {

  @Input() model: WorkflowProgressBarComponentData;
  returnUrl = '';

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => this.returnUrl = params['returnUrl']);
  }

  onClick(): void {
    if (this.returnUrl) {
      this.router.navigateByUrl(this.returnUrl);
    } else {
      this.model.backFn();
    }
  }

}

export class WorkflowProgressBarComponentData {
  show: boolean;
  showButtonBack: boolean;
  showButtonClose: boolean;
  showProgressBar: boolean;
  percentComplete: number = 0;
  backFn: any;
  closeFn: any;
}