import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import { BaseService } from 'src/app/core/services/base.service';
import { GoogleAnalyticsService } from 'src/app/core/services/google-analytics.service';
import { MessageBusService, ServiceErrorMessage } from 'src/app/core/services/message-bus.service';
import { UserService } from 'src/app/core/services/user.service';
import { Policy } from 'src/app/shared/models/people/policy.model';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';

@Component({
  selector: 'app-policy-menu-content',
  templateUrl: './policy-menu-content.component.html',
  styleUrls: ['./policy-menu-content.component.css']
})

export class PolicyMenuContentComponent implements OnInit, OnDestroy {  
  private ngUnsubscribe = new Subject();
  user: UserPerson;
  policies : Policy[] = [];
  cedula: string;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private baseService: BaseService,
    private userService: UserService,
    private messageBusService: MessageBusService,
    private authService: AuthService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      let tipoIdentificacionPoliza = this.baseService.getDirectory().tipoIdentificacionPoliza;      

      tipoIdentificacionPoliza && this.getPolicies(tipoIdentificacionPoliza);        

      // Get Cedula
      this.userService.getUserIdentificationNumber()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(i => {
        if (i && i.length > 0)
          this.cedula = i[0].valor;          
      });
    }
    
    // On SSO when the ngOnInit the user is not yet logged in, so for those cases we will subscribe to this event
    this.messageBusService.onLoginCompleted()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {      
      if (this.policies.length == 0) {
        let tipoIdentificacionPoliza = this.baseService.getDirectory().tipoIdentificacionPoliza;   

        tipoIdentificacionPoliza && this.getPolicies(tipoIdentificacionPoliza);        
      }        
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getPolicies(tipoIdentificacionPoliza: number){
    this.userService.getPolicies(tipoIdentificacionPoliza)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(policies => {
      this.policies = policies;     
    },
    error => {
      this.baseService.handleServiceError(error, "Error getting user policies");            
    });
  }

  policyTrackBy(index : number, item: Policy){
    return item.numeroPoliza; 
  }

  goToPolicy(policyNumber: string) {
    if(isPlatformBrowser(this.platformId)){
      this.googleAnalyticsService.sendEventGoToInsuranceCard();

      let url = this.baseService.getDirectory().urlCorporativo;
      
      url += "micarnet?identificacion=" + this.cedula;
  
      window.open(url, '_blank')?.focus();
    }
  }
}
