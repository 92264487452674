import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { Token } from 'src/app/shared/models/token.model';
import { environment } from 'src/environments/environment';

export const STORAGE: any = {
  VERSION: 'v',
  TOKEN: 'auth-token',
  DIRECTORY: 'directory',
  MENU: 'menu',
  LOCALE: 'locale',
  REFERRER: 'referrer',
  PROFILE_SEACH_SEED: 'profile-search-seed',
  USER: 'user',
  AUTH_IFRAME: 'auth_iframe',
  ACTIVE_VIRTUAL_ATTENTION: 'active_virtual_attention',
  MENU_LANGUAGE: 'menu_language',
  POLICIES: "policies",
  CLINIWEB_DIRECTORY: 'cliniweb_directory_',
  WORKFLOW_TYPE: 'workflow_type',
  ONLINE_APPOINTMENT_CLINIWEB_STATE: 'online_appointment_cliniweb_state',
  ONLINE_APPOINTMENT_CLINIWEB_BOOTSTRAP_STATE: 'online_appointment_cliniweb_bootstrap_state',
  WELCOME_CLINIWEB_STATE: 'welcome_cliniweb_state',
  ONLINE_PAYMENT_WORKFLOW_STATE: 'online_payment_workflow_state',
  MEDICAL_ORDER_WORKFLOW_STATE: 'medical_order_workflow_state',
  GROWTH_CURVES_WORKFLOW_STATE: 'growth_curves_workflow_state',
  ONLINE_PAYMENT_WORKFLOW_BOOTSTRAP_STATE: 'online_payment_workflow_bootstrap_state',
  SIGNUP_CAPTCHA_REQUIRED: 'signup_catpcha_required',
  SIGNUP_WORKFLOW_VERIFICATION_TYPE: 'signup_workflow_verification_type',
  AUTHENTICATION_SERVICE_PROVIDER: 'authentication_service_provider',
  APPOINTMENTS: 'appointments',
  LICENSE: 'license',
  COUNTRY: 'country',
  NEXT_APPOINTMENT_LAST_TIME_SHOWN: "next_appointment_last_time_shown",
  SIGNUP_CHECKIN_STATE: "signup_checkin_state",
  SELECT_COUNTRY: "select_country"
}

@Injectable({
  providedIn: 'root'
})
export class AppStorageService {

  constructor(
    private cookieService: CookieService,
    private swUpdate: SwUpdate
    ) { } 

  clearAll() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.cookieService.deleteAll("/", undefined);

    this.swUpdate.available.subscribe(() => {
      this.swUpdate.activateUpdate().then(() => {
        console.log('Caché limpiada y Service Worker actualizado.');
        // window.location.reload();
      });
    });
  }

  clearLogout(){
    this.removeSessionItem(STORAGE.USER);
    this.removeSessionItem(STORAGE.SIGNUP_CAPTCHA_REQUIRED);
    this.removeSessionItem(STORAGE.TOKEN);
    this.removeSessionItem(STORAGE.AUTHENTICATION_SERVICE_PROVIDER);
    this.removeSessionItem(STORAGE.APPOINTMENTS);
    this.removeSessionItem(STORAGE.SIGNUP_CHECKIN_STATE);
    this.removeSessionItem(STORAGE.SELECT_COUNTRY);
    window.localStorage.removeItem(STORAGE.COUNTRY);

    this.cookieService.deleteAll("/", undefined);
  }
  
  public setItem(key: string, data: any, encrypt?: boolean){
    window.localStorage.removeItem(key);
    window.localStorage.setItem(
      key, 
      encrypt
        ? this.encryptObj(JSON.stringify(data))
        : JSON.stringify(data));
  }

  public setItemSession(key: string, data: any, encrypt?: boolean){
    window.sessionStorage.removeItem(key);
    window.sessionStorage.setItem(
      key,
      encrypt
        ? this.encryptObj(JSON.stringify(data))
        : JSON.stringify(data));
  }

  public removeSessionItem(key: string) {
    window.sessionStorage.removeItem(key);
  }

  public getItem(key: string, desencrypt?:boolean){
    const data = window.localStorage.getItem(key);

    return data 
      ? JSON.parse(desencrypt
        ? this.decryptObj(data)
        : data)
      : null;
  }

  public getItemSession(key: string, desencrypt?:boolean){
    const data = window.sessionStorage.getItem(key);

    return data 
      ? JSON.parse(desencrypt
        ? this.decryptObj(data)
        : data)
      : null;
  }

  public getSerializedToken():string{
    const token = this.cookieService.get(STORAGE.TOKEN);
    return JSON.stringify(this.decryptObj(token));
  }

  public setSerializedToken(token:string){

    const tokenObject:Token = this.parseObjectDeep<Token>(JSON.parse(token));

    this.cookieService.set(STORAGE.TOKEN, this.encryptObj(tokenObject), 2, '/', undefined, false, "None");
    this.setItemSession(STORAGE.TOKEN, '_');
  }

  public getToken(): Token {
    const token = this.cookieService.get(STORAGE.TOKEN);
    return token ? this.decryptObj(token) : null;
  }

  public setToken(token: Token) {
    this.cookieService.set(STORAGE.TOKEN, this.encryptObj(token), 2, '/', undefined, false, "None");
    this.setItemSession(STORAGE.TOKEN, '_');
  }

  public removeToken(){
    this.cookieService.delete(STORAGE.TOKEN, '/', undefined, false, "None");
    this.removeSessionItem(STORAGE.TOKEN);
  }

  private encryptObj(obj: any): string {
    const _key = CryptoJS.enc.Utf8.parse(environment.cKey);
    const _iv = CryptoJS.enc.Utf8.parse(environment.cKey);

    return CryptoJS.AES.encrypt(
        JSON.stringify(obj),
        _key,
        {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString();
  }

  private decryptObj(text: string): any {
    const _key = CryptoJS.enc.Utf8.parse(environment.cKey);
    const _iv = CryptoJS.enc.Utf8.parse(environment.cKey);
    const decryptedText = CryptoJS.AES.decrypt(
      text,
      _key,
      {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);

    return JSON.parse(decryptedText);
  }


  // private getCookieDomain(){
  //   return window.location.href.includes('.cliniweb.com') ? '.cliniweb.com' : undefined;
  // }

  private parseObjectDeep<T>(json : any) : T {
    let result = {} as any;
    var keys = Object.keys(json);

    for(let i=0; i < keys.length; i++) {
      let camelCasePropName = keys[i].charAt(0).toLowerCase() + keys[i].slice(1);
      result[camelCasePropName] = json[keys[i]];
    }   

    return <T>result as T;
  }
}