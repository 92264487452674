<div class="summary-order-sign-container">
    <div class="doctor-sign">
        <img [src]="sign | safeUrl" (load)="imgLoad($event)" (error)="imgError($event)" />
        <div class="title">
            <span class="bold">{{nombreResponsableServicio}}</span>
            <span class="bold">{{nombreEspecialidad}}</span>
            <span class="bold">{{registro}}</span>
            <div *ngIf="esProfesionalVerificado" class="profesional-verificado">
                <img src="https://s3.amazonaws.com/docs.public/images/iconos/Frame.png" /><span>&nbsp;PROFESIONAL VERIFICADO</span>
            </div>
        </div>
    </div>
</div>