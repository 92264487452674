import { Component, ChangeDetectorRef, Inject, OnDestroy, OnInit, ViewEncapsulation, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { MenuMobileComponent } from '../../dialogs/menu-mobile/menu-mobile.component';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { MessageBusService, PublicProfileActionMessage } from 'src/app/core/services/message-bus.service';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ContainerType } from 'src/app/shared/enums/container-type.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { RouteDialogService } from 'src/app/core/services/route-dialog.service';
import { environment } from 'src/environments/environment';
import { PublicProfile } from 'src/app/shared/models/people/public-profile.model';
import { PublicProfileAvatarComponentModel } from '../../public-profile-avatar/public-profile-avatar.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('HeaderComponent')
export class HeaderComponent implements OnInit, OnDestroy, IControlComponent {
  data: FormComponentData;

  routeDialogOpened: boolean = false;
  isPublicProfilePage: boolean = false;
  isSearchProfilePage: boolean = false;
  loggedInUser: UserPerson = null as any;
  floatingComponents: FormComponentData[] = [];
  language: string;
  publicProfileMenuModel: HeaderComponentPublicProfileMenuModel;

  private ngUnsubscribe = new Subject();

  constructor(
    private routeDialogService: RouteDialogService,
    private messageBusService: MessageBusService,
    private router: Router,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnInit(): void {

    this.isPublicProfilePage = this.router.url.includes('perfil');
    this.isSearchProfilePage = this.router.url.includes('doctores');

    this.router.events
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(event=>{
      if(event instanceof NavigationEnd){
        if(this.routeDialogService.anyOpen())
          return;        

        this.isPublicProfilePage = this.router.url.includes('perfil');
        this.isSearchProfilePage = this.router.url.includes('doctores');


        this.language = this.locale.language;
      }
    });

    this.messageBusService.onSendFloatingComponents()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=>{

      if(this.routeDialogService.anyOpen())
        return;

      this.floatingComponents = message.componentsData.filter(c=>c.contenedor == ContainerType.HEADER);
    });

    this.messageBusService.onOpenRouteDialog()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=>{

      this.routeDialogOpened = true;
    });

    this.messageBusService.onCloseRouteDialog()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=>{
      this.routeDialogOpened = false;
    });

    this.messageBusService.onAllRouteDialogClosed()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=>{
      this.routeDialogOpened = false;
    });

    this.messageBusService.onPublicProfileHeaderVisibilityChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((message)=> {
      if (message.fromRouteDialog)
        return;

      this.publicProfileMenuModel = new HeaderComponentPublicProfileMenuModel();
      this.publicProfileMenuModel.headerVisible = message.headerVisible;
      this.publicProfileMenuModel.profile = message.profile;
      this.publicProfileMenuModel.avatarModel = message.publicProfileAvatarComponentModel;
      this.publicProfileMenuModel.avatarModel.useAvatarPadding = false;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openMenuMobile(){
    const dialogRef = this.dialog.open(MenuMobileComponent, {
      panelClass: "menu-mobile"
    });
  }

  onLogoClick() {
    let url = environment.cliniwebUrl;

    if (this.loggedInUser)
      this.router.navigate(['/']);
    else if(isPlatformBrowser(this.platformId))
      window.location.href = url;
  }

  setLoggedInUser(arg: UserPerson | null): void {
    this.loggedInUser = arg as any;
    this.cd.detectChanges();
  }

  gotoPayment() {
    this.messageBusService.sendPublicProfileAction(new PublicProfileActionMessage(true, false));
  }

  onAppointmentBtnClick() {
    this.messageBusService.sendPublicProfileAction(new PublicProfileActionMessage(false, true));
  }
}

export class HeaderComponentPublicProfileMenuModel {
    headerVisible: boolean;
    profile: PublicProfile;
    avatarModel: PublicProfileAvatarComponentModel;
}
