import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from "@angular/common";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';
import { MenuMobileComponent } from '../../dialogs/menu-mobile/menu-mobile.component';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-header-pacifica-salud-phr',
  templateUrl: './header-pacifica-salud-phr.component.html',
  styleUrls: ['./header-pacifica-salud-phr.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('HeaderPacificaSaludPhrComponent')
export class HeaderPacificaSaludPhrComponent implements OnInit, IControlComponent {
  data: FormComponentData;

  routeClassName: string = '';
  isLicensedCompany: boolean = true;
  loading: boolean = true;
  
  constructor(
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    private baseService: BaseService) {

    this.router.events.subscribe(()=>{
      this.initRouteClassName();
    });
  }

  ngOnInit(): void {
    this.baseService.isLicensedCompany().subscribe(hasLicenses => {
      this.isLicensedCompany = hasLicenses;

      this.loading = false;
    });
  }
  
  openMenuMobile(){
    const dialogRef = this.dialog.open(MenuMobileComponent, {
      panelClass: 'menu-mobile',
      width:"338px",
      height: "100%",
      position: { left: "0px"}
    });
  }

  navigateToCompany(){
    this.router.navigate([this.baseService.getUrlCompany()]);
  }

  gotoBack(){
    this.location.back();
  }

  private initRouteClassName(){
    this.routeClassName = this.router.url.split('/')[1];
  }
}
