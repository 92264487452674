import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { L10N_LOCALE, L10nLocale } from "angular-l10n";
import { GrowthMeasurementsService } from "src/app/core/services/growth-measurements.service";
import { ChartType, GrowthMeasurementRange, GrowthMeasurementUnitType } from "src/app/shared/models/clinic-history/growth-measurement-type.model";
import { GrowthMeasurement } from "src/app/shared/models/clinic-history/growth-measurements.model";

@Component({
  selector: 'app-growth-charts-tab-table-type',
  templateUrl: './growth-charts-tab-table-type.component.html',
  styleUrls: ['./growth-charts-tab-table-type.component.css']
})
export class GrowthChartsTabTableTypeComponent implements OnInit {

    @Input() loading = true;
    @Input() measurements: GrowthMeasurement[];
    @Input() range = new GrowthMeasurementRange;
    @Input() type: ChartType;
    measurementsForRange: MatTableDataSource<{[key: string]: string}> = new MatTableDataSource([{}]);
    headers: string[] = [];

    constructor(@Inject(L10N_LOCALE) public locale: L10nLocale, private growthMeasurementsService: GrowthMeasurementsService) {}

    ngOnInit(): void {
        const measurementsForRange = this.measurements
          .filter(obj => this.filterMeasurements(obj))
          .map(this.mapMeasurements.bind(this));
        this.measurementsForRange.data = measurementsForRange;
    }
    private filterMeasurements(obj: GrowthMeasurement): boolean {
      if (this.type == ChartType.WEIGHT_FOR_SIZE) {
        return true;
      }
      let edadTotal = this.getDecimalAge(obj.edadAnios, obj.edadMeses, obj.edadDias, this.range.codigoUnidadMedida);
      return edadTotal >= this.range.rangoInicial && edadTotal < this.range.rangoFinal;
    }
    private mapMeasurements(obj: GrowthMeasurement): {[key: string]: string} {
      const json = JSON.parse(obj.jsonHistoriaClinicaActual);
      const data = json && json[this.growthMeasurementsService.getHcaFieldByType(this.type)];
      const anios = obj.edadAnios ? `${obj.edadAnios} año${obj.edadAnios != 1 ? 's ' : ' '} ` : '';
      const meses = obj.edadMeses ? `${obj.edadMeses} mes${obj.edadMeses != 1 ? 'es ' : ' '}`: '';
      const dias = obj.edadDias ? `${obj.edadDias} día${obj.edadDias != 1 ? 's ' : ' '}`: '';
      const value = this.getAxisYByType(obj);
      const objeto: {[key: string]: string} = {};

      objeto[this.headers[0]] = this.type == ChartType.WEIGHT_FOR_SIZE ? `${obj.valorTallaCm} ${json.TallaEdad.Unidad}` : `${anios}${meses}${dias}`;
      objeto[this.headers[1]] = `${value} ${data.Unidad}`;
      objeto[this.headers[2]] = '';

      return objeto;
    }
    private getDecimalAge(yearAge:number, monthsAge: number, daysAge: number, unitType: GrowthMeasurementUnitType):number{
        switch (unitType) {
          case GrowthMeasurementUnitType.YEARS:
            return yearAge + (monthsAge / 12) + (daysAge / 365.25); // 365.25 para años bisiestos
          case GrowthMeasurementUnitType.MONTHS:
            return (yearAge * 12) + monthsAge + (daysAge / 30.44); // 30.44 para promedio mensual
          case GrowthMeasurementUnitType.WEEKS:
            return (yearAge * 52) + (monthsAge * 4.34524) + (daysAge / 7); // 4.34524 promedio de semanas por mes
          default:
              return 0;
        }
      }

    private getAxisYByType(hca:any):number{
      switch (this.type) {
        case ChartType.SIZE_FOR_AGE:
        default:
          this.headers = ['age', 'size', 'empty']
            return hca.valorTallaCm;
        case ChartType.WEIGHT_FOR_AGE:
          this.headers = ['age', 'weight', 'empty']
            return hca.valorPesoKg;
        case ChartType.BMI_FOR_AGE:
          this.headers = ['age', 'imc', 'empty']
            return hca.valorIMC;
        case ChartType.WEIGHT_FOR_SIZE:
          this.headers = ['size', 'weight', 'empty']
            return hca.valorPesoKg;
        case ChartType.HEAD_CIRCUMFERENCE_FOR_AGE:
          this.headers = ['age', 'circunferenceHidro', 'empty'];
            return hca.valorCircunferenciaCefalicaCm;
      }
    }

}