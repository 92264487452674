import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
  export class GoogleMapsLoaderService {
    private static promise: Promise<void>;
  
    constructor() {}
  
    load(): Promise<void> {
      if (!GoogleMapsLoaderService.promise) {
        GoogleMapsLoaderService.promise = new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}`;
          script.async = true;
          script.defer = true;
  
          script.onload = () => {
            resolve();
          };
  
          script.onerror = (error: any) => {
            reject(error);
          };
  
          document.head.appendChild(script);
        });
      }
  
      return GoogleMapsLoaderService.promise;
    }
  }