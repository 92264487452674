import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { concatMap, delay, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { LoginCompletedMessage, LogoutMessage, MessageBusService } from 'src/app/core/services/message-bus.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { BaseService } from 'src/app/core/services/base.service';
import { PHRVersion, PortalPhr } from 'src/app/shared/models/systems/portal-phr.model';

@Component({
  selector: 'app-anonymous-menu',
  templateUrl: './anonymous-menu.component.html',
  styleUrls: ['./anonymous-menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AnonymousMenuComponent implements OnDestroy, OnInit {

  @Output() onLoggedInUser: EventEmitter<UserPerson | null> = new EventEmitter();
  loggedInUser: UserPerson | null = null;
  showAnonymousMenu: boolean = false;
  showLoggedInMenu = false;
  private ngUnsubscribe = new Subject();
  directory: PortalPhr;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private messageBusService: MessageBusService,
    private userService: UserService,
    private baseService: BaseService
  ) {
    this.messageBusService.onLoginCompleted().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onLoginCompleted.bind(this));
    this.messageBusService.onLogout().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onLogout.bind(this));
    this.messageBusService.onRefreshProfileMenu().pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.loadProfile.bind(this));
    this.messageBusService.onShowSignupLoginHeaderMenu()
      .pipe(takeUntil(this.ngUnsubscribe), concatMap(x => of(x).pipe(delay(50))))
      .subscribe(x => this.showLoggedInMenu = x);
    /*this.showAnonymousMenu = this.messageBusService.onHideAnonymousMenu()
      .pipe(takeUntil(this.ngUnsubscribe), map(() => false), startWith(true), withLatestFrom(onShowSignupLoginHeaderMenu), map(x => x[0] && x[1]), delay(100));*/
  }

  private onLoginCompleted(msg: LoginCompletedMessage) : void {
    if (msg.user) {
      this.loadProfile();
    }
  }

  private onLogout(_: LogoutMessage): void {
    this.loggedInUser = null;
    this.onLoggedInUser.emit(null);
    this.document.body.classList.add('anonymous');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.loadProfile();

    this.messageBusService.onHideAnonymousMenu()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(m => {
        this.showAnonymousMenu = false;
      });
    
    this.messageBusService.onShowAnonymousMenu()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(m => {
        if (this.directory.versionPhr != PHRVersion.V1)
          this.showAnonymousMenu = true;
      });

    this.directory = this.baseService.getDirectory();
    this.showAnonymousMenu = this.directory.versionPhr != PHRVersion.V1;
  }

  loadProfile() {
    this.authService.isLoggedInFull()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((result:boolean)=>{

      if(result){
        this.userService.getUserPersonLoggedIn().subscribe(this.isLoggedIn.bind(this));
      }
      else{
        this.document.body.classList.add('anonymous');
      }
    });
  }

  private isLoggedIn(arg: UserPerson | null): void {
    if (!arg) return;
    this.loggedInUser = arg;
    this.onLoggedInUser.emit(arg);
    this.document.body.classList.remove('anonymous');
  }

}
