import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AttentionGroupItem, AttentionHistory } from 'src/app/shared/models/clinic-history/attention-history.model';
import { ClinicHistory } from 'src/app/shared/models/clinic-history/clinic-history.model';
import { environment } from 'src/environments/environment';
import { HttpBaseService } from './http-base-service';
import { UtilsService } from './utils.service';
import { ClinicHistoryHashed } from 'src/app/shared/models/clinic-history/clinic-history-hashed.model';

@Injectable({
  providedIn: 'root'
})
export class ClinicHistoryService {

  constructor(
    private http: HttpBaseService,
    private utilsService: UtilsService
  ) { }

  getClinicHistory(idPaciente:number = -1, idResponsableServicio: number = -1):Observable<ClinicHistory>{

    let url = "api/historias-clinicas";

    url += "?idPaciente=" + idPaciente;
    url += "&idResponsableServicio=" + idResponsableServicio;

    return this.http.get<ClinicHistory>(url);
  }

  getAttentionsByGroup(attentionHistoryArray: AttentionHistory[], idAgrupador: number): AttentionHistory[] {
    let attentionsFiltered: AttentionHistory[] = [];

    attentionHistoryArray.forEach(attentionHistory => {
      let _atenciones = attentionHistory.atenciones.filter(a => a.agrupadores.indexOf(idAgrupador) >= 0);

      if(_atenciones.length > 0){

        let _attentionHistory = new AttentionHistory();

        _attentionHistory.contactoClinico = attentionHistory.contactoClinico;
        _attentionHistory.atenciones = _atenciones;

        attentionsFiltered.push(_attentionHistory);
      }
    });

    return attentionsFiltered;
  }

  getClinicHistoryItemsByGroup(items:AttentionGroupItem[], groups: number[]) {   
    return items.filter(i => this.utilsService.containsAnyNumber(i.agrupadores, groups));    
  }
  
  getClinicHistoryItemsByExcludedGroup(items:AttentionGroupItem[], groups: number[]) {
    return items.filter(i => !this.utilsService.containsAnyNumber(i.agrupadores, groups));
  }

  getAttentionsByConceptType(items:AttentionGroupItem[], concepType: number) {
      return items.filter(a => a.idTipoConcepto == concepType);
  }

  getClassPreviewFile(extension: string): string {
    let _className = '';
    switch(extension){
      case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'gif':
        case 'png':
            _className = "image";
            break;
        default:
            break;
    }
    return _className;
  }

  getPreviewFileUrl(extension: string, url: string): string {
    let _imagePreviewUrl = '';
    switch(extension){
      case 'jpg':
        case 'jpeg':
        case 'bmp':
        case 'gif':
        case 'png':
            _imagePreviewUrl = url ? url.replace(/\\/g, '/') : '';
            break;
        case 'pdf':
            _imagePreviewUrl = '/assets/images/HC/img_preview_pdf.png';
            break;
        case 'xls':
        case 'xlsx':
        case 'xlt':
            _imagePreviewUrl = '/assets/images/HC/img_preview_xls.png';
            break;
        case 'doc':
        case 'docx':
            _imagePreviewUrl = '/assets/images/HC/img_preview_doc.png';
            break;
        case 'mp3':
            _imagePreviewUrl = '/assets/images/HC/img_preview_mp3.png';
            break;
        case 'mp4':
            _imagePreviewUrl = '/assets/images/HC/img_preview_mp4.png';
            break;
        default:
            _imagePreviewUrl = '/assets/images/HC/img_preview_default.png';
            break;
    }

    return _imagePreviewUrl;
  }

  getClinicHistoryPdf(model: any): Observable<any> {
    let url = "api/historias-clinicas/pdf";    
    let headers = new HttpHeaders();
    
    headers = headers.set('Accept', 'application/pdf');

    return this.http.post<any>(url, model, { headers: headers, responseType: 'blob' as 'json' });
  }

  getClinicHistoryByHash(id: string): Observable<ClinicHistoryHashed> {
    const url = `api/historias-clinicas/hashed`;
    const params = new HttpParams().set('hashedId', id);

    return this.http.get<ClinicHistoryHashed>(url, { params }, false);
  }

}
