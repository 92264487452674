import { Theme } from "../theme";

export const thepanamaclinicPhrTheme: Theme = {
    name: 'thepanamaclinic-phr',
    properties: {
      '--primary': '#FF7900',
      '--primary2': '#4AADC6',
      '--primary3': '#1080B5',
      '--primary4': '#898A8D', //Este color es nuevo para este PHR.

      //'--brand-color': '#1F4381',
      //'--brand-color2': '#1F4381',
      
      '--variant-color': '#50C2DE',
      //'--variant-color2': '#D6F2FF',
      //'--variant-color3': '#B4E8FF',
      '--variant-color4': '#FD881F',
      //'--variant-color5': '#C5DAE4',
      //'--variant-color6': '#E8F3F9',
      //'--variant-color7': '#55CAD1',
      //'--variant-color8': '#CDFCFF',
      //'--variant-color9': '#FF2D91',
      '--variant-color10': '#1185BC',
      '--variant-color11': '#4FB5CF',

      '--secondary-color': '#FF4C4C',
      '--secondary-color2': '#FFE8E6',
      '--secondary-color3': '#229F1D',
      '--secondary-color4': '#5FCC08',
      '--secondary-color5': '#E7FFD4',
      '--secondary-color6': '#1976D2',
      '--secondary-color7': '#DDF3FF',
      '--secondary-color8': '#FF9900',
      '--secondary-color9': '#FFEDD2',

      '--primary-50-transparent': '#009BDF80',

      '--on-primary': '#fff',
      '--accent': '#404040',
      '--on-accent': '#fff',
      '--accent1': '#a8e1ef33',
      '--error': '#FF4C4C',
      
      '--button-disabled': 'rgba(129, 137, 169, 0.27)',
      '--on-button-disabled': '#8189A9',
      '--hint-color': '#768692',
      '--dialog-text-color': '#53565A',
      '--nav-list-active': 'rgba(236, 240, 241, 0.5)',
      '--border-color-inactive': '#ECF0F1',
      '--box-shadow-color': '#ECF0F1',
      '--background-color-disabled': '#FBFBFB',
      '--font-icon': 'cliniweb-icons',
      '--font-primary': 'Mulish',
      '--font-secundary': 'Mulish',
      '--font-color': '#000',
      '--input-placeholder': '#9E9E9E',
      '--input-placeholder-show': '#505050',
      
      '--grey': '#404040',
      '--grey2': '#DDDDDD',
      '--grey3': '#F2F2F2',
      '--grey4': '#C4C4C4',
      '--grey5': '#757575',
      '--grey6': '#636363',
      '--grey7': '#BEBEBE',
      '--grey8': '#9E9E9E',
      '--grey9': '#505050',
      '--grey10': '#53565A',
      '--grey11': '#768692',      

      '--grayscale': '#1E1E1E',
      '--grayscale2': '#2D2D2D',
      '--grayscale3': '#3C3C3C',
      '--grayscale4': '#505050',
      '--grayscale5': '#646464',
      '--grayscale6': '#787878',
      '--grayscale7': '#8C8C8C',
      '--grayscale8': '#A0A0A0 ',
      '--grayscale9': '#B4B4B4',
      '--grayscale10': '#C8C8C8',
      '--grayscale11': '#DCDCDC',
      '--grayscale12': '#F0F0F0 ',

      '--public-profile-avatar-border-color': '#C8C8C8',
      '--public-profile-title-color': '#505050',
      '--public-profile-btn-appointment-color': '#009BDF',
      '--public-profile-btn-pay-color': '#505050',
      '--favicon-big': '../../assets/images/thepanamaclinic-phr/logo-paitilla.png',
      '--company-icon': 'url(assets/images/logos/paitilla-round.png)',
      '--company-image': 'url(assets/images/thepanamaclinic-phr/logo-paitilla.png)',
      // '--background-img':'url(assets/images/thepanamaclinic-phr/paitilla-background.svg)',
      '--background-color': '#F9F9F9'
    }
};