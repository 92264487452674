import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { of, Subject, zip } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseService } from 'src/app/core/services/base.service';
import { DoctorService } from 'src/app/core/services/doctors.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ClassConcept } from 'src/app/shared/enums/class-concept.enum';
import { DoctorSearchResult } from 'src/app/shared/models/cdr/doctor-search-result.model';
import { PublicProfileType } from 'src/app/shared/models/people/profile-search-results.model';
import { ControlComponentClassnameMessage, MessageBusService, ProviderSearchMessage } from 'src/app/core/services/message-bus.service';
import { TerritorialDivisionsService } from 'src/app/core/services/territorial-divisions.service';
import { TerritorialDivision } from 'src/app/shared/models/systems/territorial-division.model';
import { LayoutService } from 'src/app/core/services/layout.service';
import { PublicProfileService } from 'src/app/core/services/public-profile.service';
import { ConceptDefaultModel } from 'src/app/shared/models/search-results/concept-default.model';
import { LocationDefaultModel } from 'src/app/shared/models/search-results/location-default.model';
import { SearchQsParamMapModel, SearchQsParametersModel } from 'src/app/shared/models/search-results/search-parameters.model';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { PublicProfileAvatarComponentModel } from '../../public-profile-avatar/public-profile-avatar.component';

@Component({
  selector: 'app-search-providers',
  templateUrl: './search-providers.component.html',
  styleUrls: ['./search-providers.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('SearchProvidersComponent')
export class SearchProvidersComponent implements OnInit, OnDestroy, IControlComponent {
  data: any;

  @Input() model: SearchProvidersComponentConfiguration;

  //subscribes
  private ngUnsubscribe = new Subject();

  companyName: string = "";
  language: string;

  autocompleteOpen:boolean = false;
  loadingSearchProvider: boolean = false;
  searchProviderCtrl = new FormControl();
  filteredResults$: Observable<DoctorSearchResultGroup[]>;
  filteredResultsCount:number;
  providerSelected: DoctorSearchResult | null;

  highlightValue: string;

  locationAutocompleteOpen:boolean = false;
  loadingSearchLocation: boolean = false;
  searchLocationCtrl = new FormControl();
  filteredLocationResults$: Observable<LocationSearchResultGroup[]>;
  filteredLocationResultsCount:number;
  locationObjectSelected: LocationSearchResultItem | null;
  locationLevels: string = "2,3";

  cityText:string;
  stateText:string;
  countryText:string;
  cityDefaultText:string;
  stateDefaultText:string;

  removingSelection:boolean = false;

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('searchInput', { read:MatAutocompleteTrigger }) autocompleteProviderTrigger: MatAutocompleteTrigger;

  @ViewChild('searchLocationInput') searchLocationInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoLocation') matAutocompleteLocation: MatAutocomplete;
  @ViewChild('searchLocationInput', { read:MatAutocompleteTrigger }) autocompleteLocationTrigger: MatAutocompleteTrigger;  

  isBrowser: boolean;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private baseService: BaseService,
    private layoutService: LayoutService,
    private utilsService: UtilsService,
    private doctorService: DoctorService, 
    private route: ActivatedRoute,
    private messageBusService: MessageBusService,
    private territorialDivisionsService: TerritorialDivisionsService,
    private publicProfileService: PublicProfileService,
    private translation: L10nTranslationService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.document.body.classList.add('search-provider-v1');

    this.companyName = this.baseService.getCliniwebDirectoryCompanyName();
    this.language = this.baseService.getLanguage();

    this.parseControlData();

    this.initFromParams();

    this.configSearchProviders();

    this.configSearchLocations();
    
    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: () => {
          this.cityText = this.translation.translate('city');
          this.stateText = this.translation.translate('province');
          this.countryText = this.translation.translate('country');
          this.cityDefaultText = this.translation.translate('shared.searchResults.searchProviders.mostWantedCities');
          this.stateDefaultText = this.translation.translate('shared.searchResults.searchProviders.mostWantedProvinces');
      }
    });

    this.sendControlComponentClassname();
  }

  ngOnDestroy():void{
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  initFromParams(){
    let _paramMap$ = this.route.paramMap;
    let _queryParams$ = this.route.queryParamMap;

    zip(_paramMap$, _queryParams$)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(results =>{
      let _paramMap = new SearchQsParamMapModel();

      _paramMap.param1 = results[0].get('param1') || '';
      _paramMap.param2 = results[0].get('param2') || '';
      _paramMap.param3 = results[0].get('param3') || '';
      _paramMap.param4 = results[0].get('param4') || '';
      _paramMap.param5 = results[0].get('param5') || '';

      let _qsParams = new SearchQsParametersModel();
      
      _qsParams.t = results[1].get('t') || '';
      _qsParams.filtros = results[1].get('filtros') || '';

      this.populateDropdownByParams(_paramMap, _qsParams);

    }, error=>{
      console.log('error');
    });
  }

  getPlaceholderProvider(){
    return this.model && this.model.placeholderProvider 
      ? this.layoutService.getTextByLanguage(this.model.placeholderProvider, this.locale.language)
      : '';
  }

  getPlaceholderLocation(){
    return this.model && this.model.placeholderLocation 
      ? this.layoutService.getTextByLanguage(this.model.placeholderLocation, this.locale.language)
      : '';
  }

  private sendControlComponentClassname(){
    let message = new ControlComponentClassnameMessage();

    message.conponentName = 'SearchProvidersComponent';
    message.classname = 'search-provider-v1';

    this.messageBusService.controlComponentClassname(message);
  }
  
  private parseControlData(){
    if(this.data && this.data.valor){
      try {
        this.model = new SearchProvidersComponentConfiguration(JSON.parse(this.data.valor));
      } catch (ex) {}   
    }    
  }

  private populateDropdownByParams(paramMap:SearchQsParamMapModel, qsParams: SearchQsParametersModel){
    if(paramMap.param1){
      let _locationObjectSelected =  new LocationSearchResultItem();

      if(paramMap.param1 == "texto"){
        this.searchProviderCtrl.setValue({ nombre: qsParams.t });

        // carga de dropdown localidad
        if(paramMap.param4){
          _locationObjectSelected.url = `${paramMap.param2}/${paramMap.param3}/${paramMap.param4}`;
          _locationObjectSelected.nombre = paramMap.param4.replace(/-/g, ' ');
        }
        else if(paramMap.param3){
          _locationObjectSelected.url = `${paramMap.param2}/${paramMap.param3}`;
          _locationObjectSelected.nombre = paramMap.param3.replace(/-/g, ' ');
        }
        else if(paramMap.param2){
          _locationObjectSelected.url = `${paramMap.param2}`;
          _locationObjectSelected.nombre = paramMap.param2.replace(/-/g, ' ');
        }
      }
      // caso de que se selecciono localidad y el dropdown de proveedores no.
      else if(paramMap.param1 == "todos"){
        if(paramMap.param4){
          _locationObjectSelected.url = `${paramMap.param2}/${paramMap.param3}/${paramMap.param4}`;
          _locationObjectSelected.nombre = paramMap.param4.replace(/-/g, ' ');
        }
        else if(paramMap.param3){
          _locationObjectSelected.url = `${paramMap.param2}/${paramMap.param3}`;
          _locationObjectSelected.nombre = paramMap.param3.replace(/-/g, ' ');
        }
        else{
          _locationObjectSelected.url = `${paramMap.param2}`;
          _locationObjectSelected.nombre = paramMap.param2.replace(/-/g, ' ');
        }          
      }
      else {
        this.providerSelected = new DoctorSearchResult();
        this.providerSelected.nombre = paramMap.param2.replace(/-/g, ' ');
        this.providerSelected.url = `/${paramMap.param1}/${paramMap.param2}`;

        this.searchProviderCtrl.setValue(this.providerSelected);

        // carga de dropdown localidad
        if(paramMap.param5){
          _locationObjectSelected.url = `${paramMap.param3}/${paramMap.param4}/${paramMap.param5}`;
          _locationObjectSelected.nombre = paramMap.param5.replace(/-/g, ' ');
        }
        else if(paramMap.param4) {
          _locationObjectSelected.url = `${paramMap.param3}/${paramMap.param4}`;
          _locationObjectSelected.nombre = paramMap.param4.replace(/-/g, ' ');
        }
        else if(paramMap.param3){
          _locationObjectSelected.url = `${paramMap.param3}`;
          _locationObjectSelected.nombre = paramMap.param3.replace(/-/g, ' ');
        }
      }

      if(this.model.locationDefault && qsParams && qsParams.filtros){
        let conceptIds = qsParams.filtros.split(',').map(Number);
        let conceptSelected = this.model.locationDefault.seccion3?.find(c=> conceptIds.includes(c.id));

        if(conceptSelected){
          _locationObjectSelected = new LocationSearchResultItem();
          _locationObjectSelected.id = conceptSelected.id;
          _locationObjectSelected.nombre = this.layoutService.getTextByLanguage(conceptSelected.nombre, this.locale.language);
        }
      }

      if(_locationObjectSelected.nombre){
        this.locationObjectSelected = _locationObjectSelected;
        this.searchLocationCtrl.setValue(this.locationObjectSelected);
      }
    }
  }

  configSearchProviders(){
    this.filteredResults$ = this.searchProviderCtrl.valueChanges
    .pipe(
      tap(()=>{ 
        this.loadingSearchProvider = true;
      }),
      debounceTime(300),
      startWith(''),
      switchMap(value => 
        this.filterProviders(value)
        .pipe(
          map(results => {
            this.loadingSearchProvider = false;

            this.highlightValue = value;

            this.filteredResultsCount = results.length;
            
            return this.groupProviderResults(results);
          })
        )
      )
    );
  }

  configSearchLocations(){
    if(this.model.hideLocationAutocomplete)
      return;

    if(this.model.locationDefault){
      this.locationLevels = this.model.locationDefault.niveles;      
    }

    if(this.model.comboLocation){
      this.filteredLocationResults$ = this.searchLocationCtrl.valueChanges
      .pipe(
        tap(()=>{ 
          this.loadingSearchLocation = true;
        }),
        debounceTime(300),
        startWith(''),
        switchMap(value => {

          let options = this.filterDefaultLocations(value);

          this.loadingSearchLocation = false;
          this.highlightValue = value;
          this.filteredLocationResultsCount = options.length;

          return of(options);
        })
      );
    }
    else{

      this.filteredLocationResults$ = this.searchLocationCtrl.valueChanges
      .pipe(
        tap(()=>{ 
          this.loadingSearchLocation = true;
        }),
        debounceTime(300),
        startWith(''),
        switchMap(value =>
          this.filterLocations(value)
          .pipe(
            map(results => {
              this.loadingSearchLocation = false;

              this.highlightValue = value;

              this.filteredLocationResultsCount = results.length;

              return results;
            })
          )
        )
      );
    }
  }

  filterDefaultLocations(text:string):LocationSearchResultGroup[]{

    let optionsGrouped = this.getLocationSearchGroupByDefault();

    if(text){

      optionsGrouped = optionsGrouped.filter(og => og.results.filter(r => r.nombre.toLowerCase().includes(text.toLowerCase())).length > 0);

      optionsGrouped.forEach(og => {
        og.results = og.results.filter(r => r.nombre.toLowerCase().includes(text.toLowerCase()));
      });

      return optionsGrouped;
    }
    else{
      return optionsGrouped;
    }
  }

  filterProviders(value:string):Observable<DoctorSearchResult[]> {
    if (value)
      value = value.trim();      
    
    if(!this.autocompleteOpen)
      return of([]);

    if((!value || value == '') && this.model.profilesDefault.length > 0){
      return of(this.getProfilesDefault());
    }
    else{
      let _profileSearchTypes = this.model.profileTypes.split(",").map(pt => +pt);

      return this.doctorService.searchDoctors(this.locale.language, value, this.companyName, _profileSearchTypes);
    }
  }

  filterLocations(value:string):Observable<LocationSearchResultGroup[]>{
    if (value)
      value = value.trim(); 

    if(!this.locationAutocompleteOpen)
      return of([]);

    if((!value || value == '') && this.model.locationDefault != null){
      return of(this.getLocationSearchGroupByDefault());
    }
    else{
      return this.getLocationsByLevels(value);
    }
  }

  removeSearchProviderSelected(){
    this.removingSelection = true;

    this.searchProviderCtrl.reset('');
    this.searchInput.nativeElement.value = '';
    this.providerSelected = null;

    if(this.model.profilesDefault.length > 0) {
      setTimeout(()=>{
        this.autocompleteOpen = true;
        this.autocompleteProviderTrigger.openPanel();
        this.autocompleteProviderTrigger._onChange('');
        this.autocompleteProviderTrigger.updatePosition();
      }, 100);
    }

    setTimeout(() => {
      this.removingSelection = false;
    }, 100);

    this.fireSearchEvent();
  }

  removeSearchLocationSelected(){
    this.removingSelection = true;

    this.searchLocationCtrl.reset('');
    this.searchLocationInput.nativeElement.value = '';
    this.locationObjectSelected = null;

    if(this.model.locationDefault != null) {
      setTimeout(()=>{
        this.locationAutocompleteOpen = true;
        this.autocompleteLocationTrigger.openPanel();
        this.autocompleteLocationTrigger._onChange('');
        this.autocompleteLocationTrigger.updatePosition();
      }, 100);
    }

    setTimeout(() => {
      this.removingSelection = false;
    }, 100);

    this.fireSearchEvent();
  }

  groupProviderResults(results: DoctorSearchResult[]): DoctorSearchResultGroup[]{
    let _resultGroup:DoctorSearchResultGroup[] = [];

    let _resultsGrouped = this.utilsService.groupBy<DoctorSearchResult, number>(results, sd => sd.claseId);

    _resultsGrouped.forEach((value: DoctorSearchResult[], key: number)=>{
      let resultGroupItem = new DoctorSearchResultGroup();

      resultGroupItem.classId = key;
      resultGroupItem.className = this.getOptgroupLabelProvider(value[0].clase);
      resultGroupItem.results = value;

      _resultGroup.push(resultGroupItem);
    });

    return _resultGroup;
  }

  getOptgroupLabelProvider(text:string){
    let optgroupFocusAreaText:string = this.translation.translate('shared.searchResults.searchProviders.optgroupFocusArea');

    return text.toLocaleLowerCase() == optgroupFocusAreaText.toLocaleLowerCase() 
      ? this.translation.translate('shared.searchResults.searchProviders.optgroupExpertIn')
      : text;
  }

  profileSelected(event: MatAutocompleteSelectedEvent): void {
    this.loadingSearchProvider = false;

    this.removeClassnameSearchOpenedToBody();

    let _optionSelected:DoctorSearchResult = event.option.value;

    let _clase = _optionSelected.clase.toLowerCase();

    if(_clase == 'doctor' || _clase == 'proveedor'){
      let url = this.publicProfileService.getPublicProfileUrl(_optionSelected.url);
      
      this.publicProfileService.openPublicProfile(url);
    }
    else {
      this.providerSelected = _optionSelected;
      
      if(this.model.hideLocationAutocomplete){
        this.fireSearchEvent();
      }
      else{
        if(!this.isExtremeMobile()){
          setTimeout(()=>{
            this.autocompleteLocationTrigger.openPanel();
            console.log('this.searchLocationInput.nativeElement.focus();')
            this.searchLocationInput.nativeElement.focus();
          }, 100);
        }
  
        if(this.locationObjectSelected){
          this.fireSearchEvent();
        }
      }
    }
  }

  isExtremeMobile() {
      return this.isBrowser && window.innerWidth < 600;
  }

  locationSelected(event: MatAutocompleteSelectedEvent): void {
    this.loadingSearchLocation = false;

    let _optionSelected:LocationSearchResultItem = event.option.value;

    this.locationObjectSelected = _optionSelected;

    this.fireSearchEvent();
    this.closeAutocompleteLocationpanel();
  }

  autoCompleteProviderDisplayWith(item: DoctorSearchResult){
    return item 
      ? item.nombre 
      : "";
  }

  autoCompleteLocationDisplayWith(item: TerritorialDivision){
    return item 
      ? item.nombre 
      : "";
  }

  onKeydownInputSearch($event: KeyboardEvent){
    if($event.key !== 'Enter'){
      this.providerSelected = null;
    }
  }

  onKeydownInputLocationSearch($event: KeyboardEvent){
    if($event.key !== 'Enter'){
      this.locationObjectSelected = null;
    }
  }

  addClassnameSearchOpenedToBody(){
    this.document.body.classList.add('search-v1-opened');
  }

  removeClassnameSearchOpenedToBody(){
    this.document.body.classList.remove('search-v1-opened');
  }

  onFocusInputSearch(){
    this.autocompleteOpen = true;

    // ejecutar la busqueda del autocomplete si el input tiene un texto
    if(this.searchInput.nativeElement.value){
      this.autocompleteProviderTrigger._onChange(this.searchInput.nativeElement.value);
    }
    // si no tiene texto y valores por defecto, se agrega un timeout para que reposicione el dropdown
    else if(this.model.profilesDefault.length > 0) {
      
      setTimeout(()=>{
        this.autocompleteProviderTrigger._onChange('');
        this.autocompleteProviderTrigger.updatePosition();
      }, 100);
    }
    else{
      this.addClassnameSearchOpenedToBody();
    }
  }

  onFocusInputLocationSearch(){
    this.locationAutocompleteOpen = true;

    // ejecutar la busqueda del autocomplete si el input tiene un texto
    if(this.searchLocationInput.nativeElement.value){
      this.autocompleteLocationTrigger._onChange(this.searchLocationInput.nativeElement.value);
    }
    // si no tiene texto y valores por defecto, se agrega un timeout para que reposicione el dropdown
    else if(this.model.locationDefault != null) {
      setTimeout(()=>{
        this.autocompleteLocationTrigger._onChange('');
        this.autocompleteLocationTrigger.updatePosition();
      }, 100);
    }
    setTimeout(() => {
      if (this.isExtremeMobile() && this.locationAutocompleteOpen && !this.matAutocompleteLocation.isOpen)
        this.autocompleteLocationTrigger.openPanel();
    }, 100);
  }

  onBlurInputSearch(){
    if(!this.locationAutocompleteOpen)
      this.removeClassnameSearchOpenedToBody();
    
    if(!this.isExtremeMobile())
      this.autocompleteOpen = false;

    if(!this.searchInput.nativeElement.value){
      this.searchProviderCtrl.setValue(null);
      this.providerSelected = null;
    }
  }

  onBlurInputLocationSearch(){
    if(!this.autocompleteOpen)
      this.removeClassnameSearchOpenedToBody();

    if(!this.isExtremeMobile())
      this.locationAutocompleteOpen = false;

    if(!this.searchLocationInput.nativeElement.value){
      this.searchLocationCtrl.setValue(null);
      this.locationObjectSelected = null;
    }
  }

  showingCancelSearchProvider(){
    return this.isExtremeMobile() && this.autocompleteOpen;
  }

  onCancelSearchProvider(){
    this.autocompleteOpen = false;

    this.autocompleteProviderTrigger.closePanel();

    this.searchInput.nativeElement.disabled = true;
    setTimeout(() => this.searchInput.nativeElement.disabled = false, 100);

    this.removeClassnameSearchOpenedToBody();

    if(this.model.profilesDefault.length == 0 || this.filteredResultsCount == 0)
      event?.stopPropagation();
  }

  showingCancelSearchLocation(){
    return this.isExtremeMobile() && this.locationAutocompleteOpen;
  }

  onCancelSearchLocation(){    
    this.closeAutocompleteLocationpanel();
    if(this.model.locationDefault == null || this.filteredLocationResultsCount == 0)
      event?.stopPropagation();
  }

  private closeAutocompleteLocationpanel(): void {
    this.removeClassnameSearchOpenedToBody();

    this.locationAutocompleteOpen = false;
    this.searchLocationInput.nativeElement.disabled = true;
    setTimeout(() => this.searchLocationInput.nativeElement.disabled = false, 100);
  }

  autocompleteProfileOpened():void{
    this.autocompleteOpen = true;

    this.addClassnameSearchOpenedToBody();
  }

  autocompleteClosed() {
    this.removeClassnameSearchOpenedToBody();

    if(this.isExtremeMobile() && this.removingSelection)
      return;

    this.autocompleteOpen = false;
  }

  autocompleteLocationOpened():void{
    this.locationAutocompleteOpen = true;

    this.addClassnameSearchOpenedToBody();
  }

  autocompleteLocationClosed() {
    this.removeClassnameSearchOpenedToBody();

    if(this.isExtremeMobile() && this.removingSelection)
    return;

    //this.locationAutocompleteOpen = false;
  }


  showIconDropdownOption(item:DoctorSearchResult){
    return item.claseId == ClassConcept.ESPECIALIDAD
      || item.claseId == ClassConcept.DIAGNOSTICO
      || item.claseId == ClassConcept.AREAS_DE_ENFOQUE
      || item.claseId == ClassConcept.PROCEDIMIENTO;
  }

  getIconClassNameDropdownOption(item:DoctorSearchResult){
    let iconClassName = 'phr-iconset-';

    switch(item.claseId){
      case ClassConcept.ESPECIALIDAD:
        iconClassName += 'estetoscopio';
        break;
      case ClassConcept.DIAGNOSTICO:
        iconClassName += 'info-general2';
        break;
      case ClassConcept.AREAS_DE_ENFOQUE:
        iconClassName += 'medal';
        break;
      case ClassConcept.PROCEDIMIENTO:
        iconClassName += 'procedimientos';
        break;
      default:
        break;
    }

    return iconClassName;
  }

  showAvatarDropdownOption(item:DoctorSearchResult){
    return item.claseId == 0;
  }

  showDescriptionDropdownOption(item:DoctorSearchResult){
    return item.claseId == 0;
  }

  isDoctor(item:DoctorSearchResult) {
    return item.tipoPerfilPublico == PublicProfileType.Doctor;
  }

  getProfileTypeIconClassName(item:DoctorSearchResult){

    let profileType = item.tipoPerfilPublico;
    let className: string = 'phr-iconset-';

    switch(profileType){
      case PublicProfileType.Doctor:
      default:
        className += 'estetoscopio';
        break;
      case PublicProfileType.Farmacia:
        className += 'farmacia';
        break;
      case PublicProfileType.Clinica:
        className += 'hospital';
        break;
      case PublicProfileType.Fisioterapia:
          className += 'fisioterapia';
          break;
      case PublicProfileType.Laboratorio:
        className += 'laboratorios';
        break;
      case PublicProfileType.CentroDeImagenes:
        className += 'imagenologia';
        break;  
      case PublicProfileType.ServiciosOdontologicos:
        className += 'odontologia';
        break;
      case PublicProfileType.Opticas:
        className += 'open-eye';
        break;
    }

    return className;
  }


  onClickSearchButton(){
    this.fireSearchEvent();
  }

  private getProfilesDefault(){
    return (this.model.profilesDefault && this.model.profilesDefault.length > 0)
      ? this.model.profilesDefault.map(cd => {
          let doctorSearchResult = new DoctorSearchResult();
          doctorSearchResult.id = cd.id;
          doctorSearchResult.claseId = cd.claseId;
          doctorSearchResult.clase = this.layoutService.getTextByLanguage(cd.clase, this.locale.language);
          doctorSearchResult.nombre = this.layoutService.getTextByLanguage(cd.nombre, this.locale.language);
          doctorSearchResult.descripcion = this.layoutService.getTextByLanguage(cd.descripcion, this.locale.language);
          doctorSearchResult.url = cd.url;
          doctorSearchResult.urlImagen = cd.urlImagen;

          return doctorSearchResult;
        })
      : [];
  }

  private fireSearchEvent(){
    this.searchInput.nativeElement.value = this.searchInput.nativeElement.value.trim();

    let _searchText = this.searchInput.nativeElement.value;
    let _providerSearchMessage = new ProviderSearchMessage();

    if(this.providerSelected){
      _providerSearchMessage.conceptNav = this.providerSelected.url;
      _providerSearchMessage.conceptId = this.providerSelected.id;
      _providerSearchMessage.conceptClaseId = this.providerSelected.claseId;
    }
    else {
      _providerSearchMessage.searchText = _searchText;
    }

    if(this.locationObjectSelected){
      if(this.locationObjectSelected.url){
        _providerSearchMessage.territorialDivisionIdentification = this.locationObjectSelected.url;
      }
      else {
        _providerSearchMessage.filtros = this.locationObjectSelected.id.toString();
      }
    }
    else{
      this.searchLocationCtrl.reset();
    }

    this.messageBusService.providerSearch(_providerSearchMessage);
  }

  private getLocationSearchGroupByDefault():LocationSearchResultGroup[]{
    let _resultGroup:LocationSearchResultGroup[] = [];

    // SECCION 1
    let _section1Grouped = this.model.locationDefault.seccion1 && this.model.locationDefault.seccion1.length > 0 
      ? this.utilsService.groupBy<TerritorialDivision, number>(this.model.locationDefault.seccion1, sd => sd.nivel)
      : new Map<TerritorialDivision, number>();

    _section1Grouped.forEach((value: TerritorialDivision[], key: number)=>{
      let resultGroupItem = new LocationSearchResultGroup();
      resultGroupItem.id = key;
      resultGroupItem.displayName = this.getLocationDisplayNameFromLevelDefault(key);

      resultGroupItem.results = value.map(td => { 
        return {
          id: td.id, 
          nombre: this.layoutService.getTextByLanguage(td.nombreCanonico, this.locale.language),
          url: td.identificador, 
          icono: td.paisISO == 'PA' ? 'phr-iconset-panama' : 'phr-iconset-suraplace'
        };
      });

      _resultGroup.push(resultGroupItem);
    });

    // SECCION 2
    let _section2Grouped = this.model.locationDefault.seccion2 && this.model.locationDefault.seccion2.length > 0 
      ? this.utilsService.groupBy<TerritorialDivision, number>(this.model.locationDefault.seccion2, sd => sd.nivel)
      : new Map<TerritorialDivision, number>();

    _section2Grouped.forEach((value: TerritorialDivision[], key: number)=>{
      let resultGroupItem = new LocationSearchResultGroup();
      resultGroupItem.id = key;
      resultGroupItem.displayName = this.getLocationDisplayNameFromLevelDefault(key);

      resultGroupItem.results = value.map(td => { 
        return {
          id: td.id,
          nombre: this.layoutService.getTextByLanguage(td.nombreCanonico, this.locale.language),
          url: td.identificador,
          icono: 'phr-iconset-suraplace' 
        };
      });

      _resultGroup.push(resultGroupItem);
    });

    // SECCION 3
    let _section3Grouped = this.model.locationDefault.seccion3 && this.model.locationDefault.seccion3.length > 0 
      ? this.utilsService.groupBy<ConceptDefaultModel, number>(this.model.locationDefault.seccion3, sd => sd.claseId)
      : new Map<TerritorialDivision, number>();

    _section3Grouped.forEach((value: ConceptDefaultModel[], key: number)=>{
      let resultGroupItem = new LocationSearchResultGroup();
      resultGroupItem.id = key;
      resultGroupItem.displayName = this.layoutService.getTextByLanguage(value[0].clase, this.locale.language);

      resultGroupItem.results = value.map(td => { 
        return {
          id: td.id,
          nombre: this.layoutService.getTextByLanguage(td.nombre, this.locale.language),
          url: "",
          icono: 'phr-iconset-hospital'
        };
      });

      _resultGroup.push(resultGroupItem);
    });

    return _resultGroup;
  }

  private getLocationsByLevels(value:string):Observable<LocationSearchResultGroup[]>{
    return this.territorialDivisionsService.getByLevels(this.language, value, this.locationLevels).pipe(map((results:TerritorialDivision[])=>{
      let _resultGroup:LocationSearchResultGroup[] = [];
      let _territorialDivisionGrouped = this.utilsService.groupBy<TerritorialDivision, number>(results, sd => sd.nivel);

      _territorialDivisionGrouped.forEach((value: TerritorialDivision[], key: number)=>{
        let resultGroupItem = new LocationSearchResultGroup();
        resultGroupItem.id = key;
        resultGroupItem.displayName = this.getLocationDisplayNameFromLevel(key);
  
        resultGroupItem.results = value.map(td => { 
          return {id: td.id, nombre: td.nombreCanonico, url: td.identificador, icono: 'phr-iconset-suraplace' };
        });
  
        _resultGroup.push(resultGroupItem);
      });

      return _resultGroup;
    }));
  }

  private getLocationDisplayNameFromLevel(level: number) {
    return level == 1 ? this.countryText : (level == 2 ? this.stateText : this.cityText);
  }

  private getLocationDisplayNameFromLevelDefault(level: number) {
    return level == 1 ? "" : (level == 2 ? this.stateDefaultText : this.cityDefaultText);
  }

  getAvatarModel(item:DoctorSearchResult) : PublicProfileAvatarComponentModel {
    var avatarModel = new PublicProfileAvatarComponentModel();

    avatarModel.personId = item.idPersona;
    avatarModel.avatarUrl = item.urlImagen;
    avatarModel.fullName = item.nombre;
    avatarModel.sex = item.sexo;
    avatarModel.profileType = item.tipoPerfilPublico;    
    avatarModel.useAvatarPadding = false;

    return avatarModel;
  }

  showCloseIcon(){
    return this.providerSelected
      || (this.searchInput 
        && this.searchInput.nativeElement 
        && this.searchInput.nativeElement.value);
  }
}

export class SearchProvidersComponentConfiguration{
  profileTypes:string;
  profilesDefault: ConceptDefaultModel[] = [];
  placeholderProvider: string;
  placeholderLocation: string;
  locationDefault:LocationDefaultModel;
  hideLocationAutocomplete:boolean = false;
  comboLocation:boolean = false;

  constructor(obj: SearchProvidersComponentConfiguration);
  constructor(obj?:any){
    this.profileTypes = obj && obj.profileTypes || '';
    this.profilesDefault = obj && obj.profilesDefault || [];
    this.placeholderProvider = obj && obj.placeholderProvider || '';
    this.placeholderLocation = obj && obj.placeholderLocation || '';
    this.locationDefault = obj && obj.locationDefault || null;
    this.hideLocationAutocomplete = obj && obj.hideLocationAutocomplete || false;
    this.comboLocation = obj && obj.comboLocation || false;
  }   
}

export class DoctorSearchResultGroup {
  classId: number;
  className: string;
  results: DoctorSearchResult[];
}

export class LocationSearchResultGroup {
  id: number;
  displayName: string;
  results: LocationSearchResultItem[];
}

export class LocationSearchResultItem {
  id: number;
  nombre: string;
  url: string;
  icono?: string;
}