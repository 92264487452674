import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { Link } from 'src/app/shared/models/people/link.model';
import { AppStorageService, STORAGE } from '../services/app-storage.service';
import { BaseService } from '../services/base.service';
import { LayoutService } from '../services/layout.service';
import { AppInitializationService } from '../services/app-initializer.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MenuGuard implements CanActivate {

  constructor(
    private baseService: BaseService,
    private layoutService: LayoutService,
    private router: Router,
    private appStorageService: AppStorageService,
    private appInitializationService: AppInitializationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Verifica si es el cliente (navegador) antes de proceder
    if (!isPlatformBrowser(this.platformId)) {
      return true; // Si no es navegador, permitir la navegación (en SSR no bloquea)
    }


    // Esperar a que la aplicación esté completamente inicializada
    return this.appInitializationService.isInitialized().pipe(
      filter(initialized => initialized),  // Esperar hasta que sea `true`
      take(1),  // Tomar solo el primer valor `true`
      switchMap(() => {
        return this.baseService.getMenuLinks().pipe(
          switchMap((links)=>{
            return of(this.canGo(links, state.url));
          }),
          catchError((err) => {
            this.router.navigate(['/not-found']);
            return of(false);
          })
        );
      })
    );
  }
  
  canGo(links: Link[], path: string): boolean {
    path = path.replace("/", "").split("/")[0].split('?')[0];
    if(path == ''){
      return true;
    }
    else {
      let linkSelected = links.find(link => this.baseService.getBasePath(link.identificadorUrl) == decodeURIComponent(path));

      let sublinkSelected = false;

      links.forEach(link => {

        let sublink = link.subMenu 
          ? link.subMenu.find(link => this.baseService.getBasePath(link.identificadorUrl) == decodeURIComponent(path))
          : null;

        if(sublink)
          sublinkSelected = true;
      });

      if(linkSelected || sublinkSelected || path == "empty") {
        return true;
      } else{
        this.router.navigate(['/not-found']);
        return false;
      }
    }
  }
}
