import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MessageBusService } from '../services/message-bus.service';
import { RouteLoaderService } from '../services/route-loader.service';
import { AppInitializationService } from '../services/app-initializer.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private messageBusService: MessageBusService,
    private appInitializationService: AppInitializationService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {

    // Verifica si es el cliente (navegador) antes de proceder
    if (!isPlatformBrowser(this.platformId)) {
      return true; // Si no es navegador, permitir la navegación (en SSR no bloquea)
    }


    // Esperar a que la aplicación esté completamente inicializada
    return this.appInitializationService.isInitialized().pipe(
      filter(initialized => initialized),  // Esperar hasta que sea `true`
      take(1),  // Tomar solo el primer valor `true`
      switchMap(() => {

        return this.authService.hasPermissions(state.url).pipe(map((result: boolean) => {
          if (route.queryParams.authIframe) {
            this.messageBusService.authIframe();
          }
          return result;
        }));
      })
    );
  }
}
