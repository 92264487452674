import { Inject, Injectable } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoginResponse } from 'src/app/shared/models/login-response.model';
import { PersonIdentification } from 'src/app/shared/models/people/person-identification.model';
import { Policy } from 'src/app/shared/models/people/policy.model';
import { UpdateUser, UserPerson } from 'src/app/shared/models/people/user-person.model';
import { User } from 'src/app/shared/models/people/user.model';
import { Token } from 'src/app/shared/models/token.model';
import { AppStorageService, STORAGE } from './app-storage.service';
import { AuthOidcService } from './auth-oidc.service';
import { BaseService } from './base.service';
import { HttpBaseService } from './http-base-service';
import { MessageBusService } from './message-bus.service';
import { ResetPasswordModel } from 'src/app/shared/models/people/reset-password.model';
import { Dependent, DependentRelationshipType, DependentRequest } from 'src/app/shared/models/people/dependent.model';
import { RegisterUnverifedUser, RegisterUser } from 'src/app/shared/models/people/register-user.model';
import { UpdatePasswordRequestModel } from 'src/app/shared/models/people/update-password-request.model';
import { FileUploadRequest } from 'src/app/shared/interfaces/file-upload-request';
import { AvatarUploadModel, AvatarUploadedFileModel } from 'src/app/shared/components/avatar-upload/avatar-upload.component';
import { UserExistsResponseModel } from 'src/app/shared/models/people/user-exists-response.model';
import { TerritorialDivisionsService } from './territorial-divisions.service';
import { CountryModel, TerritorialDivision } from 'src/app/shared/models/systems/territorial-division.model';
import { UnverifiedRequest } from 'src/app/shared/models/systems/unverified-request.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  dependentRelationshipTranslations = {
    other: "",
    son: "",
    daughter: "",
    father: "",
    mother: "",
    language: "",
    man: "",
    women: "",
    relative: "",    
    husband: "",
    wife: "",    
    brother: "",
    sister: "",
    grandfather: "",
    grandmother: "",
    manWarded: "",
    womanWarded: "",
  };

  constructor(
    private http: HttpBaseService,
    private appStorageService: AppStorageService,
    private authOidcService: AuthOidcService,
    private baseService: BaseService,
    private messageBusService: MessageBusService,
    private translation: L10nTranslationService,
    private territorialDivisionService: TerritorialDivisionsService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
  ) { 
    this.setConfigTranslation();
  }

  private setConfigTranslation(){
    this.translation.onChange().subscribe({
      next: (locale: L10nLocale) => {
          this.dependentRelationshipTranslations.other = this.translation.translate('other');
          this.dependentRelationshipTranslations.son = this.translation.translate('son');
          this.dependentRelationshipTranslations.daughter = this.translation.translate('daughter');
          this.dependentRelationshipTranslations.father = this.translation.translate('father');
          this.dependentRelationshipTranslations.mother = this.translation.translate('mother');
          this.dependentRelationshipTranslations.man = this.translation.translate('man');
          this.dependentRelationshipTranslations.women = this.translation.translate('women');
          this.dependentRelationshipTranslations.relative = this.translation.translate('relative');
          this.dependentRelationshipTranslations.husband = this.translation.translate('husband');
          this.dependentRelationshipTranslations.wife = this.translation.translate('wife');
          this.dependentRelationshipTranslations.brother = this.translation.translate('brother');
          this.dependentRelationshipTranslations.sister = this.translation.translate('sister');
          this.dependentRelationshipTranslations.grandfather = this.translation.translate('grandfather');
          this.dependentRelationshipTranslations.grandmother = this.translation.translate('grandmother');
          this.dependentRelationshipTranslations.manWarded = this.translation.translate('manWarded');
          this.dependentRelationshipTranslations.womanWarded = this.translation.translate('womanWarded');
      }
    });
    this.translation.onError().subscribe({
      next: (error: any) => {
          if (error) console.log(error);
      }
    });
  }

  getUserPerson(username: string): Observable<UserPerson> {
    let url = "api/usuarios/persona/" + username;

    return this.http.get<UserPerson>(url);
  }

  getUserIdentification(identificationType: number): Observable<PersonIdentification[]> {
    let url = "api/usuarios/identificaciones?tipo=" + identificationType;

    return this.http.get<PersonIdentification[]>(url);
  }
  
  getUserIdentificationNumber() : Observable<PersonIdentification[]> {
    return this.getUserIdentification(15); // Cedula
  }

  getPolicies(policyType: number) : Observable<Policy[]> {
    let policies = this.appStorageService.getItem(STORAGE.POLICIES, true);

    if (policies)
      return of(policies);

    return this.getUserIdentification(policyType).pipe(map(identifications => {
      let policies = [];
      
      for (let index = 0; index < identifications.length; index++) {
        const identification = identifications[index];
        
       // Only return policies that are not cancelled
       if (identification.idEstado != 7) {
        let policy = this.baseService.parseObjectDeep<Policy>(JSON.parse(identification.json))

        policies.push(policy);
       }        
      }

      this.appStorageService.setItem(STORAGE.POLICIES, policies, true);

      if (policies.length == 0) {
        this.baseService.handleServiceError(null, "Error getting user policies", this.translation.translate("policyMenu.text7"));        
      }

      return policies;
    }));
  }

  getUserDependents(): Observable<Dependent[]> {
    let url = "api/usuarios/dependientes/";

    return this.http.get<Dependent[]>(url);
  }

  getUserIdLoggedIn(): Observable<number>{
    let _userPerson = this.appStorageService.getItemSession(STORAGE.USER, true);

    if(_userPerson == null){
      return this.getUserPersonLoggedInFromService()
        .pipe(map((userPerson: UserPerson | null)=>{ 
          return userPerson ? userPerson.id : 0;
        }));
    }
    else{
      return of(_userPerson.id);
    }
  }

  getUsernameLoggedIn(): string{
    let token = this.appStorageService.getToken();

    if(token && !this.http.isTokenExpired(token)){
      return token.username;
    }
    else{
      return "";
    }
  }

  getUserPersonLoggedIn(): Observable<UserPerson | null> {
    let _userPerson = this.appStorageService.getItemSession(STORAGE.USER, true);

    if(_userPerson == null){
      return this.getUserPersonLoggedInFromService();
    }

    return of(_userPerson);
  }
  
  getUserPersonLoggedInFromService(): Observable<UserPerson | null> {
    const token = this.appStorageService.getToken();

    if(token == null || this.http.isTokenExpired(token))
      return of(null);

    return this.getUserPerson(token.username).pipe(
      map((userPerson:UserPerson) =>{
        this.updateUserLoggedIn(userPerson);
        return userPerson;
      })
    );
  }

  updateUserLoggedIn(user: UserPerson) {
    this.appStorageService.setItemSession(STORAGE.USER, user, true);

    return;
  }

  processRequestUserId(){
    let token = this.appStorageService.getToken();

    if(token && !this.http.isTokenExpired(token)){
      let userPerson = this.appStorageService.getItemSession(STORAGE.USER, true);

      if(userPerson == null){
        this.getUserPerson(token.username).subscribe(userPerson => {
          this.appStorageService.setItemSession(STORAGE.USER, userPerson, true);

          this.sendUserId(userPerson.id);
        });
      }
      else{
        this.sendUserId(userPerson.id);
      }
    }
    else if(this.authOidcService.existsManager()){
      this.authOidcService.startAuthenticationPopup().subscribe(token => {
        if(!token.isInvalid) {
          this.appStorageService.setToken(token);

          this.getUserPerson(token.username).subscribe(userPerson =>{
            this.appStorageService.setItemSession(STORAGE.USER, userPerson, true);
  
            this.sendUserId(userPerson.id);
          });
        }
      });
    }
    else {

    }
  }

  public sendUserId(id:number){
    this.messageBusService.sendUserId(id);
  }

  public postUserActive(idEmpresaDestino:number, idEtapaSolicitud: number){
    let url = "api/usuarios/activo?idEmpresaDestino=" + idEmpresaDestino + "&idEtapaSolicitud=" + idEtapaSolicitud;

    return this.http.post(url, null);
  }

  public postUserRegister(username: string, password: string): Observable<User>{
    return this.baseService.getDirectoryDomain()
    .pipe(switchMap((domain:string)=>{
      let url = "api/usuarios/registro?domain=" + domain;

      let model = {
        Username: username,
        Password: password,
        IdEmpresa: this.baseService.getCompanyId()      
      };
  
      return this.http.post<User>(url, model, null, false);
    }));
  }

  public requestUserRegistration(registerUser: RegisterUser): Observable<string>{
    let url = "api/solicitudes-x-verificar/users";

    return this.http.post<string>(url, registerUser, null, false);
  }

  public postConfirmation(idVerificacion:string):Observable<LoginResponse>{
    return this.baseService.getDirectoryDomain()
    .pipe(switchMap((domain:string)=>{
      let url = `api/usuarios/confirmacion/${idVerificacion}?domain=${domain}`;

      return this.http.post<Token>(url, null, null, false)
      .pipe(
        map((token) => {
          this.appStorageService.setToken(token);
  
          let response = new LoginResponse();
          response.success = true;
          return response;
        }),
        catchError((err) => {
          let response = new LoginResponse();
          response.success = false;
          response.message = "Hubo un error al intentar confirmar el usuario";
  
          return of(response);
        }));
    }));
  }

  public postResetPassword(model:ResetPasswordModel):Observable<void>{
    return this.baseService.getDirectoryDomain().pipe(switchMap((domain:string)=>{
      let url = `api/usuarios/restablecer-contrasenia`;

      if(!model.idEmpresa){
        model.idEmpresa = this.baseService.getCompanyId();
        model.nombreEmpresa = this.baseService.getDirectoryTitle();
      }
  
      if(!model.language){
        model.language = this.baseService.getLanguage();
      }
  
      model.dominio = domain;
  
      return this.http.post<void>(url, model, null, false);
    }));
  }

  public postConfirmationResetPassword(idVerificacion:string, password: string):Observable<void>{
    let url = `api/usuarios/restablecer-contrasenia/confirmacion/${idVerificacion}`;

    let model = { Password: password };

    return this.http.post(url, model, null, false);
  }

  public postExistsUser(username: string): Observable<UserExistsResponseModel>{
    return this.baseService.getDirectoryDomain().pipe(switchMap((domain:string)=>{
      let model = {
        Username: username,
        DominioEmpresa: domain
      };
  
      return this.http.post<UserExistsResponseModel>("api/usuarios/existe", model, null, false);
    }));
  }

  public updateUser(user: UpdateUser): Observable<UpdateUser> {
    let url = `api/usuarios/`;

    return this.http.put<UpdateUser>(url, user, null, true);
  }

  public createOrUpdateDependent(dependent: DependentRequest) {
    let url = `api/usuarios/dependientes/`;

    return dependent.idPersona > 0
      ? this.http.put(url, dependent, null, true)
      : this.http.post(url, dependent, null, true);
  }
  
  public deleteDependent(dependent: Dependent) {
    let url = `api/usuarios/dependientes/${dependent.id}?idPersona=${dependent.idPersona}`;

    return this.http.delete(url, null, true);      
  }

  public updatePassword(model: UpdatePasswordRequestModel) {
    let url = `api/usuarios/contrasenia`;

    return this.http.put(url, model, null, true);
  }

  public getDependentRelationshipName(dependent: Dependent | undefined) {
    let relationship = this.dependentRelationshipTranslations.other;

    if (dependent) {
      switch(dependent.tipoRelacion) {
        case DependentRelationshipType.SonOrDaughter:
          relationship = (dependent.sexo == "F"? this.dependentRelationshipTranslations.daughter : this.dependentRelationshipTranslations.son);
          break;
        case DependentRelationshipType.FatherOrMother:
          relationship = (dependent.sexo == "F"? this.dependentRelationshipTranslations.mother : this.dependentRelationshipTranslations.father);
          break;
        case DependentRelationshipType.BrotherOrSister:
          relationship = (dependent.sexo == "F"? this.dependentRelationshipTranslations.sister : this.dependentRelationshipTranslations.brother);
          break;
        case DependentRelationshipType.HusbandOrWife:
          relationship = (dependent.sexo == "F"? this.dependentRelationshipTranslations.wife : this.dependentRelationshipTranslations.husband);
          break;
        case DependentRelationshipType.GrandfatherOrGrandmother:
          relationship = (dependent.sexo == "F"? this.dependentRelationshipTranslations.grandmother : this.dependentRelationshipTranslations.grandfather);
          break;
        case DependentRelationshipType.Warded:
          relationship = (dependent.sexo == "F"? this.dependentRelationshipTranslations.womanWarded : this.dependentRelationshipTranslations.manWarded);
          break;
        case DependentRelationshipType.Family:
          relationship = this.dependentRelationshipTranslations.relative;
          break;
      }
    }   

    return relationship;
  }

  updateProfilePicture(args: AvatarUploadModel): Observable<any> {
    const userType = !args.idPersona ? '' : '/dependientes';
    const url = `api/usuarios${userType}/foto-perfil`;
    return this.http.put(url, args, null, true);
  }

  uploadProfilePicture(file: File): Observable<any> {
    const pUploadResponse = new Subject();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = x => this.fileReaderOnLoadEnd(x, file, pUploadResponse);
    return pUploadResponse.asObservable();
  }

  private fileReaderOnLoadEnd(args: ProgressEvent<FileReader>, file: File, pUploadResponse: Subject<any>): void {
    const ultimoPunto = file.name.lastIndexOf('.');
    const nombre: string = file.name.slice(0, ultimoPunto);
    const extension: string = file.name.slice(ultimoPunto + 1);
    const body: FileUploadRequest = {
      content: args.target?.result as string,
      contentType: file.type,
      extension,
      nombre
    };
    const url = `api/usuarios/imagenes`;
    this.http.post<AvatarUploadedFileModel>(url, body).subscribe({
      next: x => {pUploadResponse.next(x); pUploadResponse.complete();},
      error: error => {pUploadResponse.error(error); pUploadResponse.complete(); }
    });
  }

  deleteUploadedImage(id: number, idPersona: number) {
    const url = `api/usuarios/dependientes/imagen/${id}?idPersona=${idPersona}`;
    return this.http.delete(url, null, true);
  }

  public updateUserResidenceCountry(countryId: number): Observable<void>{
    const model = { IdPais : countryId };
    return this.http.put('api/usuarios/pais-residencia', model, null, true);
  }

  public getUserCountry():Observable<TerritorialDivision | null>{
    return this.getUserPersonLoggedIn().pipe(switchMap((user: UserPerson | null)=>{
      if(user){

        return this.territorialDivisionService.getCountryApp()
        .pipe(switchMap((countryApp: CountryModel)=>{

          let availableCountries = this.territorialDivisionService.getAvailableCountries();
          let countryUser = availableCountries.find(c=> c.idPais == user.idPaisResidencia);
  
          if(user.idPaisResidencia > 0 && user.idPaisResidencia != 9 && countryUser){
  
            this.territorialDivisionService.setCountryApp(countryUser.idPais);
  
            return of(countryUser);
          }
          else if(user.idPaisResidencia == 9 && availableCountries.find(c=> c.id == countryApp.territorialDivision.id)){
            return this.updateUserResidenceCountry(countryApp.territorialDivision.idPais)
            .pipe(switchMap((result:void)=>{
  
              user.idPaisResidencia = countryApp.territorialDivision.idPais;
  
              this.updateUserLoggedIn(user);
  
              return of(countryApp.territorialDivision);
            }));
          }
          else{
            return of(null);
          }
        }));
      }
      else{
        return of(null);
      }
    }));
  }

  getUserUnverifiedRequest(requestGuid: string) : Observable<UnverifiedRequest> {
    const url = "api/usuarios/solicitudPorVerificar?solicitudPorVerificarCode=" + requestGuid;
    
    return this.http.get<UnverifiedRequest>(url, null, false);
  }

  registerUnverifiedUser(model: RegisterUnverifedUser): Observable<Token> {
    return this.http.post<Token>('api/usuarios/no-verificado', model, null, false);
  }

  wasUserCreated():boolean{
    const token = this.appStorageService.getToken();

    return !!token ? token.userCreated : false;
  }
}
