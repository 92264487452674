import { Component, Inject, OnDestroy, OnInit, Type, ViewChild, ViewEncapsulation } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppStorageService, STORAGE } from 'src/app/core/services/app-storage.service';
import { LayoutService } from 'src/app/core/services/layout.service';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { Link } from 'src/app/shared/models/people/link.model';
import { UserService } from 'src/app/core/services/user.service';
import { UserPerson } from 'src/app/shared/models/people/user-person.model';
import { UtilsService } from 'src/app/core/services/utils.service';
import { MessageBusService } from 'src/app/core/services/message-bus.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { ProfileMenuComponent } from '../profile/profile-menu.component';
import { MyHealthComponent } from '../../my-health/my-health.component';
import { BaseService } from 'src/app/core/services/base.service';

@Component({
  selector: 'app-cliniweb-menu',
  templateUrl: './cliniweb-menu.component.html',
  styleUrls: ['./cliniweb-menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('CliniwebMenuComponent')
export class CliniwebMenuComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  private cargando = false;
  ID_AREA_SISTEMAS_MI_SALUD = 4240;
  links: Link[];
  loggedInUser: UserPerson = null as any;
  profileMenuOpen: boolean = false
  miSaludMenuOpen = false;
  @ViewChild(MatMenuTrigger) profileFloatingMenuTrigger: MatMenuTrigger;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private appStorageService: AppStorageService,
    private translation: L10nTranslationService,
    private baseService: BaseService,
    private layoutService: LayoutService,
    private userService: UserService,
    private utilsService: UtilsService,
    private messageBusService: MessageBusService,
    private mediaMatcher: MediaMatcher
  ) {

    this.translation.onChange()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: (locale: L10nLocale) => {

        let menuLanguage = this.appStorageService.getItem(STORAGE.MENU_LANGUAGE);

        if(locale.language != menuLanguage)
          this.populateLinks(locale.language);
      }
    });

    this.translation.onError()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe({
      next: (error: any) => {
          if (error) console.log(error);
      }
    });

    this.messageBusService.onLoginCompleted()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(msg => {
      if (msg.user) {
        this.loadProfile();
      }      
    });

    this.messageBusService.onToggleBottomSheetSubject()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(this.onToggleBottomSheetSubjectNext.bind(this));
  }

  private onToggleBottomSheetSubjectNext(args: { action: string, component?: any}) {
    if (this.cargando) return;
    this.cargando = true;
    this.miSaludMenuOpen = args.action == 'open' && args.component === MyHealthComponent;
    this.profileMenuOpen = args.action === 'open' && args.component === ProfileMenuComponent;
    setTimeout(() => this.cargando = false, 100);
  }

  ngOnInit(): void {

    this.links = this.appStorageService.getItemSession(STORAGE.MENU, true);
    this.links = this.links.map(x => {
      const {idAreaSistema, subMenu, ...resto} = x;
      return x.idAreaSistema == this.ID_AREA_SISTEMAS_MI_SALUD ? ({...resto, idAreaSistema, subMenu: []}) : x
    });

    this.userService.getUserPersonLoggedIn()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(p => {
      if (!p)
        return;
      
      this.loggedInUser = p;

      if (!this.loggedInUser.ubicacionLogo) {
        let feNacimiento = this.loggedInUser.fechaNacimiento? new Date(this.loggedInUser.fechaNacimiento) : null;
        this.loggedInUser.ubicacionLogo = this.utilsService.getPersonDefaultAvatarUrl(feNacimiento, this.loggedInUser.sexo);
      }

      setTimeout(() => {
        this.profileFloatingMenuTrigger.menuOpened
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.profileMenuOpen = true;
            this.layoutService.overflowBodyDisabled();
          });
        this.profileFloatingMenuTrigger.menuClosed
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(() => {
            this.profileMenuOpen = false;
            this.layoutService.overflowBodyEnabled();
          });
      }, 100);
          
    });    
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  loadProfile(){
    this.userService.getUserPersonLoggedIn().subscribe(p => {
      if (!p)
        return;
      
      this.loggedInUser = p;

      if (!this.loggedInUser.ubicacionLogo) {
        let feNacimiento = this.loggedInUser.fechaNacimiento? new Date(this.loggedInUser.fechaNacimiento) : null;
        this.loggedInUser.ubicacionLogo = this.utilsService.getPersonDefaultAvatarUrl(feNacimiento, this.loggedInUser.sexo);
      }
    });
  }

  populateLinks(language: string){
    this.baseService.getMenuLinks(language).subscribe(data => {
      if (!data || data.length == 0)
        return;
      
      this.links = data;

      this.appStorageService.setItem(STORAGE.MENU_LANGUAGE, language);
    });
  }

  openProfile(event: MouseEvent): void {
    if (this.mediaMatcher.matchMedia(Breakpoints.Handset)) {
      this.messageBusService.sendToggleBottomSheetSubject({action: 'open', component: ProfileMenuComponent});
    } else {
      this.profileFloatingMenuTrigger.openMenu();
    }
    event.stopPropagation();
  }
  
  closeProfileMenu() {
    this.profileMenuOpen = false;

    this.profileFloatingMenuTrigger.closeMenu();
  }
  
}
