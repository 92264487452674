<div class="summary-appoinment-checkout-container" *ngIf="initialLoading || (model && show)" [ngClass]="{'not-workflow': !inWorflow}">
    <div class="card" [ngClass]="{'android': isAndroid}">
        <div class="content">
            <span class="title" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                {{ 'shared.summaryAppointmentCheckout.title' | translate:locale.language: {specialty: model.specialty} }}
            </span>
            <span class="h1-mobile" [ngClass]="{'skeleton-item background-grey': initialLoading}"></span>
            <span class="h2-mobile" [ngClass]="{'skeleton-item background-grey': initialLoading}"></span>
            <div class="pending-container-skeleton" [ngClass]="{'skeleton-item background-grey': initialLoading}"></div>
            <!-- Unconfirmed -->
            <div class="pending-container {{appointmentStateClassName}}" *ngIf="appointmentState == 22 && !appointmentExpired && !initialLoading">                
                <div class="pending-container-header">
                    <div class="state-name">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-alert"></mat-icon>
                        <span>{{ appointmentStateName }}</span>
                    </div>
                    <div class="right">
                        <span class="action-link" (click)="onCancelAppointmentClick()">
                            {{ 'shared.summaryAppointmentCheckout.helpButton2' | translate:locale.language }}
                        </span>
                    </div>
                </div>
                <div class="content">
                    <span [innerHTML]="'shared.summaryAppointmentCheckout.pendingDescription1' | translate:locale.language"></span>
                    <span [innerHTML]="'shared.summaryAppointmentCheckout.pendingDescription2' | translate:locale.language"></span>         
                </div>                    
            </div>          
            
            <div class="pending-container {{appointmentStateClassName}}" *ngIf="(appointmentState != 22 || appointmentExpired) && !initialLoading">
                <div class="pending-container-header" [ngClass]="{'expired-title': appointmentExpired && appointment.idEstado != 7 && appointment.idEstado != 28}">
                    <div class="state-name">
                        <mat-icon fontSet="phr-iconset" class="{{appointmentStateIconClassName}}"></mat-icon>
                        <span>{{ appointmentStateName }}</span>
                    </div> 
                    <div class="right">
                        <span class="action-link" *ngIf="appointmentState != 7 && !appointmentExpired" (click)="onCancelAppointmentClick()">
                            {{ 'shared.summaryAppointmentCheckout.helpButton2' | translate:locale.language }}
                        </span>
                        <span class="action-link" *ngIf="appointmentState == 7 || appointmentExpired" (click)="onReScheduleAppointmentClick()">
                            {{ 'shared.summaryAppointmentCheckout.reSchedule' | translate:locale.language }}
                        </span>
                    </div>   
                </div>
            </div>
            
            <!-- Did not attend 
            <div class="pending-container did-not-attend" *ngIf="appointmentState == 28 || (appointmentExpired && appointmentState == 22)">
                <div>
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-close-circle"></mat-icon>
                    <span>{{ appointmentStateName }}</span>
                </div>
                
            </div>
            
            <div class="pending-container confirmed-state" *ngIf="appointmentState != 7 && appointmentState != 22 && appointmentState != 28">
                <div>
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-validacion"></mat-icon>
                    <span>{{ appointmentStateName }}</span>
                </div>                
            </div>-->

            <div class="appointment-info-container">
                <google-map (click)="openMap()" *ngIf="model.locationLat && !isVirtual && !initialLoading"
                    [center]="{lat: model.locationLat, lng: model.locationLng}" [zoom]="model.locationZoom">
                    <map-marker [position]="{lat: model.locationLat, lng: model.locationLng}"></map-marker>
                </google-map>
                
                <div class="skeleton-map" [ngClass]="{'skeleton-item background-grey': initialLoading}"></div>

                <app-appointment-info-checkout [initialLoading]="initialLoading" [model]="model.appointmentInfo" [showRelative]="true" [enablePublicProfileLinks]="enablePublicProfileLink" class="wrapper">                
                </app-appointment-info-checkout>
                
                <div *ngIf="!initialLoading" class="button-container">
                    <a mat-button mat-flat-button [href]="getCallNumber()" class="secondary small">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-suraphone"></mat-icon>
                        <span>{{ 'shared.summaryAppointmentCheckout.callButtonText' | translate:locale.language }}</span>
                    </a>
                    <a mat-button mat-flat-button target="_blank" [disabled]="appointmentState == 7 || appointmentExpired" [href]="getWazeUrl()" class="secondary small" *ngIf="!isVirtual">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-waze"></mat-icon>
                        <span>{{ 'shared.summaryAppointmentCheckout.wazeButtonText' | translate:locale.language }}</span>
                    </a>
                    <a mat-button mat-flat-button [disabled]="!idTeleconsulta || appointmentState == 7 || appointmentExpired" target="_blank" [href]="getVirtualConsultation()" class="secondary small" *ngIf="isVirtual">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-video"></mat-icon>
                        <span>{{ 'shared.summaryAppointmentCheckout.startButtonText' | translate:locale.language }}</span>
                    </a>
                </div>
                <div *ngIf="initialLoading" class="button-container">
                    <div class="skeleton-item background-grey"></div>
                    <div class="skeleton-item background-grey"></div>
                </div>
            </div>
            <div *ngIf="!initialLoading" class="help-container">
                <span class="title">{{ 'shared.summaryAppointmentCheckout.helpTitle' | translate:locale.language }}</span>
                <span class="description">{{ 'shared.summaryAppointmentCheckout.helpDescription' | translate:locale.language }}</span>
                <div class="button-container">
                     <!--<button mat-button mat-flat-button>
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-suradate"></mat-icon>
                        {{ 'shared.summaryAppointmentCheckout.helpButton1' | translate:locale.language }}
                    </button>-->
                    <button mat-button mat-flat-button (click)="onCancelAppointmentClick()" *ngIf="appointmentState != 7 && !appointmentExpired">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-close-circle-line"></mat-icon>
                        {{ 'shared.summaryAppointmentCheckout.helpButton2' | translate:locale.language }}
                    </button>
                    <button mat-button mat-flat-button (click)="onReScheduleAppointmentClick()" *ngIf="appointmentState == 7 || appointmentExpired">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-date-2"></mat-icon>
                        {{ 'shared.summaryAppointmentCheckout.reSchedule' | translate:locale.language }}
                    </button>
                    <button mat-button mat-flat-button (click)="openDialogContact()">
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-mail"></mat-icon>
                        {{ 'shared.summaryAppointmentCheckout.helpButton3' | translate:locale.language }}
                    </button>
                </div>
            </div>
            <!-- <div class="cancellation-policies-container">
                <span class="title">{{ 'shared.summaryAppointmentCheckout.cancellationPolicyTitle' | translate:locale.language }}</span>
                <div>
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-clock"></mat-icon>
                    <span>{{ 'shared.summaryAppointmentCheckout.cancellationPolicy1' | translate:locale.language }}</span>
                </div>
                <div>
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-clinigold"></mat-icon>
                    <span>{{ 'shared.summaryAppointmentCheckout.cancellationPolicy2' | translate:locale.language }}</span>
                </div>
                <div>
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-PHR-Smile"></mat-icon>
                    <span>{{ 'shared.summaryAppointmentCheckout.cancellationPolicy3' | translate:locale.language }}</span>
                </div>
            </div>
            <div class="promise-container">
                <div class="promise-header">
                    <mat-icon fontSet="phr-iconset" class="phr-iconset-validacion"></mat-icon>
                    <span>{{ 'shared.summaryAppointmentCheckout.promiseTitle' | translate:locale.language }}</span>
                </div>
                <div class="promise-content">
                    <p>
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-okbold"></mat-icon>
                        <span>{{ 'shared.summaryAppointmentCheckout.promise1' | translate:locale.language }}</span>
                    </p>
                    <p>
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-okbold"></mat-icon>
                        <span>{{ 'shared.summaryAppointmentCheckout.promise2' | translate:locale.language }}</span>
                    </p>
                    <p>
                        <mat-icon fontSet="phr-iconset" class="phr-iconset-okbold"></mat-icon>
                        <span>{{ 'shared.summaryAppointmentCheckout.promise3' | translate:locale.language }}</span>
                    </p>
                </div>
            </div> -->
        </div>
        <div class="footer">
            <button mat-button mat-flat-button (click)="gotoHome()" class="secondary">
                <span *ngIf="isCliniwebCompany">
                    {{ 'shared.summaryAppointmentCheckout.homeButtonText' | translate:locale.language }}
                </span>
                <span *ngIf="!isCliniwebCompany">
                    {{ 'shared.summaryAppointmentCheckout.homeButtonText2' | translate:locale.language }}
                </span>
            </button>
            <button mat-button mat-flat-button (click)="showMyAppointments()" class="primary">
                {{ 'shared.summaryAppointmentCheckout.appointmentsButtonText' | translate:locale.language }}
            </button>
        </div>
    </div>
</div>