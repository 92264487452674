<div [@.disabled]="isDesktop || showRelative" class="appointment-info-checkout-container">
    <div class="appointment-info-checkout" *ngIf="show" [ngClass]="{'show-relative': showRelative, 'enable-public-profile-links': enablePublicProfileLinks}">
        <div class="appointment-info-content">
            <div class="appointment-type" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                <mat-icon fontSet="phr-iconset" [class]="getAppointmentTypeClasname()"></mat-icon>
                <span >{{getAppointmentTypeText()}}</span>
            </div>
            <div class="appointment-date" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                <span>{{model.appointmentDate | date:'EEEE': 'UTC': language | titlecase }}, {{model.appointmentDate | customDate:'mediumDate': 'UTC': language }}</span>
                <span>{{model.appointmentDate | date:'h:mm a': 'UTC' | lowercase }}</span>
            </div>
            <div class="cancellation-container" [ngClass]="{'skeleton-item background-grey': initialLoading}"></div>
            <!--<div class="cancellation-container" *ngIf="model.cancellationDate">
                <span>{{ 'shared.appointmentInfoCheckout.cancellationText' | translate:locale.language }}</span>
                <span>{{ model.cancellationDate | date:'d MMM y': 'UTC': language }}, {{ model.cancellationDate | date:'h:mm a': 'UTC' | lowercase }}. </span>
                <a>{{ 'shared.appointmentInfoCheckout.cancellationLink' | translate:locale.language }}</a>
            </div>-->
            <div class="doctor-container">

                <div class="avatar-skeleton" [ngClass]="{'skeleton-item background-grey': initialLoading}"></div>
                <div class="avatar" [ngClass]="{'skeleton-item background-grey': initialLoading}" *ngIf="avatarModel" (click)="goToPublicProfile()">                
                    <app-public-profile-avatar [model]="avatarModel"></app-public-profile-avatar>
                </div>

                <label [ngClass]="{'skeleton-item background-grey': initialLoading}">
                    {{ 'shared.appointmentInfoCheckout.doctorLabel' | translate:locale.language }}
                </label>
                <span class="name" (click)="goToPublicProfile()" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                    {{model.doctorName}}
                </span>
                <span class="specialty" [title]="model.doctorSpecialty" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                    {{model.doctorSpecialty}}
                </span>
            </div>    
            <div class="attention-place-container" *ngIf="!isVirtualAppointment" [ngClass]="{'show-map-link': model.attentionPlaceLatLng }">
                <mat-divider></mat-divider>
                <label [ngClass]="{'skeleton-item background-grey': initialLoading}">
                    {{ attentionPlaceLabel | translate:locale.language }}
                </label>
                <div>
                    <span class="name" [title]="model.attentionPlaceName" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        {{ model.attentionPlaceName }}
                    </span>
                    <a class="map-link" *ngIf="initialLoading || model.attentionPlaceLatLng" (click)="openMap()" [ngClass]="{'skeleton-item background-grey': initialLoading}">
                        {{ 'shared.appointmentInfoCheckout.mapLink' | translate:locale.language }}
                    </a>
                </div>
                <span class="address" *ngIf="model.attentionPlaceAddress && !initialLoading" [title]="model.attentionPlaceAddress">{{ model.attentionPlaceAddress }}.</span>
            </div>
            <div class="medical-consultation-container" *ngIf="initialLoading || model.medicalConsultationType">
                <mat-divider></mat-divider>
                <label [ngClass]="{'skeleton-item background-grey': initialLoading}">
                    {{ 'shared.appointmentInfoCheckout.medicalConsultationLabel' | translate:locale.language }}
                </label>
                <span [ngClass]="{'skeleton-item background-grey': initialLoading}">{{ model.medicalConsultationType }}</span>
                <span class="amount" *ngIf="model.medicalConsultationAmount">{{ model.medicalConsultationAmount | currency:'USD' }}
                </span>
            </div>    
            <div class="patient-container" *ngIf="model.patientName && !initialLoading">
                <mat-divider></mat-divider>
                <label>{{ 'shared.appointmentInfoCheckout.appointmentDestinationLabel' | translate:locale.language }}</label>
                <div>
                    <span class="name">{{ model.patientName }}</span>
                    <span *ngIf="showRelationship()">{{ ' (' + getRelationshipText() + ')' }}</span>
                </div>
            </div>
        </div>
    </div>
</div>